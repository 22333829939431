import { HamburgerIcon } from "components/vectors";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function NavBar() {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const path = location.pathname?.split("/")[2]
    ? location.pathname?.split("/")[2]
    : location.pathname?.split("/")[1];

  return (
    <div className="top__header__container">
      <section className="header__navigation container d-none d-lg-flex">
        <div className="nav__wrapper">
          <Link to="/">
            <img
              src="/assets/images/logo/purple_peppa.svg"
              className="logo"
              alt="peppa logo"
            />
          </Link>
          <li className={`${path === "aboutUs" ? "active__nav" : ""}`}>
            <Link to="/aboutUs">About</Link>
          </li>
          <li className={`${path === "products" ? "active__nav" : ""}`}>
            <Link to="/products">Products</Link>
          </li>
          <li className={`${path === "pricing" ? "active__nav" : ""}`}>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li className={`${path === "santa-offer" ? "active__nav" : ""}`}>
            <Link to="/santa-offer">Marketplace</Link>
          </li>
        </div>

        <div className="store__download">
          <Link to="/login">Login</Link>
          <Link to="/register">
            <button className="button button__primary">For Sellers</button>
          </Link>
        </div>
      </section>
      <main className="position-relative d-block d-lg-none mobile__nav_wrapper">
        <div className="mobile__nav container p-lg-0 py-2">
          <div className="logo__section">
            <Link to="/">
              <img src="/assets/images/logo/purple_peppa_mobile.svg" />
            </Link>
          </div>
          <div
            className="toggle__section"
            onClick={() => setActive((prev) => !prev)}
          >
            <HamburgerIcon transparent={false} />
          </div>
        </div>
        {active ? (
          <main className="dropdown__menu_wrapper">
            <div className="dropdown__menu container p-lg-0">
              <li className={`${path === "aboutUs" ? "active__nav" : ""}`}>
                <Link to="/aboutUs">About</Link>
              </li>
              <li className={`${path === "products" ? "active__nav" : ""}`}>
                <Link to="/products">Products</Link>
              </li>
              <li className={`${path === "pricing" ? "active__nav" : ""}`}>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li className={`${path === "santa-offer" ? "active__nav" : ""}`}>
            <Link to="/santa-offer">Marketplace</Link>
          </li>

              <div className="d-flex align-items-center gap-4">
                <Link to="/register">
                  <button className="button button__primary">
                    For Sellers
                  </button>
                </Link>
                <Link to="/login" className="text__primary">
                  Login
                </Link>
              </div>

            </div>
          </main>
        ) : null}
      </main>
    </div>
  );
}

export default NavBar;
