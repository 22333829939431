import PepperestAxios from '../../libs/utils/PepperestAxios'
import {
    Invoices,
    InvoiceErrorMessages
} from '../../libs/constants/PepperestWebServices';
import * as actionTypes from './actionTypes';
import { setAddress } from './orders'
import axios from "axios"
import { setAlert } from './alert'
import { getStringHash } from 'libs/utils';
import { Redirect } from 'react-router-dom';
import React from 'react';


export const loadingInvoice = () => {
    return {
        type: actionTypes.LOAD_SINGLE_INVOICE,
    };
}

export const loadedInvoice = (invoice) => {
    const update = {
        invoice,
        loading: false,
        loaded: true,
        error: null,
    }
    return {
        type: actionTypes.FINISHED_LOADING_SINGLE_INVOICE,
        invoice: update,
    };
}


export const loadSingleInvoice = (extraParams = {}) => {
    return dispatch => {
        dispatch(loadingInvoice())

        const params = {
            ...extraParams
        }

        PepperestAxios.get(Invoices.INVOICE, {
            params,

        })
            .then(response => {
                const invoice = response.data.invoice
                dispatch(loadedInvoice(invoice))
            })
            .catch(error => {
                dispatch(failedToLoadSingleInvoice(InvoiceErrorMessages.getHistoryFailed))
            })
    };
}

export const confirmInvoice = (token, user, extraParams = {}, message) => dispatch => {
    const headers = {
        Authorization: token,
        customerID: user.customerID
    }
    const body = {
        ...extraParams
    }

    PepperestAxios.post(Invoices.CONFIRM_INVOICE, body, { headers }).then((response) => {
        dispatch(setAlert(message, 'success', getStringHash()))
        return (<Redirect to="/onboarding" />)

    }).catch((error) => {
        console.error(error.response)
        dispatch(setAlert('An error occurred', 'error', getStringHash()))
    })
}

export const failedToLoadSingleInvoice = (error) => {
    return {
        type: actionTypes.LOADING_SINGLE_INVOICE_FAILED,
        error: error,
    }
}







