import React from "react";

function index({ fill = "white" }) {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.47907 9.52937L8.94932 6.4634H6.00765V4.47466C6.00765 3.63566 6.41782 2.81738 7.73536 2.81738H9.07362V0.20716C9.07362 0.20716 7.85966 0 6.69956 0C4.27579 0 2.69308 1.46877 2.69308 4.12663V6.4634H0V9.52937H2.69308V16.9416C3.23377 17.0265 3.78689 17.07 4.35036 17.07C4.91384 17.07 5.46696 17.0265 6.00765 16.9416V9.52937H8.47907Z"
        fill={fill}
      />
    </svg>
  );
}

export default index;
