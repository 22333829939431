import React from "react";

function index() {
  return (
    <svg width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.7705 5.73539L24.5451 0.407361C23.0709 -0.135787 20.6655 -0.135787 19.1912 0.407361L4.96593 5.73539C2.22432 6.76996 0 9.97712 0 12.8998V33.8498C0 35.9448 1.3708 38.7123 3.05198 39.9537L17.2773 50.5839C19.7861 52.472 23.8985 52.472 26.4074 50.5839L40.6327 39.9537C42.3139 38.6864 43.6847 35.9448 43.6847 33.8498V12.8998C43.7105 9.97712 41.4862 6.76996 38.7705 5.73539ZM30.856 20.2193L19.7344 31.341C19.3464 31.7289 18.855 31.91 18.3636 31.91C17.8722 31.91 17.3808 31.7289 16.9928 31.341L12.8545 27.151C12.1045 26.4009 12.1045 25.1594 12.8545 24.4094C13.6046 23.6593 14.8461 23.6593 15.5961 24.4094L18.3895 27.2027L28.1403 17.4519C28.8903 16.7018 30.1318 16.7018 30.8819 17.4519C31.6319 18.2019 31.6319 19.4693 30.856 20.2193Z"
        fill="#FE8F01"
      />
      <rect x="11" y="16" width="23" height="21" fill="#FE8F01" />
      <path
        d="M15 30.6683C15 33.2549 17 35.3349 19.4534 35.3349H24.4666C26.6 35.3349 28.3333 33.5216 28.3333 31.2549C28.3333 28.8283 27.2667 27.9483 25.6934 27.3883L17.6667 24.5883C16.0933 24.0283 15.0267 23.1749 15.0267 20.7216C15.0267 18.4816 16.76 16.6416 18.8933 16.6416H23.9067C26.36 16.6416 28.36 18.7216 28.36 21.3083"
        stroke="white"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.667 14V38"
        stroke="white"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default index;
