import PepperestAxios from "../../libs/utils/PepperestAxios";
import { Customers, CustomersErrorMessages } from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { setAlert } from "./alert";
import { getStringHash } from "libs/utils";

export const loadCustomer = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch(loadingCustomer());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      merchantID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.get(Customers.CUSTOMER, { params, headers })
      .then((response) => {
        const customers = response.data.customers.data;
        const meta = response.data.customers.meta;
        const links = response.data.customers.links;
        dispatch(loadedCustomer(customers, meta, links));
      })
      .catch((error) => {
        dispatch(failedToLoadCustomer(CustomersErrorMessages.getHistoryFailed));
      });
  };
};

export const reportIssue =
  (token, user, extraParams = {}) =>
  (dispatch) => {
    dispatch({type:actionTypes.REPORT_ISSUE})
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Customers.REPORT_ISSUE, body, { headers })
      .then((res) => {
        dispatch({type:actionTypes.REPORT_ISSUE_SUCCESS})
        dispatch(setAlert("Your issue has been reported successfully", "success"));
      })
      .catch((err) => {
        if (err.response) console.error(err.response);
        else console.error(err);
        const message = err?.response?.data?err?.response?.data?.message:'An error occurred'
        dispatch({type:actionTypes.REPORT_ISSUE_FAIL})
        dispatch(setAlert(message, "error"));
      });
  };

export const requestRefund =
  (token, user, extraParams = {}) =>
  (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Customers.REQUEST_REFUND, body, { headers })
      .then((res) => {
        dispatch(setAlert("Request successful", "success"));
      })
      .catch((err) => {
        if (err.response) console.error(err.response);
        else console.error(err);
        dispatch(setAlert("An error occurred", "error"));
      });
  };
export const requestPayment =
  (token, user, extraParams = {}) =>
  (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Customers.REQUEST_PAYMENT, body, { headers })
      .then((res) => {
        dispatch(setAlert("Request successful", "success"));
      })
      .catch((err) => {
        if (err.response) console.error(err.response);
        else console.error(err);
        dispatch(setAlert("An error occurred", "error"));
      });
  };

export const loadingCustomer = () => {
  return {
    type: actionTypes.LOADING_CUSTOMERS,
  };
};

export const loadedCustomer = (customers, meta, links) => {
  const update = {
    customers,
    meta,
    links,
    loading: false,
    loaded: true,
    error: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_CUSTOMERS,
    update: update,
  };
};
export const failedToLoadCustomer = (error) => {
  return {
    type: actionTypes.LOADING_CUSTOMERS_FAILED,
    error: error,
  };
};
