import React, { useState, useRef, useEffect } from "react";
import { Button, Loader } from "components/blocks";
import { useForm } from "react-hook-form";
import { AbstractInput } from "components/forms";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { SpinnerIcon } from "../../vectors";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory, NavLink } from "react-router-dom";

const SignUpSchema = yup.object().shape({
  phone: yup.number("Field only accepts number").required("this field is required"),
  password: yup.string().required("Please Enter your password"),
});
const tryingToBuySchema = yup.object().shape({
  phone: yup.number("Field only accepts number").required("this field is required"),
  name: yup.string("Field only accepts string").required("this field is required"),
  email: yup.string().email(),
});

const RegisterForm = (props) => {
  const [state, setState] = useState({
    loading: false,
    registerForm: {
      accountType: {
        elementConfig: {
          name: "accountType",
          type: "select",
          placeholder: "Select an account type",
          label: "Choose a Pepperest Account type",
          id: "accountType",
          options: [
            {
              label: "Buyer",
              value: "Buyer",
            },
            {
              label: "Merchant",
              value: "Merchant",
            },
            {
              label: "Both",
              value: "Both",
            },
          ],
        },
        value: "",
        validation: {
          rules: {
            required: "Account type is required",
          },
          errorMessage: "",
        },
      },
      name: {
        elementConfig: {
          name: "name",
          type: "text",
          placeholder: "Your Firstname & Lastname",
          label: "Full Name",
          id: "name",
        },
        value: "",
        validation: {
          rules: {
            required: "Personal Name is required",
          },
          errorMessage: "",
        },
      },
      businessName: {
        elementConfig: {
          name: "businessName",
          type: "text",
          placeholder: "Your Business Name",
          label: "Business Name",
          id: "businessName",
        },
        value: "",
        validation: {
          rules: {
            required: "Personal / Business Name is required",
          },
          errorMessage: "",
        },
      },
      referal_code: {
        elementConfig: {
          name: "referal_code",
          type: "text",
          placeholder: "Your Referal Code if any?",
          label: "Referal code",
          id: "referal_code",
        },
        value: "",
        validation: {
          rules: {
            // required: "This Referal is inccorect",
          },
          errorMessage: "",
        },
      },
      phone: {
        elementConfig: {
          name: "phone",
          type: "tel",
          placeholder: "Your phone number i.e 08012345678",
          label: "Phone Number *",
          id: "phone",
        },
        value: "",
        validation: {
          rules: {
            required: "This field is required",
          },
          errorMessage: "",
        },
      },
      email: {
        elementConfig: {
          name: "email",
          type: "email",
          placeholder: "Email",
          label: "Email",
          id: "email",
        },
        value: "",
        validation: {
          rules: {
            required: "Must be a valid email address",
          },
          errorMessage: "",
        },
      },
      password: {
        elementConfig: {
          name: "password",
          type: "password",
          placeholder: "Strong password",
          label: "Password",
          id: "password",
        },
        value: "",
        validation: {
          rules: {
            required: "Password is required",
            // pattern: {
            //   value: /^[a-z0-9]+$/i,
            //   message: "password can only be alphabet and numbers",
            // },
          },
          errorMessage: "",
        },
      },
    },
  });
  const [acceptTerms, setAcceptTerms] = useState(false);

  const tryingToBuy = localStorage.getItem("product");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(tryingToBuy ? tryingToBuySchema : SignUpSchema),
  });

  const history = useHistory();

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedRegisterForm = {
      ...state.registerForm,
    };
    const updatedFormElement = {
      ...updatedRegisterForm[inputIdentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedRegisterForm[inputIdentifier] = updatedFormElement;
    setState({ ...state, registerForm: updatedRegisterForm });
  };
  const registerHandler = () => {
    if (!acceptTerms) return alert("please accept our terms and condition to proceed");
    setState({ ...state, loading: true });
    let payLoad = {
      name: state.registerForm.name.value,
      accountType: state.registerForm.accountType.value,
      businessName: state.registerForm.businessName.value,
      phone: state.registerForm.phone.value,
      email: state.registerForm.email.value,
      password: state.registerForm.password.value,
      referral_code: state.registerForm.referal_code.value,
    };

    if (!tryingToBuy) {
      props.onRegister(payLoad);
      return history.push("/cart");
    }
    payLoad = {
      fullname: state.registerForm.name.value,
      phone: state.registerForm.phone.value,
      email: state.registerForm.email.value,
    };
    props.miniRegistration(payLoad);
    return history.push("/cart");
  };

  useEffect(() => {
    if (state.registerForm.accountType.value !== "Buyer") {
      props.setReduceHeightForBuyer(false);
    } else {
      props.setReduceHeightForBuyer(true);
    }
  }, [state.registerForm?.accountType.value]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.referal_code) {
      const updatedRegisterForm = {
        ...state.registerForm,
      };
      const updatedFormElement = {
        ...updatedRegisterForm.referal_code,
      };
      updatedFormElement.value = params.referal_code;
      updatedRegisterForm.referal_code = updatedFormElement;
      setState({ ...state, registerForm: updatedRegisterForm });
    }
  }, []);

  let namesToNotShow = ["accountType", "businessName", "referal_code", "password"];

  const showInput = (inputName) => {
    if (tryingToBuy) {
      if (namesToNotShow.includes(inputName)) return false;
    }
    if (inputName !== "businessName") return true;

    return state.registerForm.accountType.value !== "Buyer";
  };

  return (
    <>
      <form className="nsForm" onSubmit={handleSubmit(registerHandler)}>
        {Object.values(state.registerForm).map((formInput, index) => {
          return (
            <span key={index} className="mb-1">
              {showInput(formInput.elementConfig.name) && (
                <AbstractInput
                  key={formInput.elementConfig.name}
                  value={formInput.value}
                  elementConfig={formInput.elementConfig}
                  onChange={(event) => inputChangedHandler(event, formInput.elementConfig.name)}
                  validation={register(formInput.validation.rules)}
                  errorMessage={
                    (errors[formInput.elementConfig.name] &&
                      errors[formInput.elementConfig.name].message) ||
                    props.errors[formInput.elementConfig.name]
                  }
                />
              )}
            </span>
          );
        })}

        <div>
          <p className="auth-terms-condition text-center">
            By {tryingToBuy ? "Clicking Submit" : "Creating an Account"}, you agree to{" "}
            <NavLink to="/privacy">Pepperest Terms</NavLink> and{" "}
            <NavLink to="/privacy">Conditions</NavLink>.
          </p>
          <div className="form-check d-flex align-items-center justify-content-start">
            <input
              className="form-check-input"
              type="checkbox"
              value={acceptTerms}
              checked={acceptTerms === true}
              onChange={() => setAcceptTerms(!acceptTerms)}
              id="flexCheckDefault"
            />
            <label className="form-check-label auth-terms-condition" for="flexCheckDefault">
              I Agree
            </label>
          </div>
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <Button
            type="submit"
            value={tryingToBuy ? "Submit" : "Sign up"}
            name="sign_up"
            handleClick={() => {}}
          />
        )}
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  errors: state.auth.errors,
});

const mapDispatchToProps = (dispatch) => ({
  onRegister: (payLoad) => dispatch(actions.register(payLoad)),
  miniRegistration: (payLoad) => dispatch(actions.miniRegistration(payLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
