import React from "react";

function index() {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.0436 15.21L44.4152 9.20833C43.5052 2.665 40.5369 0 34.1886 0H29.0752H25.8686H19.2819H16.0752H10.8752C4.50523 0 1.55856 2.665 0.626898 9.27333L0.0418978 15.2317C-0.174769 17.55 0.453565 19.8033 1.81856 21.5583C3.46523 23.7033 6.00023 24.9167 8.8169 24.9167C11.5469 24.9167 14.1686 23.5517 15.8152 21.3633C17.2886 23.5517 19.8019 24.9167 22.5969 24.9167C25.3919 24.9167 27.8402 23.6167 29.3352 21.45C31.0036 23.595 33.5819 24.9167 36.2686 24.9167C39.1502 24.9167 41.7502 23.6383 43.3752 21.385C44.6752 19.6517 45.2602 17.4633 45.0436 15.21Z"
        fill="#FE8F01"
      />
      <path
        d="M21.1886 33.3889C18.4369 33.6705 16.3569 36.0105 16.3569 38.7839V44.7205C16.3569 45.3055 16.8336 45.7822 17.4186 45.7822H27.7536C28.3386 45.7822 28.8153 45.3055 28.8153 44.7205V39.5422C28.8369 35.0139 26.1719 32.8689 21.1886 33.3889Z"
        fill="#FE8F01"
      />
      <path
        d="M42.8983 28.4917V34.9483C42.8983 40.9283 38.0449 45.7817 32.0649 45.7817C31.4799 45.7817 31.0033 45.305 31.0033 44.72V39.5417C31.0033 36.7683 30.1583 34.6017 28.5116 33.1283C27.0599 31.8067 25.0883 31.1567 22.6399 31.1567C22.0983 31.1567 21.5566 31.1783 20.9716 31.2433C17.1149 31.6333 14.1899 34.8833 14.1899 38.7833V44.72C14.1899 45.305 13.7133 45.7817 13.1283 45.7817C7.14826 45.7817 2.29492 40.9283 2.29492 34.9483V28.535C2.29492 27.0183 3.78992 26 5.19826 26.4983C5.78326 26.6933 6.36826 26.845 6.97492 26.9317C7.23492 26.975 7.51659 27.0183 7.77659 27.0183C8.12325 27.0617 8.46992 27.0833 8.81659 27.0833C11.3299 27.0833 13.7999 26.1517 15.7499 24.5483C17.6133 26.1517 20.0399 27.0833 22.5966 27.0833C25.1749 27.0833 27.5583 26.195 29.4216 24.5917C31.3716 26.1733 33.7983 27.0833 36.2683 27.0833C36.6583 27.0833 37.0483 27.0617 37.4166 27.0183C37.6766 26.9967 37.9149 26.975 38.1533 26.9317C38.8249 26.845 39.4316 26.65 40.0383 26.455C41.4466 25.9783 42.8983 27.0183 42.8983 28.4917Z"
        fill="#FE8F01"
      />
    </svg>
  );
}

export default index;
