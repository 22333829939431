import React from 'react'
import { currencyFormatter } from 'store/utility'

const OverviewCard = ({title,itemCount, type,icon}) => {
  return (
    <div className={`metric__card ${type}__bg`}>
      <div className='card__content'>
      <p className='card__title'>{title}</p>
        <p className='item__count'>{type=='revenues'?currencyFormatter.format(itemCount?itemCount:0):itemCount}</p>
      </div>
      
        {/* <img src={`/assets/images/svg/${icon}`} className='card__icon' /> */}
       
    </div>
  )
}

export default OverviewCard