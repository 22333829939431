import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Invoices,
  InvoiceErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { PAGE_TYPES as InvoicesPageTypes } from "libs/constants/PepperestWebServices/Invoices";
import { setAlert } from "./alert";
import { getStringHash } from "libs/utils";
import { getFromStorage, setToStorage } from "buyer-constants";

// dispatch( setAlert('An error occurred.', 'error', getStringHash()))

export const loadInvoices = (token, user, pageType, extraParams = {}) => {
  return (dispatch) => {
    dispatch(loadingInvoices(pageType));
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };

    const params = {
      merchant_id: user.customerID,
      perPage: 6,
      ...extraParams,
    };
    // const params = {
    //     invoice_typ: invoice_typ,
    //     merchant_id: user.customerID,
    //     perPage: 6,
    //     pageType: pageType
    // }

    PepperestAxios.get(Invoices.INVOICES, { params, headers })
      .then((response) => {
        if (pageType === InvoicesPageTypes.DASHBOARD) {
          const total = response.data.total;
          const canceled = response.data.canceled;
          const pending = response.data.pending;
          const confirmed = response.data.confirmed;
          const today_invoices = response.data.today_invoices;
          const yesterday_invoices = response.data.yesterday_invoices;
          dispatch(
            loadedInvoicesDashboard(
              total,
              canceled,
              pending,
              confirmed,
              today_invoices,
              yesterday_invoices,
              pageType
            )
          );
        } else {
          const invoices = response.data.invoices.data;
          const meta = response.data.invoices.meta;
          const links = response.data.invoices.links;
          dispatch(loadedInvoices(invoices, meta, links, pageType));
        }
      })
      .catch((error) => {
        dispatch(
          failedToLoadInvoices(pageType, InvoiceErrorMessages.getHistoryFailed)
        );
      });
  };
};

export const confirmInvoicePayment = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch(confirmLoading(true));
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      ...extraParams,
    };
    PepperestAxios.get(Invoices.CONFIRM_INVOICE_PAYMENT, { params, headers })
      .then((response) => {
        dispatch({
          type: actionTypes.CONFIRM_INVOICE_DETAILS,
          payload: {
            confirmInvoiceDetails: response.data,
          },
        });
        dispatch(confirmLoading(false));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(
          setAlert(
            "Fail to confirm invoice An error occurred.",
            "error",
            getStringHash()
          )
        );
        dispatch(confirmLoading(false));
      });
  };
};

export const setAddress = (addresses) => {
  return {
    type: actionTypes.LOAD_ADDRESS,
    addresses,
  };
};

const confirmLoading = (value) => {
  return {
    type: actionTypes.CONFIRM_LOADING,
    loading: value,
  };
};

export const loadingInvoices = (pageType) => {
  return {
    type: actionTypes.LOADING_INVOICES,
    pageType: pageType,
  };
};

export const loadedInvoicesDashboard = (
  total,
  canceled,
  pending,
  confirmed,
  today_invoices,
  yesterday_invoices,
  pageType
) => {
  const pageTypeUpdate = {
    total: total,
    canceled: canceled,
    pending: pending,
    confirmed: confirmed,
    today_invoices: today_invoices,
    yesterday_invoices: yesterday_invoices,
    loading: false,
    loaded: true,
    error: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_INVOICES,
    pageType: pageType,
    pageTypeUpdate: pageTypeUpdate,
  };
};
export const loadedInvoices = (invoices, meta, links, pageType) => {
  const pageTypeUpdate = {
    invoices,
    meta,
    links,
    loading: false,
    loaded: true,
    error: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_INVOICES,
    pageType: pageType,
    pageTypeUpdate: pageTypeUpdate,
  };
};
export const failedToLoadInvoices = (pageType, error) => {
  return {
    type: actionTypes.LOADING_INVOICES_FAILED,
    pageType: pageType,
    error: error,
  };
};

export const loadedSingleInvoice = (invoices) => {
  const pageTypeUpdate = {
    invoices,
  };
  return {
    type: actionTypes.FINISHED_LOADING_INVOICES,
    pageTypeUpdate: pageTypeUpdate,
  };
};

export const loadSingleInvoice = (extraParams = {}) => {
  return (dispatch) => {
    //dispatch(loadingInvoices(pageType))

    const params = {
      ...extraParams,
    };

    PepperestAxios.get(Invoices.INVOICE, { params })
      .then((response) => {
        const invoice = response.data.invoice;
        console.log(invoice);
        dispatch(loadedSingleInvoice(invoice));
      })
      .catch((error) => {
        //dispatch(failedToLoadInvoices(pageType, InvoiceErrorMessages.getHistoryFailed))
      });
  };
};
const redirect = (url) => {
  return {
    type: actionTypes.PLACE_INVOICE_REDIRECT,
    redirectUrl: url,
  };
};

export const placeInvoice = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Invoices.PLACE_INVOICE, body, { headers: headers })
      .then((response) => {
        console.log(response.data);
        dispatch(redirect(response.data.paymentUrl));
        dispatch(
          setAlert("Invoice placed successfully.", "success", getStringHash())
        );
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(setAlert("An error occurred.", "error", getStringHash()));
      });
  };
};

export const createInvoice = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.CREATE_INVOICE });
    const { token, userInfo } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
      "Content-Type": "multipart/form-data",
    };
    const body = {
      customerID: userInfo.customerID,
      ...data,
    };
    PepperestAxios.post(Invoices.CREATE_INVOICE, data, { headers })
      .then((response) => {
        console.log({ response });
        dispatch({ type: actionTypes.CREATE_INVOICE_SUCCESS });
        dispatch(
          setAlert("Invoice placed successfully.", "success", getStringHash())
        );
      })
      .catch((error) => {
        console.log({ error });
        const message = error?.response?.data?.ResponseMessage;
        dispatch({ type: actionTypes.CREATE_INVOICE_FAIL });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};

export const createInvoiceComplete = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_INVOICE_COMPLETE });
  };
};

export const getInvoices = (params) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.GET_INVOICES });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.get(Invoices.NEW_INVOICES, { params, headers })
      .then((response) => {
        const invoices = response.data.orders.data;
        const meta = response.data.orders.meta;
        dispatch({
          type: actionTypes.GET_INVOICES_SUCCESS,
          invoices: { data: invoices, meta },
        });
      })
      .catch((error) => {
        const message = error?.response?.data?.ResponseMessage;
        dispatch({ type: actionTypes.GET_INVOICES_FAIL, error: message });
      });
  };
};

export const getSingleInvoice = (orderID) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.GET_INVOICE });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };
    const params = {
      orderID,
    };

    PepperestAxios.get(Invoices.NEW_SINGLE_INVOICE, { params, headers })
      .then((response) => {
        console.log({ response });
        const order = response?.data?.order;
        dispatch({
          type: actionTypes.GET_INVOICE_SUCCESS,
          order,
        });
      })
      .catch((error) => {
        console.log({ error });
        const message = error?.response?.data?.ResponseMessage;
        dispatch({ type: actionTypes.GET_INVOICE_FAIL, error: message });
      });
  };
};

export const updateInvoice = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATING_INVOICE });
    const { token, userInfo } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
      "Content-Type": "multipart/form-data",
    };

    PepperestAxios.post(Invoices.UPDATE_INVOICE, data, { headers })
      .then((response) => {
        console.log({ response });
        dispatch({ type: actionTypes.UPDATE_INVOICE_SUCCESS });
        dispatch(
          setAlert("Invoice placed successfully.", "success", getStringHash())
        );
      })
      .catch((error) => {
        console.log({ error });
        const message = error?.response?.data?.ResponseMessage;
        dispatch({ type: actionTypes.UPDATE_INVOICE_FAIL });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};

export const updateInvoiceComplete = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_INVOICE_COMPLETE });
  };
};

export const changeInvoiceStatus = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.CHANGE_INVOICE_STATUS_START });
    const { token, userInfo } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };
    const params = {
      ...data,
    };

    PepperestAxios.get(Invoices.CHANGE_INVOICE_STATUS, { params, headers })
      .then((response) => {
        console.log({ response });
        dispatch({ type: actionTypes.CHANGE_INVOICE_STATUS_SUCCESS });
        dispatch(
          setAlert("Status updated successfully.", "success", getStringHash())
        );
      })
      .catch((error) => {
        console.log({ error });
        const message = error?.response?.data?.ResponseMessage;
        dispatch({ type: actionTypes.CHANGE_INVOICE_STATUS_FAIL });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};

export const getInvoiceByRef = (orderRef) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_INVOICE });
    const headers = {
      "Content-Type": "application/json",
    };
    const params = {
      orderRef,
    };

    PepperestAxios.get(Invoices.INVOICE_BY_REF, { params, headers })
      .then((response) => {
        console.log({ response });
        const { merchantCode, storeName, order, token } = response?.data;
        setToStorage("storeID", merchantCode);
        setToStorage("businessname", storeName);
        setToStorage("userToken", token?.access_token);
        dispatch({
          type: actionTypes.GET_INVOICE_SUCCESS,
          order,
        });
      })
      .catch((error) => {
        console.log({ error });
        const message = error?.response?.data?.ResponseMessage;
        dispatch({ type: actionTypes.GET_INVOICE_FAIL, error: message });
      });
  };
};

export const verifyInvoicePayment = (orderID, reference) => {
  return (dispatch, getState) => {
    const token = getFromStorage("userToken");
    dispatch({ type: actionTypes.VERIFY_INVOICE_PAYMENT });
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = {
      orderID,
      reference,
    };

    PepperestAxios.get(Invoices.VERIFY_INVOICE_PAYMENT_REF, { params, headers })
      .then((response) => {
        console.log({ response });
        const { paymentDetails, paymentStatus } = response.data;
        dispatch({
          type: actionTypes.VERIFY_INVOICE_PAYMENT_SUCCESS,
          paymentInfo: { paymentDetails, paymentStatus },
        });
      })
      .catch((error) => {
        console.log({ error });
        const message = error?.response?.data?.ResponseMessage;
        dispatch({
          type: actionTypes.VERIFY_INVOICE_PAYMENT_FAIL,
          error: message,
        });
      });
  };
};

export const verifyTransfer = (orderID) => {
  return (dispatch, getState) => {
    const token = getFromStorage("userToken");
    dispatch({ type: actionTypes.VERIFY_INVOICE_PAYMENT });
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = {
      orderID,
    };

    PepperestAxios.get(Invoices.VERIFY_TRANSFER, { params, headers })
      .then((response) => {
        const { paymentDetails, paymentStatus } = response.data;
        dispatch({
          type: actionTypes.VERIFY_INVOICE_PAYMENT_SUCCESS,
          paymentInfo: { paymentDetails, paymentStatus },
        });
      })
      .catch((error) => {
        const message = error?.response?.data?.ResponseMessage;
        dispatch({
          type: actionTypes.VERIFY_INVOICE_PAYMENT_FAIL,
          error: message,
        });
      });
  };
};

export const uploadInvoiceProof = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.UPLOAD_INVOICE_PROOF });
    const { token, userInfo } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
      "Content-Type": "multipart/form-data",
    };
    const body = {
      customerID: userInfo.customerID,
      ...data,
    };
    PepperestAxios.post(Invoices.UPLOAD_INVOICE_PROOF, data, { headers })
      .then((response) => {
        dispatch({ type: actionTypes.UPLOAD_INVOICE_PROOF_SUCCESS });
        dispatch(setAlert("Uploaded uccessfully.", "success", getStringHash()));
      })
      .catch((error) => {
        const message = error?.response?.data?.ResponseMessage;
        dispatch({
          type: actionTypes.UPLOAD_INVOICE_PROOF_FAIL,
          error: message,
        });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};

export const uploadInvoiceProofComplete = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPLOAD_INVOICE_PROOF_COMPLETE });
  };
};

export const requestPayment = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_INVOICE_PAYMENT });
    const { token, userInfo } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
      "Content-Type": "application/json",
    };
    const body = {
      customerID: userInfo.customerID,
      ...data,
    };
    PepperestAxios.post(Invoices.REQUEST_PAYMENT, data, { headers })
      .then((response) => {
        dispatch({ type: actionTypes.REQUEST_INVOICE_PAYMENT_SUCCESS });
        const message = response?.data?.ResponseMessage;
        dispatch(setAlert(message, "success", getStringHash()));
      })
      .catch((error) => {
        const message = error?.response?.data?.ResponseMessage;
        dispatch({
          type: actionTypes.REQUEST_INVOICE_PAYMENT_FAIL,
          error: message,
        });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};

export const deleteInvoice = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_INVOICE });
    const { token, userInfo } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
      "Content-Type": "application/json",
    };
   
    PepperestAxios.post(Invoices.DELETE_INVOICE, data, { headers })
      .then((response) => {
        dispatch({ type: actionTypes.DELETE_INVOICE_SUCCESS });
        const message = response?.data?.ResponseMessage;
        dispatch(setAlert(message, "success", getStringHash()));
      })
      .catch((error) => {
        const message = error?.response?.data?.ResponseMessage;
        dispatch({
          type: actionTypes.DELETE_INVOICE_FAIL,
          error: message,
        });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};
