import ArrowRight from 'components/vectors/ArrowRight';
import React from 'react'
import { Link } from 'react-router-dom';

function BreadCrumb({crumbs}) {
    if (crumbs.length <= 1) {
        return null;
      }
      
  return (
    <div className='bread__crumb'>
        {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <span key={key}>
            {name}
          </span>
        ) : (
          <Link key={key} to={path}>
            <span className=''>{name} {' '}</span>
            <ArrowRight />
          </Link>
        )
      )}

    </div>
  )
}

export default BreadCrumb