import React from "react";

const ArrowUp = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9401 11.2876L10.0501 6.3976C9.47256 5.8201 8.52756 5.8201 7.95006 6.3976L3.06006 11.2876"
      stroke="#5E60CE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUp;
