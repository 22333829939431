/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import { withDefaultLayout } from "components/layouts";
import { getStringHash } from "libs/utils";
import { useHistory } from "react-router-dom";

import { Input, InputWithoutLabel } from "components/blocks";
// import { PepperestContext } from "components/helpers/constant";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
// import {useReactToPrint} from "react-to-print";
var numeral = require("numeral");

const config = {
  hasAlternateHeader: false,
  hasCommonHeader: true,
  showCart: false,
  commonHeaderTitle: "Forex Invoice",
  links: [],
  page: "forex-invoice",
  isSettings: true,
  navBarTitle: "Create Customer Invoice",
};
const ForexInvoicePage = ({
  user,
  token,
  storeProducts,
  addresses,
  load,
  createInvoice,
  setAlert,
}) => {
  const history = useHistory();
  const [proof_of_payment, setProveOfPayment] = useState("");
  const [customerEmail, setCE] = useState("");
  const [customerName, setCN] = useState("");
  const [sellerAddress, setSellerAddress] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [tax, setTax] = useState(7.5);
  const [fxCurrency, setFxCurrency] = useState("");
  const [deliveryPeriod, setDP] = useState("");
  const [fxAmount, setFxAmount] = useState("");
  const [agreedRate, setAgreedRate] = useState("");
  const [storeQuantity, setSQ] = useState("");
  const [nairaAmount, setNairaAmount] = useState("");
  const [products, setProducts] = useState([]);
  const [storeSelectedProduct, setStoreSelecetedProduct] = useState("");
  const [userAddress, setUA] = useState("");
  const [error, setErr] = useState(false);
  const [productError, setPErr] = useState(false);
  // const [productz, setProductz] = useState("");
  const pepperestFees = 2.5;
  const date = new Date();

  useEffect(() => {
    load(token, user);
  }, []);

  const calculateNairaAmount = (rate, amount) => {
    let nairaAmount = Number(amount) * Number(rate);
    return nairaAmount;
  };
  const handleChangeSelectProducts = (event) => {
    // setProductz(event.target.value);
    const [p] = storeProducts.filter((x) => x.id == event.target.value);
    // const [q] = products.filter(x => x.name === p.fxCurrency)
    var indexOfq = products.findIndex((i) => i.fxCurrency === p.fxCurrency);
    if (indexOfq < 0) {
      const product = {
        fxCurrency: p.fxCurrency,
        fxAmount: p.fxAmount,
        agreedRate: p.agreedRate,
        nairaAmount: p.nairaAmount,
        proof_of_payment: p.proof_of_payment,
        totalCost: p.nairaAmount,
      };
      setProducts([...products, product]);
      setAlert("Item added to Invoice", "success", getStringHash());
    } else {
      const q = products[indexOfq];
      q.quantity += 1;
      let np = [...products];
      np[indexOfq] = q;
      setProducts(np);
      setAlert("Item added to Invoice", "success", getStringHash());
    }
  };

  const addFromStore = () => {
    if (storeSelectedProduct && storeQuantity) {
      const [p] = storeProducts.filter((x) => x.id == storeSelectedProduct);
      // const [q] = products.filter(x => x.name === p.fxCurrency)
      var indexOfq = products.findIndex((i) => i.fxCurrency === p.fxCurrency);
      if (indexOfq < 0) {
        const product = {
          fxCurrency: p.fxCurrency,
          fxAmount: p.fxAmount,
          agreedRate: p.agreedRate,
          nairaAmount: p.nairaAmount,
          totalCost: nairaAmount,
        };
        setProducts([...products, product]);
        setStoreSelecetedProduct("");
        setSQ("");
        setAlert("Item added to invoice", "success", getStringHash());
      } else {
        setAlert("Item already in invoice", "error", getStringHash());
      }
    } else {
      setAlert("Select a product & enter a quantity", "error", getStringHash());
    }
  };

  const [address] = addresses.filter((a) => a.address_id == userAddress);

  const add = () => {
    if ((fxCurrency, fxAmount, agreedRate, nairaAmount)) {
      const product = {
        fxCurrency: fxCurrency,
        fxAmount: fxAmount,
        agreedRate: agreedRate,
        nairaAmount: nairaAmount,
        totalCost: nairaAmount,
        proof_of_payment: proof_of_payment,
      };
      setProducts([...products, product]);
      setFxCurrency("");
      setFxAmount("");
      setAgreedRate("");
      setNairaAmount("");
      setAlert("Item added to Invoice", "success", getStringHash());
      setPErr(false);
    } else {
      setPErr(true);
      setAlert("Please complete the Product form", "error", getStringHash());
    }
  };
  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  // const handlePrint = () => {
  //   console.log("print")
  // }
  const remove = (i) => {
    let p = [...products];
    p.splice(i, 1);
    setProducts(p);
    setAlert("Item removed from Invoice", "success", getStringHash());
  };

  const calcTotal = () => {
    let total = 0;
    for (var i = 0, _len = products.length; i < _len; i++) {
      total += products[i].fxAmount * products[i].agreedRate;
    }
    return total;
  };
  const amount = () => {
    let total = 0;
    total = calcTotal() * ((pepperestFees + 100) / 100);
    return numeral(total).format("0,0.00");
  };

  const AlertCloseIcon = ({ className, onClick }) => (
    <svg
      xm0lns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      onClick={() => {
        onClick();
      }}
    >
      <path
        fill="#FF721B"
        d="M12 0c6.624.008 11.992 5.376 12 12 0 6.628-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5c5.796-.006 10.494-4.704 10.5-10.5 0-5.799-4.701-10.5-10.5-10.5zM6.166 6.148c.298-.288.773-.28 1.061.018L12 10.94l4.773-4.773c.293-.292.768-.292 1.06 0 .293.293.293.768 0 1.061L13.062 12l4.772 4.773c.281.29.281.751 0 1.042-.287.298-.762.306-1.06.019L12 13.06l-4.792 4.79c-.297.289-.772.28-1.06-.017-.288-.299-.28-.773.018-1.061L10.94 12 6.166 7.227l-.018-.019c-.288-.297-.28-.772.018-1.06z"
      />
    </svg>
  );
  const submit = () => {
    if (
      products.length &&
      proof_of_payment &&
      customerEmail &&
      phoneNumber &&
      customerName &&
      tax &&
      deliveryPeriod
    ) {
      const selectedAddress = address ? address.address : "";
      const extraParams = {
        invoiceType: "FX",
        userAddress: selectedAddress,
        addressLine_1: sellerAddress,
        customerEmail: customerEmail,
        customerPhone: phoneNumber,
        customerName: customerName,
        totalcost: calcTotal() * ((tax + pepperestFees + 100) / 100),
        deliveryPeriod: deliveryPeriod,
        vat: calcTotal() * (tax / 100),
        fxDetails: products,
        currency: "NGN",
        pepperest_fee: calcTotal() * (pepperestFees / 100),
      };

      createInvoice(token, user, extraParams);
      setErr(false);
      if (!error) {
        setProveOfPayment("");
        setCE("");
        setCN("");
        setSellerAddress("");
        setPhone("");
        setDP("");
        setProducts([]);
      }
    } else if (products.length === 0) {
      setAlert("Select/Add Item to invoice", "error", getStringHash());
    } else {
      setAlert("Please Fill out all Invoice Data Fields", "error", getStringHash());
      setErr(true);
    }
  };

  return (
    <section>
      <div className="row" style={{ margin: "8px" }}>
        <div className="col-md-12 col-sm-12">
          <div className="d-flex justify-content-start">
            <button
              className="button button--auto button-md button--orange justify-content-end"
              onClick={() => {
                history.push("invoice");
              }}
            >
              Create Product Invoice
            </button>
          </div>
        </div>
      </div>

      <div className="row justify-content-center" style={{ margin: "8px", background: "white" }}>
        <div className="col-md-4 col-sm-12" style={{ paddingTop: "12px", paddingBottom: "12px" }}>
          <div className="d-flex justify-content-center">
            <h3>
              <b>Invoice Data</b>
            </h3>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="inputEmail4">Seller Name</label>
            <input
              name="customerName"
              type="text"
              placeholder=""
              id="customerName"
              required
              value={customerName}
              onChange={(e) => setCN(e.target.value)}
              className="form-control"
              errorMessage={error && !customerName ? "required" : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="inputEmail4">Seller Email</label>
            <input
              name="email"
              type="email"
              placeholder=""
              id="email"
              required
              value={customerEmail}
              onChange={(e) => setCE(e.target.value)}
              errorMessage={error && !customerEmail ? "required" : ""}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label htmlFor="inputEmail4">Seller Phone Number</label>
            <input
              name="customer_phone"
              type="tel"
              placeholder=""
              required
              id="customer_phone"
              value={phoneNumber}
              onChange={(e) => setPhone(e.target.value)}
              errorMessage=""
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputEmail4">Agreed offshore Proof of Payment</label>
            <select
              className="form-control"
              value={proof_of_payment}
              onChange={(e) => setProveOfPayment(e.target.value)}
            >
              <option value={0}> Select Proof of Payment </option>

              <option value="email"> Email</option>
              <option value="screenshot"> Screenshot</option>
              <option value="payment advice"> Payment advice</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="inputEmail4">Seller Address</label>
            <input
              name="sellerAddress"
              type="text"
              placeholder=""
              id="sellerAddress"
              required
              value={sellerAddress}
              onChange={(e) => setSellerAddress(e.target.value)}
              className="form-control"
              errorMessage={error && !sellerAddress ? "required" : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="inputEmail4">Estimated Transaction Completion Date</label>
            <input
              name="deliveryPeriod"
              type="number"
              placeholder="Number of days "
              id="deliveryPeriod"
              required
              value={deliveryPeriod}
              onChange={(e) => setDP(e.target.value)}
              errorMessage={error && !deliveryPeriod ? "required" : ""}
              className="form-control"
            />
          </div>

          {addresses?.length > 0 &&
            addresses.map((address) => (
              <div className="form-check" key={address.address_id}>
                <input
                  type="radio"
                  className="form-check-input"
                  value={address.address_id}
                  onChange={(event) => setUA(event.target.value)}
                  id={`${address.address_id}`}
                  name="address"
                />

                <label className="form-check-label" htmlFor={address.address_id}>
                  {address.address}
                </label>
              </div>
            ))}
        </div>
        <div className="col-md-4 col-sm-12" style={{ paddingTop: "12px", paddingBottom: "12px" }}>
          <div className="d-flex justify-content-center">
            <h3>
              <b>Forex Data</b>
            </h3>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="fxcurrentcy">FX Currency</label>
            <select
              className="form-control"
              value={fxCurrency}
              onChange={(e) => setFxCurrency(e.target.value)}
            >
              <option value={0}> Select FX Currentcy </option>

              <option value="USD"> USD</option>
              <option value="pounds"> Pounds</option>
              <option value="euro"> Euro</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="fxAmount">FX Amount</label>
            <input
              type="number"
              placeholder=""
              id="cost_item"
              required
              name="description"
              value={fxAmount}
              onChange={(e) => {
                setFxAmount(e.target.value);
              }}
              errorMessage={productError && !nairaAmount ? "required" : ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="agreedrate">Agreed Rate</label>
            <input
              name="agreedRate"
              type="number"
              placeholder=""
              required
              id="agreedRate"
              value={agreedRate}
              onChange={(e) => {
                setAgreedRate(e.target.value);
              }}
              onBlur={(e) => {
                setNairaAmount(calculateNairaAmount(fxAmount, agreedRate));
              }}
              errorMessage={productError && !agreedRate ? "required" : ""}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label htmlFor="nariaamount">Naira Amount</label>
            <input
              name="cost_item"
              type="number"
              placeholder=""
              id="cost_item"
              required
              value={nairaAmount}
              disabled
              errorMessage={productError && !nairaAmount ? "required" : ""}
              className="form-control"
            />
          </div>

          <div className="button  button--orange" onClick={add}>
            + ADD ITEM TO INVOICE
          </div>
        </div>
      </div>
      <div className="invoice">
        <div className="row">
          <div ref={componentRef} className="col-md-12 col-lg-12">
            <div className="invoice-card" style={{ marginTop: "8px" }}>
              <div className="invoice-header">
                <div className="col-md-2 col-sm-12">
                  <img
                    className="pepperest-logo"
                    src="/assets/images/logo/pepperest-logo-white.png"
                    alt="pepperest logo"
                    height="40px"
                    width="30px"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="details-container">
                    <div className="invoice-userdetails" style={{ fontSize: "18px" }}>
                      <p>{user?.name + " 's Invoice"}</p>
                      <p>{user?.phone}</p>
                      <p>{user?.email}</p>
                    </div>
                  </div>
                </div>
                <img
                  className="pepperest-logo"
                  src="/assets/images/beta11.png"
                  height="50px"
                  width="50px"
                  alt="beta logo"
                />
              </div>
              <div className="invoice-subcontent">
                <div className="subcontent-address">
                  <div className="col-md-6 col-sm-12">
                    <div className="billed-to">
                      <p className="grey-format">Billed To</p>
                      <p style={{ width: "max-content" }}>Seller Name: : {customerName}</p>
                      <p style={{ width: "max-content" }}>Seller Email: {customerEmail}</p>
                      <p style={{ width: "max-content" }}>Seller Phone No.: {phoneNumber}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="invoice-number">
                      <p className="grey-format">Invoice Number</p>
                      <p>{getStringHash()}</p>
                      <p className="grey-format">Date of Issue</p>
                      <p>{date.toLocaleDateString()}</p>
                    </div>
                  </div>
                </div>
                <div className="invoice-total">
                  <p className="grey-format">Invoice Total</p>
                  {/* <p>N{calcTotal() * ((tax + pepperestFees + 100)/100)}</p> */}
                  <p>N{amount()}</p>
                </div>
              </div>
              <div className="invoice-content">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">FX Currency</th>
                        <th scope="col">FX Amount</th>
                        <th scope="col">Agreed Amount</th>
                        <th scope="col">Naira Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.length > 0 &&
                        products.map((item, index) => (
                          <tr key={getStringHash()}>
                            <th scope="row">
                              <p>
                                <strong>{item.fxCurrency}</strong>
                              </p>
                            </th>
                            <td>
                              <p className="item-quantity">{item.fxAmount}</p>
                            </td>
                            <td>
                              {" "}
                              <p className="item-cost">N{item.agreedRate}</p>
                            </td>
                            <td>
                              <p className="item-amount">N{item.fxAmount * item.agreedRate}</p>
                            </td>
                            <AlertCloseIcon
                              onClick={(index) => remove(index)}
                              className="alert-icon"
                            />
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <div className="total-items">
                  <div className="total-items-container">
                    <div className="total-items-content">
                      <p>Subtotal</p>
                      <p>N{calcTotal()}</p>
                    </div>
                    <div className="total-items-content">
                      <p>Tax</p>
                      <p>N{numeral((calcTotal() * tax) / 100).format("0,0.00")}</p>
                    </div>
                    <div className="total-items-content">
                      <p>Pepperest Fees</p>
                      <p>N{numeral((calcTotal() * pepperestFees) / 100).format("0,0.00")}</p>
                    </div>
                    <div className="total-items-content">
                      <p>Total</p>
                      {/* <p>N{calcTotal() * ((tax + pepperestFees + 100)/100)}</p> */}
                      <p>N{amount() > 0 ? amount() + 50 : amount()}</p>
                    </div>
                    <div className="total-items-content mt-20">
                      <p>Amount Due(Naira)</p>
                      {/* <p>N{calcTotal() * ((tax + pepperestFees + 100)/100)}</p> */}
                      <p>N{amount() > 0 ? amount() + 50 : amount()}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-footer">
                <div className="invoice-footer-child">
                  {/* <div className="button button--auto button-md button--orange" >
                  Download
                </div> */}

                  <div className="col-md-12 col-sm-12">
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={submit}
                        className="button button--auto button-md button--orange ml-15 align-right"
                        style={{ marginBottom: "12px" }}
                      >
                        Send Invoice
                      </button>
                    </div>
                  </div>
                </div>
                <div className="pModal-main__notification text--smallest">
                  A payment link would be created and sent to the customer email address
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    token: state.auth.token,
    storeProducts: state.products.products,
    addresses: state.orders.addresses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (token, user, extraParams) =>
      dispatch(actions.loadProductsAndAddress(token, user, extraParams)),
    createInvoice: (token, user, extraParams) =>
      dispatch(actions.createInvoice(token, user, extraParams)),
    setAlert: (message, type, id) => dispatch(actions.setAlert(message, type, id)),
  };
};

export default withDefaultLayout(
  connect(mapStateToProps, mapDispatchToProps)(ForexInvoicePage),
  config
);
