import { LoaderProcessing } from "components/shared";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { confirmPremiumPaymentStatus } from "store/actions";
import { getBanks, resetError } from "store/actions/userAccount";

function useSearchParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function VerifyPremiumPayment() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const trxref = searchParams.get("trxref");
  const reference = searchParams.get("reference");
  const { paymentDetail, verifyLoading, paymentError } = useSelector(
    (state) => state.userAccount
  );

  const verifyPayment = useCallback(async () => {
    const param = {
      paymentReference: searchParams.get("trxref"),
      subscription_id: searchParams.get("subscriptionId"),
      reference: searchParams.get("reference"),
    };
    dispatch(confirmPremiumPaymentStatus(param));
  }, []);

  useEffect(() => {
    if (!paymentDetail && reference) {
      verifyPayment();
    }
  }, [reference]);

  console.log("log");
  return verifyLoading ? (
    <div className="loader__container">
      <LoaderProcessing />
    </div>
  ) : paymentError ? (
    <div className="empty__state">
      <p>{paymentError}</p>
    </div>
  ) : (
    <div className="verify__premium__payment">
      {paymentDetail ? (
        <>
          <div className="payment__wrapper">
            <div className="image__section text-center">
              <img
                src="/assets/images/success-icon.gif"
                className="success__icon"
              />
            </div>
            <p className="status__text">Congratulations!</p>
            {paymentDetail?.ResponseCode == 200 && !paymentError ? (
              <p className="message__body">{paymentDetail?.message}</p>
            ) : (
              <p className="message__body">
                You are now subscribed to Peppa Premium plan. You have been
                charged <span>NGN0.00</span> and your next billing date is
                <span>
                  {" "}
                  {paymentDetail?.merchant_sub?.expires_at?.date.split(" ")[0]}
                </span>
                <br />
                You can unsubscribe at any time
              </p>
            )}
            <button
              className="button button--purple button__content"
              onClick={() => history.push("/dashboard/overview")}
            >
              Go to Dashboard
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default VerifyPremiumPayment;
