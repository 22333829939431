import React, { useState, useEffect } from 'react';
import { withDefaultLayout } from 'components/layouts';
import { WhiteTick, SpinnerIcon, CloseIcon } from 'components/vectors';
import { getStringHash } from 'libs/utils';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import * as actions from 'store/actions/index';
import Modal from "components/blocks/ViewInvoiceModal/index"

const config = {
    hasAlternateHeader: false,
    hasCommonHeader: true,
    showCart: false,
    commonHeaderTitle: 'Confirmation',
    links: [],
    page: 'invoice-checkout',
    isSettings: true,
    navBarTitle: 'Invoice Confirmed Confirmation',
};
const InvoicePaymentConfirmationPage = (props) => {
    const [showM, setShow] = useState(false)


    useEffect(() => {
        const params = new URLSearchParams(props.location.search)
        const trxref = params.get('txref')
        //const reference = params.get('flwref')
        const reference = trxref;
        props.confirmInvoicePayment(props.token, props.user, { trxref, reference })
    }, [])

    if (props.loading) {
        return <SpinnerIcon />
    }
    else if (props.invoices == null) {
        return (
            <div className="confirmation">
                <div className="confirmation-content">
                    <div className="confirmation-card">
                        <div className="confirmation-card-header">
                            <div className="confirmation--status">
                                <CloseIcon />
                            </div>
                            <h6 className="text--small text-font--medium">Invoice Payment Confirmation</h6>
                        </div>
                        <div className="confirmation-card-body">
                            <div className="confirmation-card-body-status">
                                <p className="text--smaller text--gray text--center">
                                    Unable to Place Your Invoice
                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="confirmation">
                <div className="confirmation-content">
                    <div className="confirmation-card">
                        <div className="confirmation-card-header">
                            <div className="confirmation--status">
                                <WhiteTick />
                            </div>
                            <h6 className="text--small text-font--medium">Invoice Payment Confirmation</h6>
                        </div>
                        <div className="confirmation-card-body">
                            <div className="confirmation-card-body-status">
                                <p className="text--smaller text--gray text--center">
                                    Your Invoice Payment was successful , click the Invoice ID to view
                                    more details
            </p>
                            </div>
                            <div className="confirmation-card-body-order__content">
                                <p className="text--xs text--orange text-font--medium">YOUR INVOICE NUMBER IS</p>
                                <div className="button button-md button--orange-outline" onClick={() => setShow(true)}>
                                    {props.invoices.paymentDetail.id}
                                </div>
                            </div>
                        </div>
                        <div className="confirmation-card-body__base">
                            <p className="text--smaller text--gray">
                                Your will receive an email shortly, Hope you enjoy the items you
                                have chosen.
          </p>
                            <div className="confirmation-card-body__base-actions">
                                <div className="button button-md button--grey">Continue Shopping</div>
                                <div className="button button-md button--grey">My Invoice</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showM ?
                <Modal
                    close={setShow}
                    invoices={props.invoices}
                />
                : null}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.userInfo,
        token: state.auth.token,
        loading: state.invoices.loading,
        invoices: state.invoices.confirmInvoiceDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        confirmInvoicePayment: (token, user, extraParams) => dispatch(actions.confirmInvoicePayment(token, user, extraParams))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withDefaultLayout(InvoicePaymentConfirmationPage, config));
