import React from 'react'

function PlayStoreButton({color="white"}) {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.377152 1.44173C0.138255 1.6899 0 2.07628 0 2.57666V20.4248C0 20.9252 0.138255 21.3116 0.377152 21.5598L0.436114 21.6143L10.5145 11.6168V11.3807L0.436114 1.38322L0.377152 1.44173Z" fill={color}/>
<path d="M15.0901 14.9502L11.7344 11.616V11.38L15.0942 8.04578L15.1694 8.08916L19.1483 10.3358C20.2839 10.9734 20.2839 12.0226 19.1483 12.6642L15.1694 14.9068L15.0901 14.9502Z" fill={color}/>
<path d="M14.5592 15.5279L11.1242 12.1191L0.986816 22.1801C1.36397 22.5736 1.979 22.621 2.67841 22.2276L14.5592 15.5279Z" fill={color}/>
<path d="M14.5592 7.46812L2.67841 0.768499C1.979 0.379091 1.36397 0.426505 0.986816 0.819948L11.1242 10.877L14.5592 7.46812Z" fill={color}/>
</svg>



    
  )
}

export default PlayStoreButton