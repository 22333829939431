import React from "react";
import ModalComponent from "../ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductDiscount } from "store/actions/products";
import { Loader } from "components/blocks";

const DeleteDiscount = ({
  discount,
  setDeleteModal,
  deleteModal,
  isMultiple,
}) => {
  const { deletingDiscount } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const deleteProduct = () => {
    dispatch(
      deleteProductDiscount({
        discountIDs: isMultiple
          ? discount?.map((d) => Number(d))
          : [discount?.id],
      })
    );
  };
  return (
    <ModalComponent show={deleteModal} centered size="md">
      <div className="modal__component">
        <div className="modal__title">Delete Discount?</div>
        <div className="modal__content">
          <p>
            Are you sure you want to delete these discount:{" "}
            <span className="fw-bold">{discount?.discount_name}</span>?{" "}
          </p>
          <div className="modal__control">
            <button
              className="button button--purple-outline px-4"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="button button--purple px-4"
              onClick={deleteProduct}
              disabled={deletingDiscount}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DeleteDiscount;
