import SideBar from 'components/dashboardComponent/Sidebar'
import TopNav from 'components/dashboardComponent/TopNav'
import React,{ useEffect,useState } from 'react';


function DashboardLayout({children}) {
  const [open, setOpen] = useState(true);
  const [screenSize,setScreenSize] = useState(undefined);


  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setOpen(false);
    }else{
      setOpen(true);
    }
  }, [screenSize]);

  return (
    <div className='dashboard__layout'>
        {
          open?(
            <SideBar screenSize={screenSize}setOpen={setOpen} open={open} close={()=>setOpen(prev=>!prev)} />
          ):(null)
        }
        <main className={`${open? 'main__content':'main__content main__content__fixed'}`}>
            <TopNav screenSize={screenSize} close={()=>setOpen(prev=>!prev)} />
            <section className='content'>
            {
                children
            }
            </section>
        </main>
    </div>
  )
}

export default DashboardLayout