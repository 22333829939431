import React from "react";
// import { FooterList } from "components/blocks";
// import { withWelcomeLayout } from "components/layouts";
// import { getStringHash } from "libs/utils";
// import { footerLinks } from "config/inner-routes";
import { useHistory } from "react-router-dom";
import "../../index.css";

import Footer from "components/newDashboardComponents/Footer/Footer";
import NavBar from "components/landingPageComponent/NavBar";
import CallToAction from "components/landingPageComponent/CallToAction";

const config = {
  hasAlternateHeader: false,
  hasCommonHeader: true,
  showCart: false,
  commonHeaderTitle: "peppa.io Privacy Policy",
  links: [],
  page: "welcome",
  isSettings: true,
  navBarTitle: "Welcome",
};
const PrivacyPage = () => {
  const history = useHistory();

  return (
    <div className="bg-white landing__page">
      <div style={{ position: "absolute", width: "100%", zIndex: 1, top: "0", left: "0" }}>
        <NavBar />
      </div>
      <div className="onboarding_page container px-3 ">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="onboarding-card">
              {/* <div className="onboarding-header" style={{ marginTop: "15px" }}>
                <h3>Privacy Policy</h3>
              </div> */}

              <div
                className="onboarding-header pageTitle text-center"
                style={{ margin: "4rem 0 2rem 0" }}
              >
                <h3 className="font-weight-bold">Privacy Policy</h3>
                <p className="text-center">Effective 1st January, 2022</p>
              </div>
              <div className="main-content">
                <div className="paragraph-item">
                  {/* <p>
                    www.peppa.io.com is provided by peppa.io Ltd. (peppa.io)
                    <br />
                    <br />
                  </p> */}
                  <p className="paragraph">
                  peppa.io values your privacy and appreciates your trust in us.
                   This Privacy Policy explains how we use, share and protect the personally
                    identifiable information (PII) that we collect about you and the choices you have.

                  </p>
                  <p className="paragraph">“Personally Identifiable Information” means any information (i) that identifies or can be used to identify, contact, or locate the person to whom such information pertains, or (ii) from which identification
                     or contact information of an individual person can be derived.</p>

                     <p className="paragraph">This Privacy Policy applies to how we collect, use and disclose your information across peppa.io Platform. If you see an undefined term in this Privacy Policy, 
                      it has the same definition as outlined in our Terms and Conditions.</p>
                      <p className="paragraph">By visiting our website, you agree to this Privacy Policy.</p>
                  {/* “Personally Identifiable Information” means any information (i) (ii) */}
                  
                  

                  <ul>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                      1. Information We Collect
                    </h2>
                    <p className="paragraph">
                      There are three categories of information that we collect. We collect a
                      variety of information from our customers and visitors to the website. As
                      described below some information is automatically collected when you visit our
                      website, and some you provide to us when registering or filling out a form,
                      creating a listing or booking an accommodation or communicating with us.
                    </p>
                    <br />

                    <p className="sub-text paragraph">
                    Information Collected Automatically: Whenever you visit our website, our web servers automatically collect non-personal information such     
as the domain name of the internet access provider, the internet protocol address used to connect the computer to the internet, the average   
time spent on our Website, pages viewed, information searched for, access times, and other relevant statistics.

                    </p>
                    <br />
                    <p className="sub-text text-left paragraph">
                    Information you Provide Us: If you provide us with personal information by, registering for an account or contacting us, we collect that 
personal information. For example, if you register on our website, we collect your name, e-mail address and password. If you initiate or 
commence a transaction, we collect the personal information you provide us such as your billing and payment information. If you contact our 
customer services agents, you may also provide us with personal information that we collect.

                      <br />{" "}
                    </p>

                    <li className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                      2. Using Your Personal Information
                    </li>
                    <p className="paragraph">
                    Personal Data about our customers is an important part of our business and we shall only use your personal data for the following purposes and shall not keep such personal data longer than is necessary to fulfill these purposes:
                    </p>
                    <br />
                    <p className="paragraph">
                      <ul>
                        <il>• To help us verify your identity</il>
                        <li>
                          • To help us process the Transaction, verify receipt of payment and
                          confirm shipping information
                        </li>
                        <li>
                          • To help us identify accounts and services which you could have from us
                          from time to time.
                        </li>
                        <li>
                          • Enable us operate, protect, improve, and optimize peppa.io by carrying
                          out marketing analysis and customer profiling (including transactional
                          information), conduct research, including creating statistical and testing
                          information.
                        </li>
                        <li>
                          • Personalize or otherwise customize your experience by, among other
                          things, ranking search results or showing ads based on your search,
                          booking history, and preferences.
                        </li>
                        <li>• Provide customer service</li>
                        <li>
                          • Send you service or support messages, such as updates, security alerts,
                          and account notifications.
                        </li>
                        <li>
                          • Verify or authenticate information or identifications provided by you
                          (such as to verify your address or compare your identification photo to
                          another photo you provide).
                        </li>
                        <li>
                          • To help us to administer and to contact you about improved
                          administration of any accounts, listings and booking services we have
                          provided in the past.
                        </li>
                        <li>• To help prevent and detect fraud or loss.</li>
                        <li>• Conduct investigations and risk assessments</li>
                        <li>• Comply with our legal obligations</li>
                        <li>
                          • To allow us to contact you in any way(including e - mail, telephone,
                          visit, and text or multimedia messages) about services offered by us
                          unless you have previously asked us not to do so.
                        </li>
                      </ul>
                    </p>
                    <br />
                    <p className="paragraph">
                      We may monitor and record communications with you (including phone
                      conversations and emails) for quality assurance and compliance. We will not
                      disclose your personal data to any third party except in accordance with this
                      privacy policy.
                    </p>
                    <br />
                  </ul>
                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">3. Disclosures</li>
                  <p className="paragraph">
                    {" "}
                    3.1. We will not sell, publish, disclose, or rent to third parties your
                    personally identifiable information collected at our web site, through our
                    servers or otherwise obtained by us, other than to provide our services and as
                    set forth in this privacy policy.
                  </p>
                  <br />
                  <p className="paragraph">
                    3.2. The information you provide during any transaction facilitated by peppa.io
                    such as your billing and pay-out information shall be considered confidential
                    information and, as between the parties to a transaction and peppa.io. We will
                    not disclose this information to outside parties without the written consent of
                    all the parties to any transaction.{" "}
                  </p>
                  <br />
                  <p className="paragraph">
                    3.3. We may share your information with other companies such as your banking
                    institution and with the other party to your transaction. We may also request
                    information about you from third parties to provide our services.{" "}
                  </p>
                  <br />

                  <p className="paragraph">
                    3.4. If you submit content in a public forum or social media post, or use a
                    similar feature on www.peppa.io.com , that content is publicly visible.{" "}
                  </p>
                  <br />
                  <p className="paragraph">
                    3.5. Required by Law, We may disclose Personally Identifiable Information if
                    required to do so by law or in the good faith belief that such action is
                    necessary to (a) conform with the requirements of the law or comply with legal
                    process served on us, or (b) act in urgent circumstances to protect the personal
                    safety of users of our service or members of the public.{" "}
                  </p>
                  <br />
                  <p className="paragraph">
                    To the extent practicable and legally permitted, we will attempt to advise you
                    prior to any such disclosure, so that you may seek a protective order or other
                    relief limiting such disclosure.
                  </p>
                  <br />

                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">
                    4. Communications from Our Site
                  </li>
                  <p className="paragraph">
                  Pursuant to you registering on our site, you will receive emails that confirm specific actions you requested. You will receive notifications confirming your registration, the successful verification of email addresses and bank accounts, if required, and confirmation of successful transactions. You will also receive progress updates on the status of your transactions. These are transactional notifications that you cannot opt-out of receiving, as they are in place to protect the security of your account and your personal information. We may also send you responses to emails you send us, if appropriate or applicable. From time to time, we will also send user surveys, requests for user feedback regarding user experience and site operations or marketing offers from us or from us on behalf of our marketing partners. The completion of these surveys or requests for feedback or acceptance of any offer is strictly voluntary. If you do not wish to receive these offers, surveys or user feedback emails, please opt out in any offer email you receive from us.

                  </p>
                  <br />
                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">5. Age Restriction</li>
                  <p className="paragraph">
                  You affirm that you are over the age of majority and have the right to contract in your own name, and that you have 
                  read the above authorization and fully understand its contents.
                  </p>
                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">6. Cookies</li>
                  <p className="paragraph">
                  We use cookies to identify you as a User and make your user 
                  experience easier, customise our services, content and advertising;
                   help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember your account log-in information when you visit our website, IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities. If your browser or browser add-on permits, you have the choice to disable cookies on our website; however, this may limit your ability to use our website.

                  </p>

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>7. Website Security</li>
                  <p className="paragraph">
                    We are committed to ensuring that your information is secure. In order to
                    prevent unauthorised access or disclosure, we have put in place suitable
                    physical, electronic and managerial procedures such as secure sockets layer
                    (SSL) to safeguard and secure the information we collect online.{" "}
                  </p>
                  <br />
                  <p className="paragraph">
                  In addition to our own substantial efforts, you can take several 
                  precautions to protect the security of your computer and personal 
                  information. For instance, when you are creating an account, 
                  endeavour to use a well-chosen password. You should avoid using
                   any information that others can easily learn about you, such as a 
                   family member's name or birthday, and you can also use special characters in place of letters. You may change the password as often as you wish by going to “My Profile” and selecting "Change Password." We also recommend that you change your password frequently. You can also install and regularly update antivirus and firewall software to protect your computer from external attacks by malicious users. When you are finished with a session on our site, be sure that you log out and close the browser window.

                  </p>
                  <br />
                  <p className="paragraph">
                    peppa.io authorizes only those persons who need to know your personal
                    information to administer your account, to provide or inform you about products
                    and services, or to maintain, improve or administer our website to access your
                    personal information.
                  </p>
                  <br />
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    8. The Data that we Retain
                  </li>
                  <p className="paragraph">
                  We will retain your information for as long as your account is active or as needed to provide you with our services, comply with our legal and statutory obligations or verify your information with a financial institution.

                    </p>
                  <p className="paragraph">
                    peppa.io is statutory obligated to retain the data you provide us with in order
                    to process transactions, ensure settlements, make refunds, identify fraud and in
                    compliance with laws and regulatory guidelines applicable to us, our banking
                    providers and credit card processors.
                  </p>
                  <p className="paragraph">
                    Therefore, even after closing your peppa.io account, we will retain certain
                    data to comply with these obligations.
                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    9. Your Choices on Your Data
                  </li>
                  <p className="paragraph">
                    Generally. You can exercise rights over your Personal Data against the
                    Controller. We provide reasonable steps to allow you to access, rectify, erase,
                    port, or restrict the use of your Personal Data. You have the right to object to
                    the use of your Personal Data at any time, subject to applicable law.
                  </p>
                  <p className="paragraph">
                    When collection is based on your consent, you have the right to withdraw consent
                    at any time, without affecting the lawfulness of processing based on consent
                    before its withdrawal by applicable law. If applicable by national law, you have
                    the right to lodge a complaint with a supervisory authority if you believe that
                    the processing of your personal data relating infringes your rights.
                  </p>
                  <p className="paragraph">
                  To exercise your choice on your data, please e-mail us at <u>notifications@pepperest.com</u>
                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>10. Problem Signing In</li>
                  <p className="paragraph">
                  Our sign-in process is designed to help protect your privacy. 
                  If you have trouble signing in to our Website, please ensure that 
                  you are using your registered e-mail address and/or correct password.
                   If you are using your registered e-mail address and correct password,
                    and you continue to have trouble signing into our site, 
                    please e-mail us immediately at <u>notifications@pepperest.com</u>

                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    11. Transaction Confirmation
                  </li>
                  <p className="paragraph">
                  peppa.io sends an e-mail notice confirming acceptance of any 
                  transaction you initiate or commence to the e-mail address you
                   provided to us when signing up for a peppa.io account. 
                   If you receive a confirmation for a transaction you did not 
                   initiate, please e-mail us immediately at <u>notifications@pepperest.com</u>

                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    12. Changes to Your Account
                  </li>
                  <p className="paragraph">
                  peppa.io gives you the ability to change or edit your personal 
                  information contained in your profile. Additionally, peppa.io 
                  gives you the opportunity to close your account if you choose.

                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    13. Links to Other Web Sites
                  </li>
                  <p className="paragraph">
                    {" "}
                    13.1. Our Service may contain links to third-party web sites or services that
                    are not owned or controlled by peppa.io.
                  </p>
                  <br />
                  <p className="paragraph">
                    13.2. peppa.io Ltd has no control over, and assumes no responsibility for, the
                    content, privacy policies, or practices of any third-party web sites or
                    services. You further acknowledge and agree that peppa.io shall not be
                    responsible or liable, directly, or indirectly, for any damage or loss caused or
                    alleged to be caused by or in connection with use of or reliance on any such
                    content, goods or services available on or through any such web sites or
                    services.
                  </p>
                  <br />
                  <p className="paragraph">
                    13.3. We strongly advise you to read the terms and conditions and privacy
                    policies of any third-party web sites or services that you visit.
                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    14. Changes to this Privacy Policy
                  </li>
                  <p className="paragraph">
                    peppa.io may revise this Privacy Policy from time to time by posting a revised
                    Privacy Policy on our Website. We reserve the right to modify this Privacy
                    Policy at any time, so please review it frequently.
                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>15. Further Information</li>
                  <p className="mb-5 paragraph">
                  If you would like any more information or have any comments about 
                  our Privacy Policy, please contact us at +234 802 222 0071 or e-mail
                   us at <u>notifications@pepperest.com</u>

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction/>
      <Footer />
    </div>
  );
};

export default PrivacyPage;
// export default withWelcomeLayout(PrivacyPage, config);
