import InputField from "components/blocks/InputField";
import React from "react";
import { Country, State, City } from "country-state-city";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePickUpAddress } from "store/actions";
import { useEffect } from "react";
import PlacesAutoComplete from "components/shared/PlacesAutoComplete";

const PickAddress = () => {
  const [state, setState] = useState("");
  const [selectedPlace, setSelectedPlace] = useState("");
  const [city, setCity] = useState("");
  const { token, userInfo } = useSelector((state) => state.auth);
  const { pickUpAddress, updatingPickupAddress } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();

  const availableStates = State.getStatesOfCountry("NG");
  const availableCities = City.getCitiesOfState("NG", state);
  const states = availableStates.map((state) => {
    const { name, isoCode } = state;
    return { label: name, value: isoCode };
  });

  const cities = availableCities.map((city) => {
    const { name } = city;
    return { label: name, value: name };
  });

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
  };

  const pickupAddressSchema = yup.object().shape({
    street: yup.string().required("Enter your street name"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country fis required"),
    zip_code: yup.string().nullable(),
  });
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(pickupAddressSchema),
  });

  const submit = (data) => {
    data.state = State.getStateByCode(state)?.name;
    data.street = selectedPlace;
    dispatch(updatePickUpAddress(token, userInfo, data));
  };
  useEffect(() => {
    if (pickUpAddress) {
      let state = availableStates.find((item) =>
        item.name.includes(pickUpAddress?.state)
      );
      setState(state?.isoCode);
      reset({
        state: state?.isoCode,
        city: pickUpAddress?.city,
        street: pickUpAddress?.street,
        country: pickUpAddress?.country,
        zip_code: pickUpAddress?.zip,
      });
    }
  }, []);

  return (
    <div className="profile__info">
      <p className="title mb-3">Pickup Address</p>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form__container">
          <div className="form__wrapper">
            <PlacesAutoComplete onPlaceSelect={handlePlaceSelect}>
              <InputField
                id="street"
                label="Street"
                name="street"
                placeHolder="Street"
                register={register}
                error={errors.street?.message}
                type="text"
              />
            </PlacesAutoComplete>
          </div>
          <div className="form__wrapper">
            <InputField
              id="state"
              label="State"
              name="state"
              options={states}
              register={register}
              onChange={(e) => setState(e.target.value)}
              error={errors.state?.message}
              type="select"
            />
          </div>
          <div className="form__wrapper">
            <InputField
              id="city"
              label="City"
              name="city"
              options={cities}
              register={register}
              onChange={(e) => setCity(e.target.value)}
              error={errors.city?.message}
              type="select"
            />
          </div>
          <div className="form__wrapper">
            <InputField
              id="country"
              label="Country"
              name="country"
              options={country}
              register={register}
              error={errors.country?.message}
              type="select"
            />
          </div>
          <div className="form__wrapper">
            <InputField
              id="zipcode"
              label="Zip Code"
              name="zip_code"
              placeHolder="Zip Code"
              register={register}
              error={errors.zip_code?.message}
              type="text"
            />
          </div>
          <div className="button__wrapper">
            <button
              className="button button--purple button__content"
              disabled={updatingPickupAddress}
            >
              Update changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PickAddress;
const country = [{ label: "Nigeria", value: "Nigeria" }];
