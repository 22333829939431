import React from "react";

const LocationIcon = ({width=16,height=16}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={height}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 0C4.66563 0 2 2.66563 2 6C2 7.15312 2.34375 8.04375 2.94062 8.94687L7.36875 15.6656C7.50313 15.8687 7.73438 16 8 16C8.26562 16 8.5 15.8656 8.63125 15.6656L13.0594 8.94687C13.6562 8.04375 14 7.15312 14 6C14 2.66563 11.3344 0 8 0ZM8 9.33125C6.15938 9.33125 4.66563 7.8375 4.66563 5.99375C4.66563 4.15 6.15938 2.65625 8 2.65625C9.84062 2.65625 11.3344 4.15 11.3344 5.99375C11.3344 7.8375 9.84062 9.33125 8 9.33125ZM8 4C6.89375 4 6 4.89375 6 6C6 7.10625 6.89375 8 8 8C9.10625 8 10 7.10625 10 6C10 4.89375 9.10625 4 8 4Z"
      fill="#FE8F01"
    />
  </svg>
);

export default LocationIcon;
