import React from 'react'

const copyIconStyled = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.917 11.2083H11.1087C9.62533 11.2083 8.41699 10.0083 8.41699 8.51659V6.70825C8.41699 6.36659 8.14199 6.08325 7.79199 6.08325H5.15033C3.22533 6.08325 1.66699 7.33325 1.66699 9.56659V15.0999C1.66699 17.3333 3.22533 18.5833 5.15033 18.5833H10.0587C11.9837 18.5833 13.542 17.3333 13.542 15.0999V11.8333C13.542 11.4833 13.2587 11.2083 12.917 11.2083Z" fill="#5E60CE"/>
<path d="M14.8501 1.91675H13.2085H12.3001H9.9418C8.05846 1.91675 6.53346 3.11675 6.4668 5.25841C6.5168 5.25841 6.55846 5.25008 6.60846 5.25008H8.9668H9.87513H11.5168C13.4418 5.25008 15.0001 6.50008 15.0001 8.73342V10.3751V12.6334V14.2751C15.0001 14.3251 14.9918 14.3667 14.9918 14.4084C16.8501 14.3501 18.3335 13.1167 18.3335 10.9417V9.30008V7.04175V5.40008C18.3335 3.16675 16.7751 1.91675 14.8501 1.91675Z" fill="#5E60CE"/>
<path d="M9.98418 6.20813C9.72585 5.94979 9.28418 6.12479 9.28418 6.48313V8.66646C9.28418 9.58313 10.0592 10.3331 11.0092 10.3331C11.6008 10.3415 12.4258 10.3415 13.1342 10.3415C13.4925 10.3415 13.6758 9.92479 13.4258 9.67479C12.5175 8.76646 10.9008 7.14146 9.98418 6.20813Z" fill="#5E60CE"/>
</svg>

)

export default copyIconStyled