import React, { useEffect } from "react";
import { ReportIssueModal } from "components/blocks";
import { PAGE_TYPES as OrdersPageTypes } from "libs/constants/PepperestWebServices/Orders";
import { PepperestContext } from "components/helpers/constant";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import { toast } from "react-toastify";
import { SocialIcon } from "react-social-icons";
import { mixPanelUserProperty } from "libs/mixPanel";
import { PepperestWebServices } from "libs/constants";

const referalText = [
  {
    text: "Share your Pepperest referral link with 10 friends who sell things on Facebook/Instagram/WhatsApp or any other place to get a N5000 instant bonus.",
  },
  {
    text: "Have your friends register for the FREE Pepperest Social Store using your referral link and start selling.",
  },
  {
    text: "Redeem your referral bonus instantly by selling to 10 different customers (sales made by friends you refer also count)",
  },
];
const socialIConsStyle = { width: "30px", height: "30px", marginRight: "8px" };
const OrderReferal = (props) => {
  const { loaded } = props;
  useEffect(() => {
    if (!props.loaded && !props.loading) {
      getOrders();
    }
  }, [loaded]);

  const getOrders = () => {
    props.getReferralStats(props.token, props.user);
  };

  const copyReferralCode = (referal_code) => {
    let textArea = document.createElement("textarea");
    document.body.append(textArea);
    // Copied link for testing
    // textArea.innerText = `localhost:3000/register?referal_code=${referal_code}`;
    // Live link to be copied
    textArea.innerText = `${PepperestWebServices.HOST}/register?referal_code=${referal_code}`;
    textArea.select();
    document.execCommand("copy");
    //document.body.removeChild(textArea);
    toast.success(`Your Referal code  has been Copied`);
    mixPanelUserProperty("Referrallink_copied", {});
  };

  return (
    <>
      <div className="page-container">
        <div className="pcard">
          <div className="pcard-header">
            <h3
              className="pcard-header__title"
              style={{ color: "#10182a", fontSize: "1.2rem", fontWeight: "500" }}
            >
              Referal Statistic
            </h3>
          </div>
          <div className="pcard-body pb-3">
            <div className="mx-auto col-md-8">
              <div className="header--nav justify-content-center">
                <div className={`header--nav-dot }`}></div>
                <div className={`header--nav-progress`} />
                <div className={`header--nav-dot `}></div>
                <div className={`header--nav-progress`} />
                <div className={`header--nav-dot `}></div>
              </div>
              <ul className="header--nav__menu justify-content-center">
                <li className="header--nav__menu-item ">Send an Invitaion</li>
                <li className="header--nav__menu-item ">Register</li>
                <li className="header--nav__menu-item ">Access Reward</li>
              </ul>
            </div>
            <div style={{}} className="mx-auto d-flex-col d-md-flex col-md-11">
              <div className="col-12 col-md-4">
                <div style={{}} className="mx-auto col-10">
                  <h6 className="pcard-header__title">Referal Points</h6>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <p>Buyers</p>
                    <p>{props.referralStats.buyers_referred || 0}</p>
                  </div>
                  <div className="d-flex mt-2 justify-content-between align-items-center">
                    <p>Sellers</p>
                    <p>{props.referralStats.sellers_referred || 0}</p>
                  </div>
                  <div className="d-flex mt-2 justify-content-between align-items-center">
                    <h6 className="pcard-header__title ">Total Points Earned</h6>
                    <p>{props.referralStats.tranx_count || 0}</p>
                  </div>
                  <div
                    className="button button-md mt-3 mx-auto button--orange"
                    style={{ width: "70%" }}
                    // onClick={() => {
                    //   context.updateShowProductModal(true);
                    // }}
                  >
                    Claim Point
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                {referalText.map((text, i) => (
                  <ReferalList key={i} value={text.text} />
                ))}
                <h6 className="mt-3 pcard-header__title">Share Referal Link</h6>
                <div className="text-black-50">
                  Share your referal Link on your favorite Social Platforms
                  <div>
                    <SocialIcon style={socialIConsStyle} url="https://twitter.com" />
                    <SocialIcon style={socialIConsStyle} url="https://facebook.com" />
                    <SocialIcon style={socialIConsStyle} url="https://instagram.com" />
                    <SocialIcon style={socialIConsStyle} url="https://whatsapp.com" />
                    <SocialIcon style={socialIConsStyle} url="https://gmail.com" />
                  </div>
                  if you want to just copy the link click on the button below
                </div>
                <div
                  className="button button-md button--orange my-1"
                  style={{ width: "6rem" }}
                  onClick={() => {
                    copyReferralCode(props.referral_code);
                  }}
                >
                  Copy Link
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PepperestContext.Consumer>
        {(context) =>
          context.state.showReportIssueModal ? <ReportIssueModal context={context} /> : null
        }
      </PepperestContext.Consumer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.userInfo,
    referralStats: state.orders.referralStats,
    orders: state.orders[OrdersPageTypes.CANCELED].orders,
    meta: state.orders[OrdersPageTypes.CANCELED].meta,
    links: state.orders[OrdersPageTypes.CANCELED].links,
    loading: state.orders[OrdersPageTypes.CANCELED].loading,
    loaded: state.orders[OrdersPageTypes.CANCELED].loaded,
    referral_code: state.auth.userInfo.referral_code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadOrders: (token, user, pageType, extraParams) =>
      dispatch(actions.loadOrders(token, user, pageType, extraParams)),
    getReferralStats: (token, user) => dispatch(actions.getReferralStats(token, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReferal);

const ReferalList = ({ value }) => (
  <li className="auth-register__info-section-feature__list-item">
    <div style={{ width: "auto" }}>
      <svg
        style={{ marginRight: "10px" }}
        className="auth-register__info-section-feature__list-item__icon"
        xmlns="http://www.w3.org/2000/svg"
        // width='30'
        // height='30'
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" r="12" fill="coral" />
          <path
            fill="white"
            fillRule="nonzero"
            d="M11.621 14.692a.93.93 0 0 1-1.313 0l-2.036-2.035a.929.929 0 1 1 1.314-1.314l1.213 1.213c.091.091.24.091.331 0l3.284-3.284a.93.93 0 0 1 1.314 1.314l-4.107 4.106z"
          />
        </g>
      </svg>
    </div>
    <div style={{ width: "90%" }}>
      <p className="text-black-50">{value}</p>
    </div>
  </li>
);
