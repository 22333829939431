import React from "react";

function index() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.9768 14.1914H12.5235C11.9168 14.1914 11.3535 14.2131 10.8335 14.2347C5.6985 14.5381 4.3335 16.4231 4.3335 22.2731V23.5297C4.3335 24.7214 5.3085 25.6964 6.50016 25.6964H39.0002C40.1918 25.6964 41.1668 24.7214 41.1668 23.5297V22.2731C41.1668 15.8164 39.5202 14.1914 32.9768 14.1914Z"
        fill="#FE8F01"
      />
      <path
        d="M6.50016 28.9463C5.3085 28.9463 4.3335 29.9213 4.3335 31.113V37.418C4.3335 43.8746 5.98016 45.4996 12.5235 45.4996H32.9768C39.4118 45.4996 41.1018 43.9396 41.1668 37.7646V31.113C41.1668 29.9213 40.1918 28.9463 39.0002 28.9463H6.50016ZM15.0802 40.213H11.3752C10.4868 40.213 9.75016 39.4763 9.75016 38.588C9.75016 37.6996 10.4868 36.963 11.3752 36.963H15.1018C15.9902 36.963 16.7268 37.6996 16.7268 38.588C16.7268 39.4763 15.9902 40.213 15.0802 40.213ZM27.1918 40.213H19.7385C18.8502 40.213 18.1135 39.4763 18.1135 38.588C18.1135 37.6996 18.8502 36.963 19.7385 36.963H27.1918C28.0802 36.963 28.8168 37.6996 28.8168 38.588C28.8168 39.4763 28.1018 40.213 27.1918 40.213Z"
        fill="#FE8F01"
      />
      <path
        d="M47.667 28.882V17.5286C47.667 10.747 43.7887 7.80029 37.9387 7.80029H18.5903C16.9436 7.80029 15.4703 8.03863 14.1703 8.53696C13.152 8.90529 12.242 9.44696 11.5053 10.162C11.1153 10.5303 11.4186 11.137 11.982 11.137H35.5336C40.4086 11.137 44.352 15.0803 44.352 19.9553V35.4903C44.352 36.032 44.937 36.3353 45.327 35.9453C46.822 34.3636 47.667 32.0453 47.667 28.882Z"
        fill="#FE8F01"
      />
    </svg>
  );
}

export default index;
