import React, { useEffect, useState } from "react";
import SubMenu from "./SubMenu";
import ArrowDown from "components/vectors/ArrowDown";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowUp } from "components/vectors";

function ParentMenuItem({ item, handleCloseSideBar }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const activeItem = location.pathname.split("/");

  const handleClick = () => {
    if (item.hasSubMenu) {
      setIsOpen((prev) => !prev);
    } else {
      handleCloseSideBar();
    }
  };

  return (
    <li className="menu__item">
      {item.hasSubMenu ? (
        <>
          <p
            onClick={handleClick}
            className={`menu_link cursor__pointer ${
              activeItem[2] == item.title.toLowerCase() ? "active" : ""
            }`}
          >
            {activeItem[2] === item.slug ? item.icon.solid : item.icon.light}

            {item.title}

            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </p>
          {isOpen && (
            <SubMenu
              subMenuItems={item.subMenuItems}
              isActive={isOpen}
              handleCloseSideBar={handleCloseSideBar}
            />
          )}
        </>
      ) : (
        <NavLink
          onClick={handleClick}
          to={item.path}
          className={({ isActive }) =>
            isActive ? "menu_link menu__link active__menu" : "menu_link"
          }
        >
          {activeItem[2] === item.slug ? item.icon.solid : item.icon.light}
          {item.title}
        </NavLink>
      )}
    </li>
  );
}

export default ParentMenuItem;
