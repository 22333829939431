import {
  AtmCardIcon,
  CircularDollarIcon,
  ShieldDollarIcon,
  ShopIcon,
  TruckIcon,
} from "components/vectors";
import React from "react";
import { useHistory } from "react-router-dom";

// Title to show documentations for buyers and
const FOR_BUYER = "For Buyers and Sellers";

export const ProductMainSection = ({
  reverse,
  title,
  btnText = "Get Started",
  imgLink = "/assets/images/Creative.png",
  mainText = "peppa.io provides merchants with a variety of tools to display their products on the internet with an online store. Your customers can directly order products and make payments in a beautiful online store set up for you by peppa.io",
  id = "id",
  routeTo = "/",
  target = true,
}) => {
  const { push } = useHistory();
  return (
    <section id={id} className="border-bottom py-5">
      <div
        className={`products__main flex-wrap row gx-5 d-flex align-items-center ${
          reverse ? "flex-row-reverse" : ""
        } container`}
      >
        <div className="h-100 col-12 col-md">
          <img className="w-100 h-100" src={imgLink} alt="book description" />
        </div>
        <div className="h-100 mt-5 mt-md-0 col-12 col-md align-content-center d-flex flex-wrap">
          <h3 className="products__main__title mb-2">{title}</h3>
          <p className="products__main__text my-3 ">{mainText}</p>
          <div
            className="d-flex align-items-baseline w-100 justify-content-start"
            style={{ columnGap: "2rem" }}
          >
            {target ? (
              
                  <>
                  {btnText}
                  </>
               
            ) : (
              <button
                onClick={() => push(routeTo)}
                className="button--lighOrange button font-weight-normal mt-3 register-button products__main__button"
              >
                {btnText}
              </button>
            )}

            {title === FOR_BUYER && (
              <a
                style={{ textDecoration: "none", color: "#FE8F01" }}
                className=" d-block"
                target="_blank"
                rel="noreferrer noopener"
                href="https://documenter.getpostman.com/view/1642746/UzJMraro"
              >
                EASE API Documentation
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const productBoxContents = [
  { text: "Online Storefront", icon: <ShopIcon />, link: "storefront" },
  { text: "Escrow Service", icon: <ShieldDollarIcon />, link: "escrow" },
  { text: "Payment Processing", icon: <CircularDollarIcon />, link: "payment" },
  { text: "Logistics & Delivery", icon: <TruckIcon />, link: "logistic" },
  { text: "Multiple Payment Options", icon: <AtmCardIcon />, link: "options" },
];

export const ProductBox = ({ text, icon, link }) => {
  return (
    <a
      href={`#${link}`}
      className="text-decoration-none products__box__content mx-auto col-sm h100 d-flex justify-content-center align-items-center"
    >
      <span className="mr-2 ">{icon}</span>
      {text}
    </a>
  );
};
