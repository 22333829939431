import React, { useEffect, useState } from "react";
import Dropdown from "components/dashboardComponent/Dropdown";
import { SearchBar } from "components/dashboardComponent/SearchBar";
import DashboardLayout from "components/layouts/dashboardLayout";
import { ThreeDot, TrashIcon } from "components/vectors";
import StockIcon from "components/vectors/StockIcon";
import Pagination from "components/dashboardComponent/Pagination";
import ModalComponent from "components/dashboardComponent/ModalComponent";
import { loadProduct, removeProduct, selectPage } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormatter, handleCopy } from "libs/utils";
import { Loader, LoadingListItem } from "components/blocks";
import { useRef } from "react";
import DropdownAction from "components/dashboardComponent/DropdownAction";
import { useHistory, useLocation } from "react-router-dom";
import * as actions from "store/actions/index";
import {
  publishSocialProductComplete,
  publishSocialProducts,
} from "store/actions/products";

const SocialProducts = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [query, setQuery] = useState("");
  const source = localStorage.getItem("source");
  const history = useHistory();
  const { token, userInfo } = useSelector((state) => state.auth);
  const {
    items,
    loadingFacebookProducts,
    publishingProduct,
    publishProductSuccess,
  } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const { state } = location;
  console.log({ state });

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(products.map((product) => product.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, Number(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item != id));
    }
  };

  let timeout = useRef();
  const handleSearch = (event) => {
    event.persist();
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setQuery(event.target.value);
    }, 2000);
  };

  const getSocialProducts = () => {
    const { selectedPage } = state?.state;
    const params = {
      page_id: selectedPage?.id,
      page_access_token: selectedPage?.access_token,
    };
    dispatch(actions.getPageData(token, userInfo, params));
  };

  useEffect(() => {
    if (!items && state != undefined) {
      getSocialProducts();
    }
  }, [selectPage?.id]);

  const publish = () => {
    const selectedProducts = items.filter((item) => isCheck.includes(item.id));
    const newProducts = selectedProducts.map((product) => {
      let item = {};
      item.image_url = product.url;
      item.productname = `social-product-${product.id}`;
      // item.description =`social-product-description-${product.id}`;
      // item.price = 0.00;
      item.currency = "NGN";
      item.deliveryperiod = 2;
      return item;
    });
    dispatch(publishSocialProducts({ products: newProducts }));
  };
  const finishSetup = () => {
    if (source) {
      localStorage.removeItem("source");
      history.push("/dashboard/complete-setup");
    } else {
      history.push("/dashboard/products");
    }
  };

  useEffect(() => {
    if (publishProductSuccess) {
      dispatch(publishSocialProductComplete());
      history.push("/dashboard/products?source=onboarding", { replace: true });
    }
  }, [publishProductSuccess]);

  return (
    <main className="products">
      <div className="product__button__wrapper">
        <p className="product__count">{items?.length} products found</p>
        {items.length > 0 ? (
          <button
            className="button button--purple text__md button__content"
            disabled={publishingProduct}
            onClick={publish}
          >
            Publish Product
          </button>
        ) : (
          <button
            className="button button--purple text__md button__content"
            onClick={finishSetup}
          >
            Finish Setup
          </button>
        )}
      </div>
      <div className="search__product__action__wrapper">
        <div className="search__wrapper">
          <SearchBar placeholder="Search in products" onChange={handleSearch} />
        </div>
        {/* <div className='actions__wrapper'>
                   <div className={`cursor__pointer ${isCheckAll?'':'disabled'}`} onClick={()=>setShowDelete(true)}> <TrashIcon width={16} height={17}/>  Delete</div>
                   <div className={`cursor__pointer${isCheckAll?'':'disabled'}`}> <StockIcon width={16} height={17}/>  In stock</div>
                </div> */}
      </div>

      {loadingFacebookProducts ? (
        <ul>
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
         
        </ul>
      ) : (
        <section className="table__wrapper">
          <table className="product__table">
            <thead>
              <tr>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      onClick={handleSelectAll}
                      className="form_input"
                    />
                    <span></span>
                  </label>
                </th>
                <th>Product</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {!items || items?.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    No Products found
                  </td>
                </tr>
              ) : (
                <>
                  {items?.map((product) => (
                    <tr key={product?.id}>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            key={product?.id}
                            id={product?.id}
                            checked={isCheck.find((item) => item == product?.id)}
                            onChange={handleClick}
                            className="form_input"
                          />
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <img src={product?.url} className="product__image" />
                        <span>Social-product-{product?.id}</span>
                      </td>
                      <td>NGN0.00</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {/* <Pagination currentPage={meta?.current_page} 
                totalCount={meta?.total}
                pageSize={meta?.per_page} 
                onPageChange={page => getProducts(page)}
                /> */}
        </section>
      )}

      {/* <ModalComponent show={showDelete} centered size="md">
            <div className='modal__component'>
                <div className='modal__title'>Delete Products?</div>
                <div className='modal__content'>
                    <p>Are you sure you want to delete these products? </p>
                    <div className='modal__control'>
                        <button className='button button--purple-outline px-4' onClick={()=>setShowDelete(false)}>Cancel</button>
                        <button className='button button--purple px-4'>Delete</button>
                    </div>
                </div>
            </div>
        </ModalComponent>

        <ModalComponent show={confirmDelete} centered size="md">
            <div className='modal__component'>
                <div className='modal__title'>Delete Product?</div>
                <div className='modal__content'>
                    <p>Are you sure you want to delete these product <span className='fw-bold'>{products?.find(product=>product?.id==deleteProductId)?.productName}</span>? </p>
                    <div className='modal__control'>
                        <button className='button button--purple-outline px-4' onClick={()=>setconfirmDelete(false)}>Cancel</button>
                        <button className='button button--purple px-4' onClick={deleteProduct}>{
                            updatingProduct?<Loader />:'Delete'
                        }</button>
                    </div>
                </div>
            </div>
        </ModalComponent> */}
    </main>
  );
};

export default SocialProducts;


const stockOption = ["in stock", "out of stock"];
const products = [
  {
    id: 1,
    name: "School Bag",
    price: "NGN5000.00",
  },
  {
    id: 2,
    name: "School Bag",
    price: "NGN5000.00",
  },
  {
    id: 3,
    name: "School Bag",
    price: "NGN5000.00",
  },
  {
    id: 4,
    name: "School Bag",
    price: "NGN5000.00",
  },
  {
    id: 5,
    name: "School Bag",
    price: "NGN5000.00",
  },
  {
    id: 6,
    name: "School Bag",
    price: "NGN5000.00",
  },
];
