import React from "react";

const CrownIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7002 18.98H7.3002C6.8802 18.98 6.4102 18.65 6.2702 18.25L2.1302 6.67C1.5402 5.01 2.2302 4.5 3.6502 5.52L7.5502 8.31C8.2002 8.76 8.9402 8.53 9.2202 7.8L10.9802 3.11C11.5402 1.61 12.4702 1.61 13.0302 3.11L14.7902 7.8C15.0702 8.53 15.8102 8.76 16.4502 8.31L20.1102 5.7C21.6702 4.58 22.4202 5.15 21.7802 6.96L17.7402 18.27C17.5902 18.65 17.1202 18.98 16.7002 18.98Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 22H17.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 14H14.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrownIcon;
