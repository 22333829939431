import React from "react";

const ShieldDollarIcon = ({ size = 22 }) => {
  return (
    <svg 
    width={size} 
    height={size+2} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4382 1.54414L6.60831 0.109674C6.21139 -0.0365581 5.56379 -0.0365581 5.16687 0.109674L1.33698 1.54414C0.598856 1.82268 0 2.68615 0 3.47302V9.1134C0 9.67744 0.369063 10.4225 0.821686 10.7568L4.65158 13.6188C5.32703 14.1271 6.43422 14.1271 7.10967 13.6188L10.9396 10.7568C11.3922 10.4156 11.7613 9.67744 11.7613 9.1134V3.47302C11.7682 2.68615 11.1694 1.82268 10.4382 1.54414ZM8.30739 5.44367L5.31311 8.43795C5.20866 8.5424 5.07635 8.59115 4.94404 8.59115C4.81174 8.59115 4.67943 8.5424 4.57498 8.43795L3.46083 7.30987C3.25889 7.10793 3.25889 6.77369 3.46083 6.57175C3.66277 6.36981 3.99702 6.36981 4.19896 6.57175L4.95101 7.3238L7.57623 4.69858C7.77817 4.49664 8.11241 4.49664 8.31435 4.69858C8.51629 4.90052 8.51629 5.24173 8.30739 5.44367Z" fill="#FE8F01"/>
<rect x="2.96191" y="4.30774" width="6.19231" height="5.65385" fill="#FE8F01"/>
<path d="M4.03857 8.25687C4.03857 8.95329 4.57705 9.51329 5.23756 9.51329H6.58728C7.16164 9.51329 7.62832 9.02508 7.62832 8.41482C7.62832 7.76149 7.34115 7.52457 6.91756 7.3738L4.75652 6.61995C4.33293 6.46918 4.04576 6.23944 4.04576 5.57893C4.04576 4.97585 4.51241 4.48047 5.08677 4.48047H6.43652C7.09703 4.48047 7.6355 5.04047 7.6355 5.73688" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.8335 3.76917V10.2307" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


  );
};

export default ShieldDollarIcon;
