import React from "react";
// import { Footer, FooterList } from "components/blocks";
// import { withWelcomeLayout } from "components/layouts";
// import { getStringHash } from "libs/utils";
// import { footerLinks } from "config/inner-routes";
import { useHistory } from "react-router-dom";

import "../../index.css";
import SectionBeforeFooter from "components/newDashboardComponents/SectionBeforeFooter";
import Footer from "components/newDashboardComponents/Footer/Footer";
import NavBar from "components/landingPageComponent/NavBar";
import CallToAction from "components/landingPageComponent/CallToAction";

const config = {
  hasAlternateHeader: false,
  hasCommonHeader: true,
  showCart: false,
  commonHeaderTitle: "peppa.io Terms and Conditions",
  links: [],
  page: "Terms and Conditions",
  isSettings: true,
  navBarTitle: "Terms and Conditions",
};
const TermsAndConditionPage = () => {
  const history = useHistory();
  const listStyle = {
    li: { "listStyleType:": "square" },
  };
  return (
    <div className="bg-white terms-condition landing__page">
      <div style={{ position: "absolute", width: "100%", zIndex: 1, top: "0", left: "0" }}>
        <NavBar />
      </div>
      <div className="onboarding_page  container px-3">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="onboarding-card">
              <div
                className="onboarding-header pageTitle text-center"
                style={{ margin: "4rem 0 2rem 0" }}
              >
                <h3 className="font-weight-bold">Terms and Conditions</h3>
                <p className="text-center">Effective 1st January, 2022</p>
              </div>
              <div className="main-content">
                <div className="">
                  <div className="paragraph-item">
                    <p className="paragraph">
                      Please read these Terms and Conditions (“Terms”), which set forth the legally
                      binding terms and conditions between you and peppa.io (“peppa.io or the
                      Company”). It governs your access to and the use of peppa.io website and all
                      services (the “Service”) offered by peppa.io
                      <br />
                      <br />
                    </p>

                    <p className="paragraph">
                      Our collection and use of personal information in connection with your access
                      to and use of the Service is described in our{" "}
                      <a href="/privacy" className="text-primary">
                        {" "}
                        Privacy Policy
                      </a>
                    </p>
                    <br />

                    <p className="paragraph">
                      Your access to use of the Service is conditioned on your acceptance of and
                      compliance with these Terms. These Terms apply to all visitors, users and
                      others who access or use the Service and all applicable laws and all
                      conditions or policies referenced here.
                    </p>

                    <p className="paragraph">
                      This Privacy Policy applies to how we collect, use and disclose your
                      information across the peppa.io Platform. If you see an undefined term in this
                      Privacy Policy, it has the same definition as outlined in our Terms and
                      Conditions.
                    </p>

                    <p className="paragraph">
                      By accessing or using the Service you agree to be bound by these Terms. If you
                      disagree with any part of the terms then you may not access the Service.
                    </p>
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>1. Definitions</li>
                    <p className="paragraph">
                      In these Terms and Conditions, “Buyer” means a person who makes a purchase
                      “Seller” means a person who is selling a product, goods or services Buyers,
                      Sellers and visitors to the website are referred to collectively as “Users.”
                      ‘Platform’ refers collectively to the peppa.io Website and other related
                      Applications “Site” refers to the website for the services rendered by
                      peppa.io which can be found at
                      <a href="www.peppa.io">www.peppa.io</a>
                    </p>
                    <br />

                    <p className="paragraph">
                      "Transaction" means the buying and selling of goods or services between buyers
                      and sellers “Transaction Detail Screens” means those screens on the website
                      where Users will provide all requested information relating to a transaction.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>2. Acceptance of Terms</li>
                    <p className="paragraph">
                      The Service is offered subject to acceptance of all the terms and conditions
                      contained in these Terms and all other operating rules, policies, and
                      procedures that may be published on the Site by the Company, which are
                      incorporated by reference, including operating rules, policies, and procedures
                      of third party service providers to the Site that are referenced herein. These
                      Terms apply to every user of the Service. In addition, some Services offered
                      through the Site may be subject to additional terms and conditions adopted by
                      the Company. Your use of those Services is subject to those additional terms
                      and conditions, which are incorporated into these Terms by this reference.
                    </p>
                    <br />

                    <p className="paragraph">
                      The Company reserves the right, at its sole discretion, to modify or replace
                      these Terms of from time to time by posting the updated terms on the Site. It
                      is your responsibility to check the Terms periodically for changes. If you
                      object to any such changes, your sole recourse will be to cease using the Site
                      and the Service. Your continued use of the Service following the posting of
                      any changes to the Terms will indicate your acknowledgement of such changes
                      and agreement to be bound by the terms and conditions of such changes.
                    </p>
                    <br />

                    <p className="paragraph">
                      The Company reserves the right to change, suspend, or discontinue the Service
                      (including, but not limited to, the availability of any feature, database, or
                      Content) at any time for any reason. The Company may also impose limits on
                      certain features and Services or restrict your access to parts of or the
                      entire Site without notice or liability.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      3. Scope of peppa.io’s Services
                    </li>
                    <p className="paragraph">
                      3.1 peppa.io is a Company dedicated to the facilitation of secured
                      transactions by offering escrow services for peer to peer transactions. The
                      services are online and offline based transaction management services
                      performed by www.peppa.io and escrow services provided by peppa.io ,
                      a registered limited liability company.
                    </p>
                    <br />

                    <p className="paragraph">
                      3.2 peppa.io acts as a trusted third-party that collects, holds and only
                      disburses funds when both buyers and sellers are satisfied.
                    </p>
                    <br />

                    <p className="paragraph">
                      3.3 The Buyer provides the Seller information and initiates a transaction on
                      www.peppa.io, after which peppa.io contacts the Seller to confirm the
                      Transaction. The Buyer then receives a transaction code to make payment on{" "}
                      <a href="www.peppa.io">www.peppa.io.</a> Upon confirmation of
                      delivery of goods/services to the specification(s) agreed between the Buyer
                      and the Seller, peppa.io releases payment to the Seller.
                    </p>
                    <br />

                    <p className="paragraph">
                      3.4 While we may help facilitate secured transactions through our arbitration
                      mechanism, peppa.io has no control over and does not guarantee the existence,
                      quality, safety, suitability, or legality of any product or services offered
                      by a seller to a buyer. In the instance any disagreement arise between the
                      buyer and the seller (referred to as “the Parties”), peppa.io shall not be
                      held liable for any costs, damages or losses that may arise from duties
                      performed.
                    </p>
                    <br />

                    <p className="paragraph">
                      3.5 You must be at least 18 years old and able to enter into legally binding
                      contracts to access and use peppa.io or register a peppa.io Account. By
                      accessing or using peppa.io, you represent and warrant that you are 18 or
                      older and have the legal capacity and authority to enter into a contract.
                    </p>
                    <br />

                    <p className="paragraph">
                      3.6 peppa.io may make the access to and use of peppa.io, or certain areas or
                      features of the peppa.io website, subject to certain conditions or
                      requirements, such as signing up for an account, completing a verification
                      process, meeting specific quality or eligibility criteria.
                    </p>
                    <br />

                    <p className="paragraph">
                      3.7 Despite the verification process, peppa.io does not assume any
                      responsibility for the confirmation of any Member’s identity. Notwithstanding
                      the above, for transparency and fraud prevention purposes, and as permitted by
                      applicable laws, we may, but have no obligation to ask Members to provide a
                      form of government identification or other information or undertake additional
                      checks designed to help verify the identities or backgrounds of Members.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>4. Accounts</li>
                    <p className="paragraph">
                      4.1 In order to initiate and commence the use of our Services, all Users must
                      register an account (“peppa.io Account”). If you are registering a peppa.io
                      Account for a company or other legal entity, you represent and warrant that
                      you have the authority to legally bind that entity and grant us all
                      permissions and licenses provided in these Terms.
                    </p>
                    <br />

                    <p className="paragraph">
                      4.2 You can register a peppa.io Account using an email address and creating a
                      password.
                    </p>
                    <br />

                    <p className="paragraph">
                      4.3 You must provide accurate, current and complete information during the
                      registration process and keep your peppa.io Account profile page information
                      up-to-date at all times.
                    </p>
                    <br />

                    <p className="paragraph">
                      4.4 You are responsible for safeguarding the password that you use to access
                      the Service and for any activities or actions under your password, whether
                      your password is with our Service or a third-party service.
                    </p>
                    <br />

                    <p className="paragraph">
                      4.5 You agree not to disclose your password to any third party. You must
                      notify us immediately upon becoming aware of any breach of security or
                      unauthorized use of your account.
                    </p>
                    <br />

                    <p className="paragraph">
                      4.6 You may not use as a username the name of another person or entity or that
                      is not lawfully available for use, a name or trade mark that is subject to any
                      rights of another person or entity other than you without appropriate
                      authorization, or a name that is otherwise offensive, vulgar or obscene.
                    </p>
                    <br />

                    <p className="paragraph">
                      4.7 You are liable for any and all activities conducted through your peppa.io
                      Account, unless such activities are not authorized by you and you are not
                      otherwise negligent (such as failing to report the unauthorized use or loss of
                      your credentials).
                    </p>
                    <br />

                    <p className="paragraph">
                      4.8 When you create an account with us, you must provide us with information
                      that is accurate, complete, and current at all times. Failure to do so
                      constitutes a breach of the Terms & Conditions, which may result in immediate
                      termination of your account on our Service.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", }}>
                      5. Users’ Representations & Warranties
                    </li>
                    <p className="paragraph">
                      Representations and warranties are statements and promises made by you to
                      peppa.io, which we rely on as being accurate in our dealings with you. You
                      make the following representations and warranties to us at the time of
                      agreeing to these Terms and every time you use peppa.io’s Services;
                    </p>
                    <br />

                    <ul className="paragraph">
                      <li style={{ marginTop: "8px",fontWeight:'normal',fontSize:'18px' }}>1. You are over the age of 18 years;</li>
                      <li>
                        2. You are of sound mind and have the capacity to initiate and commence a
                        Transaction on the Site;
                      </li>
                      <li>
                        3. All personal information that you provide about yourself is accurate and
                        true to the best of your knowledge;
                      </li>
                      <li>
                        4. You hold a bank account in Nigeria in your own name and will use this
                        account to transfer money to our account when you want to initiate a
                        Transaction
                      </li>
                      <li>
                        5. You have carefully considered the risks involved with using the Website,
                        and its Services;
                      </li>
                      <li>
                        6. If you are registering or accepting these Terms on behalf of a legal
                        entity such as a company, trust or partnership, you are legally authorised
                        to do so and we may request evidence of such legal entitlement (by way of a
                        copy of any document which shows the valid and subsisting authorisation);
                        and
                      </li>
                      <li>
                        7. You are not breaching any laws or regulations that are applicable to you
                        or any company, trust or partnership upon whose instructions you are acting.
                      </li>
                    </ul>
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>6. Service Fees</li>
                    <p className="paragraph">
                      peppa.io charges Users 1.5% of the sum to be held in escrow for every
                      transaction as its service fees in consideration for the use of the platform.
                    </p>
                    <br />

                    <p className="paragraph">
                      Once paid, peppa.io fees are non-refundable. peppa.io fees may change from
                      time to time. peppa.io is not responsible for payment of any sales, use,
                      personal property or other governmental tax or levy imposed on any items
                      purchased or sold through the Services or otherwise arising from the
                      Transaction.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      7. Intellectual Property
                    </li>
                    <p className="paragraph">
                      7.1 The Service and its original content, features and functionality are and
                      will remain the exclusive property of peppa.io . Our trademarks and trade
                      dress may not be used in connection with any product or service without the
                      prior written consent of peppa.io .
                    </p>
                    <br />

                    <p className="paragraph">
                      7.2 peppa.io may, at its sole discretion, enable Users to (i) create, upload,
                      post, send, receive and store content, such as text, photos, audio, video, or
                      other materials and information on or through peppa.io website ("User
                      Content"); and (ii) access and view User Content and any content that
                      peppa.io itself makes available on or through peppa.io website, including
                      proprietary peppa.io content and any content licensed or authorized for use
                      by or through peppa.io from a third party ("peppa.io Content" and together
                      with User Content, "Collective Content").
                    </p>
                    <br />

                    <p className="paragraph">
                      7.3 You will not use, copy, adapt, modify, prepare derivative works of,
                      distribute, license, sell, transfer, publicly display, publicly perform,
                      transmit, broadcast or otherwise exploit peppa.io website or Collective
                      Content, except to the extent that you are the legal owner of certain User
                      Content or as expressly permitted in these Terms. No licenses or rights are
                      granted to you by implication or otherwise under any intellectual property
                      rights owned or controlled by peppa.io or its licensors, except for the
                      licenses and rights expressly granted in these Terms.
                    </p>
                    <br />

                    <p className="paragraph">
                      7.4 Subject to your compliance with these Terms, peppa.io grants you a
                      limited, non-exclusive, nonsub licensable, revocable, non-transferable license
                      to access and view any Collective Content made available on or through the
                      website and accessible to you, solely for your personal and noncommercial use.
                    </p>
                    <br />

                    <p className="paragraph">
                      7.5 Users shall not directly or indirectly: <br />
                      (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt
                      to derive any source code or underlying ideas or algorithms of any part of the
                      Service, except to the extent applicable laws specifically prohibit such
                      restriction;
                      <br /> (ii) modify, translate, or otherwise create derivative works of any
                      part of the Service; or <br />
                      (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights
                      that you receive hereunder.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      8. Terms specific for Sellers
                    </li>
                    <p className="paragraph">
                      8.1 On the Transaction Detail Screens, each Seller to a Transaction must
                      designate an Account to which payment for the transaction will be made.
                    </p>
                    <br />

                    <p className="paragraph">
                      8.2 Each Seller in a Transaction shall deliver the items set forth in the
                      Transaction Detail Screens directly to the Buyer(s), at the address specified
                      by such Buyer either in the information provided to the Seller directly or as
                      shown on the Site.
                    </p>
                    <br />

                    <p className="paragraph">
                      8.3 The Seller shall use a delivery service that provides a confirmation of
                      delivery and Seller shall, upon request, provide peppa.io with a tracking or
                      reference number for the shipment of the goods.
                    </p>
                    <br />

                    <p className="paragraph">
                      8.4 In the event of any dispute as to the delivery of the goods to the Buyer,
                      the Seller gives peppa.io permission to act as its agent in communicating
                      with the shipping company regarding the notice of the delivery of the goods.
                    </p>
                    <br />

                    <p className="paragraph">
                      8.5 In the event of a return of the items by a Buyer, the Seller shall notify
                      peppa.io of the receipt of the returned goods. The Seller has 2 days to
                      inspect the returned items. In the event the Seller accepts the returned items
                      within the inspection period or fails to act within the inspection period,
                      peppa.io shall remit the escrowed funds (excluding peppa.io’s Service Fees)
                      to the Buyer.
                    </p>
                    <br />

                    <p className="paragraph">
                      8.6 If the Seller notifies peppa.io of its non-acceptance of any returned
                      items within the Seller’s inspection period, then peppa.io will retain the
                      escrowed funds pending resolution of the dispute.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      9. Terms specific for Buyers
                    </li>
                    <p className="paragraph">
                      9.1 On the Transaction Detail Screens, the Buyer must designate a payment
                      mechanism and an Account from which the purchase price and related fees will
                      be obtained for deposit into the escrow.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.2 Depending on the amount of the Transaction and the currency selected for
                      the Transaction, the Buyer may remit the necessary funds via various methods,
                      which may include a credit or debit card or a wire transfer.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.3 Regardless of the payment method, the Buyer authorizes peppa.io to
                      initiate credit or debit transactions, as applicable, to obtain the purchase
                      price and fees due for a Transaction and to initiate any debit or credit
                      entries or reversals that may be necessary to correct any error in a payment
                      or transfer.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.4 peppa.io will deposit funds received from Buyer into an escrow account
                      maintained by peppa.io.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.5 The Buyer shall notify peppa.io of the Buyer’s acceptance or rejection of
                      the items before the Buyer’s Inspection Period expires.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.5 Upon receipt of notice from Buyer that the items have been received and
                      accepted, peppa.io shall transfer the payment amount (excluding peppa.io’s
                      Service Fees) to the Seller’s Account.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.6 Transfer to a Seller generally will be initiated within the next business
                      day from the day on which notice of acceptance of the items is received from
                      the Buyer.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.7 Transfer to a Seller generally will be initiated within the next business
                      day from the day on which notice of acceptance of the items is received from
                      the Buyer.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.8 If the Buyer has not notified peppa.io of the non-receipt or rejection of
                      the items during the Buyer’s Inspection Period, then the Buyer authorizes
                      peppa.io to remit the escrowed funds to the Seller.
                    </p>
                    <br />

                    <p className="paragraph">
                      9.9 In the event the items are rejected and the items are returned to the
                      Seller, the Buyer shall notify peppa.io and peppa.io will retain the escrow
                      funds pending resolution of the dispute.
                    </p>
                    <br />
                    <p className="paragraph">
                      9.10 When a Seller rejects a returned item, the Buyer and the Seller have 2
                      days to resolve their dispute (referred to as the “Negotiation Period”). If
                      after the Negotiation Period, the dispute is yet to be resolved, the Buyer and
                      Seller are provided with another 4 days for Arbitration (referred to
                      “Arbitration Period”) which is where the Buyer and Seller are expected to
                      undergo a recommended arbitration proceeding to resolve the dispute. The
                      Arbitrators are recommended by peppa.io and will take place at an agreed
                      venue.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      10. Prohibited Activities
                    </li>
                    <p className="paragraph">
                      10.1 Users shall not utilize the Site or the Services in connection with any
                      Transaction that is illegal or involves any illegal items, or is for any
                      illegal purpose.
                    </p>
                    <br />

                    <p className="paragraph">
                      10.2 peppa.io, in its sole discretion, may refuse to complete any Transaction
                      that peppa.io has reason to believe is unauthorized or may violate any law,
                      rule or regulation.
                    </p>
                    <br />

                    <p className="paragraph">
                      10.3 In addition, if any transaction cannot be completed for any reason,
                      including cancellation by peppa.io for any reason, peppa.io will notify each
                      User in such Transaction by e-mail, to the email address each has provided on
                      the Site.
                    </p>
                    <br />

                    <p className="paragraph">
                      10.4 You are solely responsible for compliance with any and all laws, rules,
                      regulations, and tax obligations that may apply to your use of the Services.
                      In connection with your use of the Site, you will not and will not assist or
                      enable others to:
                    </p>
                    <br />

                    <ul className="paragraph">
                      <span>
                        {" "}
                        - Breach or circumvent any applicable laws or regulations, agreements with
                        third-parties, third-party rights, or our Terms;
                      </span>
                      <li> - buy or sell unlawful items;</li>
                      <li >
                        {" "}
                        - use the Site or Collective Content for any commercial or other purposes
                        that are not expressly permitted by these Terms or in a manner that falsely
                        implies endorsement, partnership or otherwise misleads others as to your
                        affiliation with peppa.io;
                      </li>

                      <li>
                        {" "}
                        - copy, store or otherwise access or use any information, including
                        personally identifiable information about any other User, contained on the
                        Site in any way that is inconsistent with peppa.io’s Privacy Policy or
                        these Terms or that otherwise violates the privacy rights of Users or third
                        parties;
                      </li>
                      <li>
                        {" "}
                        - use the Site in connection with the distribution of unsolicited commercial
                        messages ("spam");
                      </li>
                      <li>
                        {" "}
                        - offer, as a Seller any goods that you do not yourself own or have
                        permission to sell
                      </li>
                      <li>
                        {" "}
                        - use the Site to with the intention to circumvent any Service Fees or for
                        any other reason;
                      </li>
                      <li>
                        {" "}
                        - request, accept or make any payment for orders outside of the peppa.io
                        platform. If you do so, you acknowledge and agree that you: (i) would be in
                        breach of these Terms; (ii) accept all risks and responsibility for such
                        payment, and (iii) hold peppa.io harmless from any liability for such
                        payment;
                      </li>
                      <li>
                        {" "}
                        - discriminate against or harass anyone on the basis of race, tribe, origin,
                        religion, gender, physical or mental disability, medical condition, marital
                        status, or age, or otherwise engage in any abusive or disruptive behaviour;
                      </li>
                      <li>
                        {" "}
                        - use, display, mirror or frame the peppa.io platform or Collective
                        Content, or any individual element within the peppa.io Platform,
                        peppa.io’s name, any peppa.io’s trademark, logo or other proprietary
                        information, or the layout and design of any page or form contained on a
                        page in the peppa.io Platform, without peppa.io’s express written consent;
                      </li>
                      <li>
                        {" "}
                        - dilute, tarnish or otherwise harm the peppa.io brand in any way,
                        including through unauthorized use of Collective Content, registering and/or
                        using peppa.io or derivative terms in domain names, trade names, trademarks
                        or other source identifiers, or registering and/or using domains names,
                        trade names, trademarks or other source identifiers that closely imitate or
                        are confusingly similar to peppa.io domains, trademarks, taglines,
                        promotional campaigns or Collective Content;
                      </li>
                      <li>
                        {" "}
                        - use any robots, spider, crawler, scraper or other automated means or
                        processes to access, collect data or other content from or otherwise
                        interact with the peppa.io Platform for any purpose;
                      </li>
                      <li>
                        {" "}
                        - avoid, bypass, remove, deactivate, impair, descramble, or otherwise
                        attempt to circumvent any technological measure implemented by peppa.io or
                        any of peppa.io's providers or any other third party to protect the
                        peppa.io Platform;
                      </li>
                      <li>
                        {" "}
                        - attempt to decipher, decompile, disassemble or reverse engineer any of the
                        software used to provide the peppa.io Platform;
                      </li>
                      <li>
                        {" "}
                        - take any action that damages or adversely affects, or could damage or
                        adversely affect the performance or proper functioning of the peppa.io
                        Platform;
                      </li>
                      <li>
                        {" "}
                        - violate or infringe anyone else’s rights or otherwise cause harm to
                        anyone.
                      </li>
                    </ul>

                    <p className="paragraph">
                      10.5 You acknowledge that peppa.io has no obligation to monitor the access to
                      or use of the Platform by any User or to review, disable access to, or edit
                      any User Content, but has the right to do so to (i) operate, secure and
                      improve the peppa.io Platform (including without limitation for fraud
                      prevention, risk assessment, investigation and customer support purposes);
                      (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable
                      law or the order or requirement of a court, law enforcement or other
                      administrative agency or governmental body; (iv) respond to User Content that
                      it determines is harmful or objectionable; or (v) as otherwise set forth in
                      these Terms. Users agree to cooperate with and assist the peppa.io Platform
                      in good faith, and to provide peppa.io with such information and take such
                      actions as may be reasonably requested by peppa.io with respect to any
                      investigation undertaken by peppa.io or a representative of peppa.io
                      regarding the use or abuse of the peppa.io Platform.
                    </p>
                    <br />

                    <p className="paragraph">
                      10.6 If you feel that any User you interact with, whether online or in person,
                      is acting or has acted inappropriately, including but not limited to anyone
                      who (i) engages in offensive, violent or sexually inappropriate behaviour,
                      (ii) you suspect of stealing from you, or (iii) engages in any other
                      disturbing conduct, you should immediately report such person to the
                      appropriate authorities and then to peppa.io by contacting us with your
                      police station and report number (if available); provided that your report
                      will not obligate us to take any action beyond that required by law (if any)
                      or cause us to incur any liability to you.
                    </p>
                    <br />
                  </div>
                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      11. Rejection of Payment
                    </li>
                    <p className="paragraph">
                      Since the use of a bank account, credit/debit card account, or the making of
                      an electronic funds transfer may be limited by your agreement with your
                      financial institution and/or by applicable laws, peppa.io is not liable to
                      any User if peppa.io does not complete a transaction as a result of any such
                      limit, or if a financial institution fails to honour any credit or debit to or
                      from an account.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      12. Links to Other Web Sites
                    </li>
                    <p className="paragraph">
                      12.1 Our Service may contain links to third-party web sites or services that
                      are not owned or controlled by peppa.io.
                    </p>
                    <br />

                    <p className="paragraph">
                      12.2 peppa.io  has no control over, and assumes no responsibility for, the
                      content, privacy policies, or practices of any third-party web sites or
                      services. You further acknowledge and agree that peppa.io shall not be
                      responsible or liable, directly or indirectly, for any damage or loss caused
                      or alleged to be caused by or in connection with use of or reliance on any
                      such content, goods or services available on or through any such web sites or
                      services.
                    </p>
                    <br />
                    <p className="paragraph">
                      12.3 We strongly advise you to read the terms and conditions and privacy
                      policies of any third-party web sites or services that you visit.
                    </p>
                    <br />
                  </div>
                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>13. Termination</li>
                    <p className="paragraph">
                      13.1 We may terminate or suspend your account immediately, without prior
                      notice or liability, for any reason whatsoever, including without limitation
                      if you breach the Terms.
                    </p>
                    <br />

                    <p className="paragraph">
                      13.2 Upon termination, your right to use the Service will immediately cease.
                      If you wish to terminate your account, you may simply discontinue using the
                      Service.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>14. Indemnity</li>
                    <p className="paragraph">
                      You agree to indemnify and hold harmless the Company, its affiliates and
                      subsidiaries, its officers, directors, employees and agents, against all
                      liabilities, costs, expenses, damages and losses (including any direct,
                      indirect or consequential losses, loss of profit, loss of reputation and all
                      interest penalties and legal and other reasonable professional costs and
                      expenses) suffered or incurred as a result of:
                    </p>
                    <br />
                    <ul className="footer-list">
                      <li>Your fraudulent or illegal use of the Services or the Site;</li>
                      <li>Your negligence or any default by you of any of these Terms;</li>
                      <li>
                        any inaccurate or incomplete information that you have knowingly provided to
                        us;
                      </li>
                      <li>
                        Your allowing any other person to access your account either with your
                        permission or as a result of your failure to keep your username and password
                        private;
                      </li>
                      <li>
                        Any service that you have offered, whether with or without our permission,
                        to another third party using the Company’s Services or Website;
                      </li>
                      <li>
                        Any claim made against you for actual or alleged infringement of the
                        Company’s Intellectual Property Rights or any actual or alleged infringement
                        of a third party’s Intellectual Property Rights arising out of or in
                        connection with the Services or your use of the Site.
                      </li>
                    </ul>
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      15. Limitation of Liability
                    </li>
                    <p className="paragraph">
                      In no event shall peppa.io, its directors, employees, partners, agents,
                      suppliers, or affiliates, be liable for any indirect, incidental, special,
                      consequential or punitive damages, including without limitation, loss of
                      profits, data, use, goodwill, or other intangible losses, resulting from
                    </p>
                    <br />
                    <li>
                      i. your use of the Site or the Services or your inability to use the Site or
                      the Services;
                    </li>
                    <li>ii. any legal proceedings between the Buyer and the Seller.</li>
                  </div>
                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>16. Disclaimer</li>
                    <p className="paragraph">
                      16.1 Your use of the Service is at your sole risk. The Service is provided on
                      an “AS IS” and “AS AVAILABLE” basis. The Service is provided without
                      warranties of any kind, whether express or implied, including, but not limited
                      to, implied warranties of merchantability, fitness for a particular purpose,
                      noninfringement or course of performance.
                    </p>
                    <br />
                    <p className="paragraph">
                      16.2 peppa.io, its subsidiaries, affiliates, and its licensors do not warrant
                      that a) the Service will function uninterrupted, secure or available at any
                      particular time or location; b) any errors or defects will be corrected; c)
                      the Service is free of viruses or other harmful components; or d) the results
                      of using the Service will meet your requirements.
                    </p>
                    <br />
                  </div>
                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>17. Governing Law</li>
                    <p className="paragraph">
                      17.1 These Terms shall be governed and construed in accordance with the laws
                      of The Federal Republic of Nigeria, without regard to its conflict of law
                      provisions.
                    </p>
                    <br />
                    <p className="paragraph">
                      17.2 Our failure to enforce any right or provision of these Terms will not be
                      considered a waiver of those rights. If any provision of these Terms is held
                      to be invalid or unenforceable by a court, the remaining provisions of these
                      Terms will remain in effect. These Terms & Conditions constitute the entire
                      agreement between us regarding our Service, and supersede and replace any
                      prior agreements we might have between us regarding the Service.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>18. Dispute Resolution</li>
                    <p className="paragraph">
                      18.1 Any disputes arising under or in connection with the validity,
                      interpretation and performance of this Terms between peppa.io and any third
                      parties that cannot be resolved amicably by the parties through negotiation
                      within 30 (thirty) days shall be resolved by Arbitration at the Lagos Court of
                      Arbitration (LCA) before a single arbitrator in accordance with the
                      Arbitration and Conciliation Act, Cap A18, Laws of the Federation of Nigeria.
                    </p>
                    <br />
                    <p className="paragraph">
                      18.2 The Parties shall endeavour in good faith to mutually agree on the
                      selection of an arbitrator. If the Parties cannot mutually agree on the
                      selection of an arbitrator within ten (10) days of the request, they shall
                      apply to the LCA to appoint an arbitrator. Arbitration proceedings shall be
                      conducted in Lagos. The arbitrator will be requested to render an award within
                      ninety (90) days and to provide, in writing the reasoning for the award. The
                      decision of any such arbitrator shall be final and binding on the parties.
                    </p>
                    <br />
                    <p className="paragraph">
                      18.3 Each party shall bear its cost in connection with the Arbitration and the
                      arbitrator’s fees shall be split equally between both parties.
                    </p>
                    <br />
                  </div>

                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>19. Feedback</li>
                    <p className="paragraph">
                      We welcome and encourage you to provide feedback, comments and suggestions for
                      improvements to peppa.io Site or Services. You may submit Feedback by
                      emailing us, through the contact section of the Site, or by other means of
                      communication. Any Feedback you submit to us will be considered
                      non-confidential and non-proprietary to you. By submitting Feedback to us, you
                      grant us a nonexclusive, worldwide, royalty-free, irrevocable, sub-licensable,
                      perpetual license to use and publish those ideas and materials for any
                      purpose, without compensation to you.
                    </p>
                    <br />
                  </div>
                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>
                      20. Changes to Terms & Conditions
                    </li>
                    <p className="paragraph">
                      peppa.io reserves the right, in its sole discretion, to change the Terms
                      under which
                      <a href="www.peppa.io"> www.peppa.io</a> is offered. The most
                      current version of the Terms will supersede all previous versions. peppa.io
                      encourages you to periodically review the Terms of Use to stay informed of our
                      updates.
                    </p>
                    <br />
                  </div>
                  <div className="paragraph-item">
                    <li style={{ marginTop: "8px", fontWeight: "bold" }}>21. Contact Us</li>
                    <p className="paragraph">
                      If you have any questions about these Terms of Use, please contact us at:
                      +234802 222 0071  or contact@pepperest.com or A14 Willow Greens Osapa Lekki
                      London.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction/>
      <Footer />
    </div>
  );
};

export default TermsAndConditionPage;
// export default withWelcomeLayout((TermsAndConditionPage), config);
