/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { InputWithoutLabel, Loader, SelectInputWithoutLabel } from "components/blocks";
import PremiumFooter from '../../components/premiumComponents/premiumFooter'
import { connect, useDispatch, useSelector } from "react-redux";
import './upgradepage.css'
import { upgradeToPremium } from "store/actions/userAccount";
import { Redirect, useLocation } from "react-router-dom";
import { Country, State, City }  from 'country-state-city';


const UpgradePage = (props) => {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const {upgradingPremium,redirectUrl} = useSelector(state=>state.userAccount);
  const {userInfo,token} = useSelector(state=>state.auth);
  const [state,setState]= useState('');
  const [city,setCity] = useState('');
  
  const availableStates = State.getStatesOfCountry('NG');
  const availableCities = City.getCitiesOfState('NG',state);
  const states = availableStates.map(state=>{
    const {name,isoCode} = state;
    return {label:name,value:isoCode}
  })

  const cities = availableCities.map(city=>{
    const {name} = city;
    return {label:name,value:name}
  })

  const { history, userType } = props;
  let isBuyer = userType === "Buyer";
  const [selectedGateway, setselectedGateway] = useState("seerbit");
    const [activeMenu,setActiveMenu] =useState('monthly')
    const [total,setTotal] =useState(2000)
    const [inputValues,setInputValues] = useState({
        "firstname":userInfo?.firstName,
        "lastname":userInfo?.lastName,
        "address":userInfo?.deliveryAddress[0]?.street_1,
        "state":'',
        "city":''
    }) 
   
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
   

    const hanldeActiveMenu = (option)=>{
        setActiveMenu(option);
        if(option==='yearly'){
            setTotal(1000*12)
        }
        if(option==='monthly'){
            setTotal(1000)
        }
    }

    const handleChange = (event) => {
        const { value, name } = event.target;
        setInputValues({ ...inputValues, [name]: value });
      };

    const upgradePremium = (e)=>{
      e.preventDefault();
      inputValues.amount=total;
      inputValues.paymentProvider = selectedGateway;
      inputValues.subscription_info = activeMenu==='monthly'? 
      'One month subscription':'One Year subscription'

      //const params = JSON.stringify(inputValues);
      const subscription_info = activeMenu=== 'monthly'? 
      'One month subscription':'One Year subscription'
      const params = JSON.stringify(
        {
          'amount': total,
          'subscription_info': subscription_info,
          'paymentProvider': selectedGateway,
          state,
          city
          }
      )
      dispatch(upgradeToPremium(params,token,userInfo))
    }

    if(!token){
      return <Redirect
      to={{
        pathname: "/login",
        state: {from: '/upgrade',message:'Signin to continue'},
      }}
    />

    }

    
    
  return (
    <main className="bg-white ">
      <div className="checkout m-0 upgrade">
      
        <div className="row justify-content-center">
        
          <main className="col-md-10 mx-auto">
          <nav className='d-flex justify-content-between align-items-center mt-2'>
        <a href="/">
          <img
            src={"/assets/images/pepperest-logo-black.png"}
            className='logo'
            alt="logo"
          />
        </a>
        </nav>
            <form onSubmit={upgradePremium}>
            <div className="row mx-auto">
            <section className="col-md-7 my-5 mx-auto border p-lg-3 p-1 rounded">
            <p className="font-weight-bold px-3">Billing Information</p>
            <div className="mx-0 pModal-form-control row">
                    <div className="col-md-6 px-0 px-md-auto">
                      <div className="col-md-10">
                        <div
                          className="pModal-form__label-control"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label className="pModal-form__label">Firstname</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <InputWithoutLabel
                          name="firstname"
                          type="text"
                          placeholder="Enter Firstname"
                          id="firstname"
                          value={inputValues.firstname}
                          onChange={handleChange}
                          required
                    //    errorMessage={!inputValues.firstname ? "Firstname required" : ""}
                          className="nsForm-input__alternate"
                        />
                      </div>
                    </div>

                     <div className="col-md-6 px-0 px-md-auto">
                      <div className="col-md-10">
                        <div
                          className="pModal-form__label-control"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label className="pModal-form__label">Lastname</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <InputWithoutLabel
                          name="lastname"
                          type="text"
                          placeholder="Enter Lastname"
                          id="lastname"
                            value={inputValues.lastname}
                          onChange={handleChange}
                          required
                        // errorMessage={!inputValues.lastname ? "Lastname required" : ""}
                          className="nsForm-input__alternate"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 px-0 px-md-auto">
                      <div className="col-md-10">
                        <div
                          className="pModal-form__label-control"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label className="pModal-form__label">Street Address <small className="text-secondary">(Optional)</small> </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <InputWithoutLabel
                          name="address"
                          type="text"
                          placeholder="Enter Address"
                          id="address"
                          value={inputValues.address}
                        onChange={handleChange}
                    
                        // errorMessage={!inputValues.address}
                          className="nsForm-input__alternate"
                        />
                      </div>
                    </div>

                    <div className="mx-0 pModal-form-control row">
                    <div className="col-md-6 px-0 px-md-auto">
                      <div className="col-md-10">
                        <div
                          className="pModal-form__label-control"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label className="pModal-form__label">State <small className="text-secondary">(Optional)</small></label>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <SelectInputWithoutLabel
                        defaultValue="Select State"
                          options={states}
                          name="state"
                          id="state"
                          value={
                         state
                          }
                        onChange={(e)=>setState(e.target.value)}
                         
                       
                          className="nsForm-select__alternate border border-black"
                        />
                      </div>
                    </div>
                    

                    <div className="col-md-6 px-0 px-md-auto">
                      <div className="col-md-8">
                        <div
                          className="pModal-form__label-control"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label className="pModal-form__label">City <small className="text-secondary">(Optional)</small></label>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <SelectInputWithoutLabel
                        defaultValue="Select City"
                          options={cities}
                          name="city"
                          id="city"
                          value={
                         city
                          }
                          onChange={(e)=>setCity(e.target.value)}
                         
                       
                          className="nsForm-select__alternate border border-black"
                        />
                      </div>
                    </div>
                  </div>

                 
                  
                    
                  <div className="pcard shadow-none">
              <div className="main-header">
                <div className="text--smaller text-font--medium address-header">
                  Choose Payment Gateway
                </div>
              </div>
              <div className="main-content">
                <div className="flex-content px-2" >
                  <div className="flex-content_child">
                    <label className="radio-container">
                      <input
                        type="radio"
                        value="seerbit"
                        checked={selectedGateway === "seerbit"}
                        onChange={() => setselectedGateway("seerbit")}
                        name="gateway"
                      />
                      <span className="radio-checkmark"></span>
                    </label>
                    <p className="ml-10 text--smaller text--gray">SeerBit</p>
                  </div>
                  <div className="space-between">
                    <img
                      src="assets/images/seerbit.png"
                      style={{ height: "20px", width: "100px" }}
                    />
                  </div>
                </div>
             
              </div>
            </div>

          </section>
          <section className="col-md-4 mt-5 mx-auto border p-lg-3 p-1 rounded summary-wrapper">
            <p className="font-weight-bold ">Summary</p>
            {/* <div className="plan-type row mx-auto mt-3 my-5 my-lg-1">
            <div className={`col-md-6 ${activeMenu==='monthly'? 'active-plan':'inactive-plan'} pt-1`} 
            onClick={()=>hanldeActiveMenu('monthly')}>Monthly</div>
            <div className={`col-md-6 ${activeMenu==='yearly'? 'active-plan':'inactive-plan'} pt-1 `}
            onClick={()=>hanldeActiveMenu('yearly')}>Yearly</div>
            </div> */}
            <div className="plan mt-4">
                <p className="plan-header mx-1">Pepperest Premium Plan</p>
                <table className="table table-borderless table-sm mt-1 premium-table" >
                <tbody>
                    <tr>
                        <td>Duration</td>
                        <td className="text-right">1 Month</td>
                    </tr>
                    
                        <tr>
                            <td>Price</td>
                            <td className="text-right">&#8358;{total}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Total</td>
                            <td className="font-weight-bold text-right">&#8358;{total}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <button
            type="submit"
              style={{ backgroundColor: "#FE8F01" }}
              className="button font-weight-light button__rounded mb-2 mt-4"
            >
              {upgradingPremium? (
                <Loader/>
              ):(
                <span>Pay Now</span>
              )}
            </button>
            {/* <p className="text-center mt-4 warning-text" style={{fontSize:'13px'}}>
            Only available at this price for the <strong>next 21 days</strong>.
              Price goes up to N5,000/month from
              1st of October, 2022
            </p> */}
          </section>
            </div>
            </form>
            
          </main>
        
        </div>
       
      </div>
      <PremiumFooter />
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    userType: state?.auth?.userInfo?.usertype,
  };
};

export default connect(mapStateToProps, null)(UpgradePage);
