import React from 'react'

const Share = ({color='white'}) => {
  return (
    <svg width="20" 
    height="20" 
    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 18.3337C14.3056 18.3337 13.7153 18.0906 13.2292 17.6045C12.7431 17.1184 12.5 16.5281 12.5 15.8337C12.5 15.7364 12.5069 15.6356 12.5208 15.5312C12.5347 15.4273 12.5556 15.3337 12.5833 15.2503L6.70833 11.8337C6.47222 12.042 6.20833 12.205 5.91667 12.3228C5.625 12.4412 5.31944 12.5003 5 12.5003C4.30556 12.5003 3.71528 12.2573 3.22917 11.7712C2.74306 11.285 2.5 10.6948 2.5 10.0003C2.5 9.30588 2.74306 8.7156 3.22917 8.22949C3.71528 7.74338 4.30556 7.50033 5 7.50033C5.31944 7.50033 5.625 7.55921 5.91667 7.67699C6.20833 7.79533 6.47222 7.95866 6.70833 8.16699L12.5833 4.75033C12.5556 4.66699 12.5347 4.57338 12.5208 4.46949C12.5069 4.36505 12.5 4.26421 12.5 4.16699C12.5 3.47255 12.7431 2.88227 13.2292 2.39616C13.7153 1.91005 14.3056 1.66699 15 1.66699C15.6944 1.66699 16.2847 1.91005 16.7708 2.39616C17.2569 2.88227 17.5 3.47255 17.5 4.16699C17.5 4.86144 17.2569 5.45171 16.7708 5.93783C16.2847 6.42394 15.6944 6.66699 15 6.66699C14.6806 6.66699 14.375 6.60783 14.0833 6.48949C13.7917 6.37171 13.5278 6.20866 13.2917 6.00033L7.41667 9.41699C7.44444 9.50033 7.46528 9.59394 7.47917 9.69783C7.49306 9.80227 7.5 9.9031 7.5 10.0003C7.5 10.0975 7.49306 10.1981 7.47917 10.302C7.46528 10.4064 7.44444 10.5003 7.41667 10.5837L13.2917 14.0003C13.5278 13.792 13.7917 13.6287 14.0833 13.5103C14.375 13.3925 14.6806 13.3337 15 13.3337C15.6944 13.3337 16.2847 13.5767 16.7708 14.0628C17.2569 14.5489 17.5 15.1392 17.5 15.8337C17.5 16.5281 17.2569 17.1184 16.7708 17.6045C16.2847 18.0906 15.6944 18.3337 15 18.3337Z" 
    fill={color}/>
    </svg>
    
  )
}

export default Share