/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default function withAuthLayout(WrappedComponent, data = {}) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ...data,
      };
    }

    render() {
      let isLoginPage = this.props.location.pathname === "/login";

      return (
        <div className='max-content'>
          <div className='auth'>
            <div className='auth-logo'>
              <NavLink to='/'>
                <img src='/assets/images/logo.png' alt='logo' />
              </NavLink>
            </div>
            {isLoginPage && (
              <p className='text-center mb-3 auth-option-label--alternate'>
                Click on the Sign Up button if you dont have an existing account
              </p>
            )}
            <WrappedComponent {...this.props} />
          </div>
        </div>
      );
    }
  };
}
