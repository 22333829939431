export const sliderArray = [
  {
    name: "Mary",
    state: "Enugu",
    comment:
      "I used Pepperest to create an online website for my store and it has saved me the stress of replying many customers on WhatsApp",
  },
  {
    name: "Ayo",
    state: "Lagos",
    comment:
      "Pepperest made it easy for me to sell directly from my instagram store. It was so easy to set up and I haven’t had any issues",
  },
  {
    name: "Tolani",
    state: "Ibadan",
    comment:
      "With Pepperest, I don't have to bother about delivery to my customers as they help take care of it. That's why I love to sell on Pepperest",
  },
  {
    name: "Emeka",
    state: "Lagos",
    comment:
      "Responding to multiple messages from customers was tasking until now. Now, I just share my online store link with customers & they buy & pay easily.",
  },
  {
    name: "Bright",
    state: "Ibadan",
    comment:
      "Pepperest makes it easy to create an online store. I just convert my Instagram page to a store and it's a simple process. Thank you Pepperest.",
  },
];

const payments = [
  {
    status: "Pending",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Ina Ankara Payment",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "Tohbeey@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna. Aenean eget blandit magna, sit ametposuere erat. Integer posuere odio tincidunt velit ornare,feugiat venenatis",
  },
  {
    status: "Open",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Fulfilled",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Flagged",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Refund",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Release",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Stop",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Initial",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Awaiting",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
  {
    status: "Blocked",
    date: "Jun 21, 2018",
    statusText: "Pending, Due in 4 days",
    transactionId: "16835004G6739590D",
    customerName: "James Blunt",
    transactionDate: "Jun 21, 2018",
    transactionDatetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    deliveryDatetime:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    paymentDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque id nisl urna",
  },
];

const customers = [
  {
    status: "Active",
    phone: "09020349834",
    customerName: "James Blunt",
    datetime: "January 21, 2019 / 15:00:23pm",
    paymentName: "Big sized shoe",
    amount: "530,600",
    customerEmail: "grriend@gmail.com",
    totalTransactions: "4",
    totalSpent: "5,030,600",
    recentTransactions: [
      {
        status: "Initial",
        amount: "12,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Fulfilled",
        amount: "18,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Initial",
        amount: "130,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Flagged",
        amount: "530,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Awaiting",
        amount: "90,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
    ],
  },
  {
    status: "Blocked",
    phone: "09020349834",
    customerName: "James Blunt",
    paymentName: "Big sized shoe",
    customerEmail: "grriend@gmail.com",
    totalTransactions: "4",
    totalSpent: "5,030,600",
    recentTransactions: [
      {
        status: "Initial",
        amount: "12,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Fulfilled",
        amount: "18,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Initial",
        amount: "130,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Flagged",
        amount: "530,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Awaiting",
        amount: "90,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
    ],
  },
  {
    status: "Active",
    phone: "09020349834",
    customerName: "James Blunt",
    paymentName: "Big sized shoe",
    customerEmail: "grriend@gmail.com",
    totalTransactions: "4",
    totalSpent: "5,030,600",
    recentTransactions: [
      {
        status: "Initial",
        amount: "12,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Fulfilled",
        amount: "18,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Initial",
        amount: "130,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Flagged",
        amount: "530,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Awaiting",
        amount: "90,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
    ],
  },
  {
    status: "Blocked",
    phone: "09020349834",
    customerName: "James Blunt",
    paymentName: "Big sized shoe",
    customerEmail: "grriend@gmail.com",
    totalTransactions: "4",
    totalSpent: "5,030,600",
    recentTransactions: [
      {
        status: "Initial",
        amount: "12,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Fulfilled",
        amount: "18,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Initial",
        amount: "130,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Flagged",
        amount: "530,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Awaiting",
        amount: "90,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
    ],
  },
  {
    status: "Active",
    phone: "09020349834",
    customerName: "James Blunt",
    paymentName: "Big sized shoe",
    customerEmail: "grriend@gmail.com",
    totalTransactions: "4",
    totalSpent: "5,030,600",
    recentTransactions: [
      {
        status: "Initial",
        amount: "12,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Fulfilled",
        amount: "18,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Initial",
        amount: "130,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Flagged",
        amount: "530,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Awaiting",
        amount: "90,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
    ],
  },
  {
    status: "Active",
    phone: "09020349834",
    customerName: "James Blunt",
    paymentName: "Big sized shoe",
    customerEmail: "grriend@gmail.com",
    totalTransactions: "4",
    totalSpent: "5,030,600",
    recentTransactions: [
      {
        status: "Initial",
        amount: "12,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Fulfilled",
        amount: "18,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Initial",
        amount: "130,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Flagged",
        amount: "530,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
      {
        status: "Awaiting",
        amount: "90,600",
        datetime: "January 21, 2019 / 15:00:23pm",
      },
    ],
  },
];

const products = [
  {
    date: "Jul 21, 2019",
    productId: "16835004G6739590D",
    productName: 'iTec 32" HD LED Television',
    dateCreated: "January 21, 2019 / 15:00:23pm",
    transactions: "4",
    deliveryDate:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    productDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id nisl urna. Aenean eget blandit magna, sit amet posuere erat. Integer posuere odio tincidunt velit ornare, feugiat venenatis",
    recentTransactions: [
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Awaiting",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
    ],
  },
  {
    date: "Jul 21, 2019",
    productId: "16835004G6739590D",
    productName: 'iTec 32" HD LED Television',
    dateCreated: "January 21, 2019 / 15:00:23pm",
    transactions: "4",
    deliveryDate:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    productDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id nisl urna. Aenean eget blandit magna, sit amet posuere erat. Integer posuere odio tincidunt velit ornare, feugiat venenatis",
    recentTransactions: [
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Awaiting",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
    ],
  },
  {
    date: "Jul 21, 2019",
    productId: "16835004G6739590D",
    productName: 'iTec 32" HD LED Television',
    dateCreated: "January 21, 2019 / 15:00:23pm",
    transactions: "4",
    deliveryDate:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    productDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id nisl urna. Aenean eget blandit magna, sit amet posuere erat. Integer posuere odio tincidunt velit ornare, feugiat venenatis",
    recentTransactions: [
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Awaiting",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
    ],
  },
  {
    date: "Jul 21, 2019",
    productId: "16835004G6739590D",
    productName: 'iTec 32" HD LED Television',
    dateCreated: "January 21, 2019 / 15:00:23pm",
    transactions: "4",
    deliveryDate:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    productDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id nisl urna. Aenean eget blandit magna, sit amet posuere erat. Integer posuere odio tincidunt velit ornare, feugiat venenatis",
    recentTransactions: [
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Awaiting",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
    ],
  },
  {
    date: "Jul 21, 2019",
    productId: "16835004G6739590D",
    productName: 'iTec 32" HD LED Television',
    dateCreated: "January 21, 2019 / 15:00:23pm",
    transactions: "4",
    deliveryDate:
      "13th - 15th August ( Your expected delivery date is 2 days from payment date.)",
    amount: "530,600",
    productDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id nisl urna. Aenean eget blandit magna, sit amet posuere erat. Integer posuere odio tincidunt velit ornare, feugiat venenatis",
    recentTransactions: [
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Awaiting",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Fulfilled",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
      {
        status: "Initial",
        amount: "17,165.00",
        customerEmail: "jameskane@gmail.com",
        datetime: "Jun 29, 2019 / 9:01 AM",
      },
    ],
  },
];

const instagramProducts = [
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "",
    date: "Jun 21, 2019",
    product_name: "Red shwad shoe",
    product_price: "450,000",
    hasImageUrl: false,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
  {
    imageUrl: "/assets/images/avatar.jpg",
    date: "Jun 21, 2019",
    product_name: "Lius Vulton Shade grey big bag",
    product_price: "450,000",
    hasImageUrl: true,
  },
];

const invoices = [
  {
    status: "paid",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "420,000",
  },
  {
    status: "confirmed",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "paid",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "confirmed",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Canceled",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "200,000",
  },
  {
    status: "paid",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "confirmed",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "paid",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "confirmed",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "420,000",
  },
  {
    status: "Canceled",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "200,000",
  },
  {
    status: "paid",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "confirmed",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "paid",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "confirmed",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Canceled",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "200,000",
  },
  {
    status: "pending",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "420,000",
  },
  {
    status: "pending",
    date: "Jun 21, 2018",
    invoiceId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
];
const orders = [
  {
    status: "Processing",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "420,000",
  },
  {
    status: "Shipped",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "In Transit",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Delivered",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Canceled",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "200,000",
  },
  {
    status: "In Transit",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Delivered",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Shipped",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Processing",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "420,000",
  },
  {
    status: "Canceled",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "200,000",
  },
  {
    status: "Shipped",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Delivered",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "In Transit",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Delivered",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
  {
    status: "Canceled",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "200,000",
  },
  {
    status: "Processing",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "420,000",
  },
  {
    status: "In Transit",
    date: "Jun 21, 2018",
    orderId: "#9999202241",
    address: "40 Abiodun Alakemo str, off ikorodu road, maryland lagos",
    cost: "20,000",
  },
];

const extraBusinessFeatures = [
  {
    isActive: false,
    title: "Sign Up And Get Your Free Store",
  },
  {
    isActive: false,
    title: "Connect Your IG/FB Store",
  },
  {
    isActive: false,
    title: "Generate Your Store Link",
  },
  {
    isActive: false,
    title: "Share With Your Customers",
  },
  {
    isActive: false,
    title: "Buyers Makes Transfer",
  },
  {
    isActive: false,
    title: "Product Is Delivered",
  },
  {
    isActive: false,
    title: "Buyer Confirms Reciept Of Product",
  },
  {
    isActive: false,
    title: "Your Get Paid Securely",
  },
];
// const extraBusinessFeatures = [
//   {
//     isActive: false,
//     title: 'Bank Account Payments',
//   },
//   {
//     isActive: false,
//     title: 'Debit Card Payment',
//   },
//   {
//     isActive: false,
//     title: 'Reporting Dashboard',
//   },
//   {
//     isActive: false,
//     title: 'Buyer Protection',
//   },
//   {
//     isActive: false,
//     title: 'Refund Authorization',
//   },
//   {
//     isActive: false,
//     title: 'Create an invoice',
//   },
// ];

const businessFeatures = [
  {
    isActive: false,
    title: "Generate Payment Link",
  },
  {
    isActive: false,
    title: "Customer Pay",
  },
  {
    isActive: false,
    title: "You Deliver Product",
  },
  {
    isActive: false,
    title: "You Get Paid",
  },
  {
    isActive: false,
    title: "Successful Transaction",
  },
];
const premiumBenefits = [
  {
    icon: "dollar-circle.svg",
    title: "Installmental payments",
    text: `You get credited in full when a customer buys, but your customer pays in three installments`,
  },
  {
    icon: "product-upload.svg",
    title: "Unlimited Product uploads",
    text: `Upload as many products as you want on your Pepperest page`,
  },
  {
    icon: "easy-integration.svg",
    title: "Easy Integration with Facebook & Instagram",
    text: `Connect your facebook & instagram business page in less than 3 minutes`,
  },
  {
    icon: "truck-fast.svg",
    title: "Integrated Logistics and Delivery Service",
    text: `Never have to worry about delivery or logistics issues again`,
  },
  {
    icon: "data-analysis.svg",
    title: "Business data analytics",
    text: `Stay at the top of your business with key insights to help develop business strategies`,
  },
  {
    icon: "clipboard-text.svg",
    title: "Access to business invoice financing",
    text: `Get easy access to financing for your business `,
  },
];
const premiumFeatures = {
  basic: [
    "Maximum 50 products",
    "Easy integration with Facebook & Instagram",
    "Integrated logistics & delivery",
    "Access to unlimited support",
  ],
  premium: [
    "Unlimited product upload",
    "Easy integration with Facebook & Instagram",
    "Integrated logistics & delivery",
    "Access to unlimited support",
    "Installmental payments for your customers",
    "Business data analytics",
    "Access to business invoice financing",
    "Free Deals of the Day promotion for your store",
  ],
};
const ambassedorBenefits = [
  {
    icon: "earn-money.svg",
    title: "Earn Money",
    text: `Make passive income weekly through commissions and bonuses`,
  },
  {
    icon: "book.svg",
    title: "Learn",
    text: `Attend periodical webinars and programs to help you learn new skills and sharpen existing ones`,
  },
  {
    icon: "brand-swag.svg",
    title: "Branded SWAGS",
    text: `You get cool and fancy branded items for yourself, friends and colleagues`,
  },
  {
    icon: "circle.svg",
    title: "Inner Circle",
    text: `You’re a part of what we’re building in Pepperest and you get the chance to share your ideas and creativity`,
  },
  {
    icon: "community.svg",
    title: "Community",
    text: `Connect with other ambassadors within and outside your school and collaborate on exciting projects`,
  },
  {
    icon: "grow.svg",
    title: "Grow as we Grow",
    text: `You’re not left behind, get fast information about our new projects, products and opportunities.`,
  },
];
const buyerExperienceFeatures = [
  {
    icon: "pay-protect.svg",
    title: "Buyers’ payment protection",
    text: `100% protection of your funds against fraudulent merchants`,
  },
  {
    icon: "refund.svg",
    title: "Automated Refunds",
    text: `Your refunds are instantly processed`,
  },
  {
    icon: "support.svg",
    title: "24/7 Support",
    text: `Need help with orders on Pepperest? We're always one click away`,
  },
  {
    icon: "dispute-resolve.svg",
    title: "Dispute Resolution",
    text: `We provide quick support if you have disputes with Pepperest stores`,
  },
];
const marketPlaceProduct = [
  {
    id: 1,
    productName: "Organic Activated Charcoal",
    price: 15250,
    store: "Akon & Stores",
    store_url: "akon-stores",
    image_url: "featured-product.png",
  },
  {
    id: 2,
    productName: "Gold Wrist Watch",
    price: 10050,
    store: "Nasglobal World",
    store_url: "nasglobal-world",
    image_url: "gold2.jpg",
  },
  {
    id: 3,
    productName: "Electric Iron",
    price: 21000,
    store: "Akon & Stores",
    store_url: "akon-stores",
    image_url: "featured-product.png",
  },
  {
    id: 4,
    productName: "Asus Zendbook",
    price: 800200,
    store: "Sass Computers",
    store_url: "sass-computers",
    image_url: "featured-product.png",
  },
  {
    id: 4,
    productName: "Organic Activated Charcoal",
    price: 15250,
    store: "Akon & Stores",
    store_url: "akon-stores",
    image_url: "featured-product.png",
  },
  {
    id: 4,
    productName: "Men Dashiki",
    price: 28250,
    store: "MadeMen Stitch",
    store_url: "mademen-stitch",
    image_url: "men-fashion.jpg",
  },
];
const stores = [
  {
    name: "Scent Lagos store",
    url: "scent-lagos",
  },
  {
    name: "yugo Brand",
    url: "yugo",
  },
  {
    name: "Youngzy Store",
    url: "youngzy-store",
  },
  {
    name: "Comfort Beauty Arena",
    url: "comfort-beauty",
  },
  {
    name: "Ella Gold Footwears",
    url: "ella-gold",
  },
  {
    name: "Mademen Stiche",
    url: "made-men-stictch",
  },
  {
    name: "Nasglobal World",
    url: "nasglobal-world",
  },
  {
    name: "Jude Computers",
    url: "jude-computers",
  },
];

const states = [
  {
    label: "Abuja",
    value: "Abuja",
  },
  {
    label: "Lagos",
    value: "Lagos",
  },
  {
    label: "Ogun",
    value: "Ogun",
  },
];
const cities = [
  {
    label: "Abuja",
    value: "Abuja",
  },
  {
    label: "Lagos",
    value: "Lagos",
  },
  {
    label: "Ogun",
    value: "Ogun",
  },
];

const premiumPackage = [
  "Unlimited product upload",
  "Easy integration with Facebook & Instagram",
  "Door-to-door delivery",
  "Access to unlimited support",
  "Business data analytics",
  "Access to business loans",
  "Free Deals of the Day promotion for your store",
];
const orderStatus = [
  { label: "In Progress", value: "In Progress" },
  { label: "Confirmed", value: "Confirmed" },
  { label: "Shipped", value: "Shipped" },
  { label: "Canceled", value: "Canceled" },
];
const fileTypes = ["png", "jpg", "jpeg", "gif", "bmp"];

const pickupOrderStatus = [{ label: "Completed", value: "Completed" }];

const invoiceStatus = [
  { label: "Completed", value: "Completed" },
  { label: "Delivered", value: "Delivered" },
  { label: "Canceled", value: "Canceled" },
];

const productData= [
  {
      "id": 616,
      "productName": "Energy Meter",
      "productDescription": "to recharge",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Oct 05, 2023",
      "dateCreated": "Oct 05, 2023 / 21:31:00",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/616/1692826298.jpeg",
      "other_images": [],
      "amount": "4800.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [],
      "deliveryDate": 2,
      "transactions": 2,
      "recentTransactions": [
          {
              "posting_date": "2023-09-25 22:32:56",
              "trans_status": "Payment Initiated",
              "amount": "4872.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 616
          },
          {
              "posting_date": "2023-10-05 22:30:39",
              "trans_status": "Pending",
              "amount": "4872.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 616
          }
      ],
      "height": 0,
      "weight": 4,
      "length": 0,
      "width": 0,
      "quantity": 31,
      "product_type": "",
      "category": {
          "id": 3,
          "category": "Electronics & gadgets"
      },
      "subCategory": "{}"
  },
  {
      "id": 612,
      "productName": "Billboard 4000px",
      "productDescription": "billboard",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Jul 06, 2023",
      "dateCreated": "Jul 06, 2023 / 14:41:04",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/612/1688654464.jpg",
      "other_images": [],
      "amount": "4800.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [],
      "deliveryDate": 2,
      "transactions": 0,
      "recentTransactions": [],
      "height": 0,
      "weight": 0.01,
      "length": 0,
      "width": 0,
      "quantity": 10,
      "product_type": "",
      "category": {
          "id": 3,
          "category": "Electronics & gadgets"
      },
      "subCategory": "{}"
  },
  {
      "id": 611,
      "productName": "Nexus 50 Inch",
      "productDescription": "new products",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Jul 06, 2023",
      "dateCreated": "Jul 06, 2023 / 14:36:49",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/611/1688654209.jpg",
      "other_images": [],
      "amount": "40000.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [
          {
              "id": 53,
              "product_id": 611,
              "name": "size",
              "variants": "{\"1\":\"44\",\"2\":\"55\"}",
              "created_at": "2023-07-06 15:36:49",
              "updated_at": "2023-07-06 15:36:49"
          }
      ],
      "deliveryDate": 2,
      "transactions": 1,
      "recentTransactions": [
          {
              "posting_date": "2023-07-19 11:02:54",
              "trans_status": "Pending",
              "amount": "40600.00",
              "customer_email": "parkcodedr@gmail.com",
              "productID": 611
          }
      ],
      "height": 0,
      "weight": 0.01,
      "length": 0,
      "width": 0,
      "quantity": 32,
      "product_type": "",
      "category": {
          "id": 11,
          "category": "Fashion wears"
      },
      "subCategory": "{}"
  },
  {
      "id": 607,
      "productName": "Couples outfit",
      "productDescription": "couple cloths for men",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Jun 19, 2023",
      "dateCreated": "Jun 19, 2023 / 12:02:14",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/607/1686849740.jpeg",
      "other_images": [
          {
              "id": 24,
              "productID": 607,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/607/others/1_1686849740.jpeg",
              "created_at": "2023-06-15 18:22:20",
              "updated_at": "2023-06-15 18:22:20"
          },
          {
              "id": 25,
              "productID": 607,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/607/others/2_1686849740.jpeg",
              "created_at": "2023-06-15 18:22:20",
              "updated_at": "2023-06-15 18:22:20"
          },
          {
              "id": 26,
              "productID": 607,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/607/others/3_1686849740.jpeg",
              "created_at": "2023-06-15 18:22:20",
              "updated_at": "2023-06-15 18:22:20"
          },
          {
              "id": 27,
              "productID": 607,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/607/others/4_1686849740.jpeg",
              "created_at": "2023-06-15 18:22:20",
              "updated_at": "2023-06-15 18:22:20"
          }
      ],
      "amount": "90000.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [
          {
              "id": 51,
              "product_id": 607,
              "name": "size",
              "variants": "{\"1\":\"44\",\"2\":\"55\",\"3\":\"50\"}",
              "created_at": "2023-06-19 13:02:14",
              "updated_at": "2023-06-19 13:02:14"
          }
      ],
      "deliveryDate": 2,
      "transactions": 4,
      "recentTransactions": [
          {
              "posting_date": "2023-06-16 10:09:01",
              "trans_status": "Paid",
              "amount": "369050.00",
              "customer_email": "okwori32@gmail.com",
              "productID": 607
          },
          {
              "posting_date": "2023-06-16 11:33:26",
              "trans_status": "Payment Initiated",
              "amount": "92300.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 607
          },
          {
              "posting_date": "2023-06-16 11:39:11",
              "trans_status": "Payment Initiated",
              "amount": "92300.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 607
          },
          {
              "posting_date": "2023-06-29 11:14:19",
              "trans_status": "Payment Initiated",
              "amount": "91350.00",
              "customer_email": "gabbyae@gmail.com",
              "productID": 607
          }
      ],
      "height": 0,
      "weight": 0.01,
      "length": 0,
      "width": 0,
      "quantity": 28,
      "product_type": "",
      "category": {
          "id": 1,
          "category": "Hot food"
      },
      "subCategory": "{}"
  },
  {
      "id": 606,
      "productName": "Clothes",
      "productDescription": "Clothes",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Jun 09, 2023",
      "dateCreated": "Jun 09, 2023 / 11:25:42",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/606/1686309942.png",
      "other_images": [
          {
              "id": 21,
              "productID": 606,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/606/others/1_1686309942.png",
              "created_at": "2023-06-09 12:25:42",
              "updated_at": "2023-06-09 12:25:42"
          },
          {
              "id": 22,
              "productID": 606,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/606/others/2_1686309942.png",
              "created_at": "2023-06-09 12:25:42",
              "updated_at": "2023-06-09 12:25:42"
          },
          {
              "id": 23,
              "productID": 606,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/606/others/3_1686309942.png",
              "created_at": "2023-06-09 12:25:42",
              "updated_at": "2023-06-09 12:25:42"
          }
      ],
      "amount": "4800.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [
          {
              "id": 48,
              "product_id": 606,
              "name": "Size",
              "variants": "{\"1\":\"45\",\"2\":\"65\",\"3\":\"44\"}",
              "created_at": "2023-06-09 12:25:42",
              "updated_at": "2023-06-09 12:25:42"
          }
      ],
      "deliveryDate": 2,
      "transactions": 1,
      "recentTransactions": [
          {
              "posting_date": "2023-07-03 11:25:12",
              "trans_status": "Payment Initiated",
              "amount": "4872.00",
              "customer_email": "parkcodedr@gmail.com",
              "productID": 606
          }
      ],
      "height": 0,
      "weight": 0.01,
      "length": 0,
      "width": 0,
      "quantity": 22,
      "product_type": "",
      "category": {
          "id": 8,
          "category": "Medical supplies"
      },
      "subCategory": "{}"
  },
  {
      "id": 605,
      "productName": "Men Dashiki",
      "productDescription": "product",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Jul 04, 2023",
      "dateCreated": "Jul 04, 2023 / 13:17:07",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/605/1686306861.jpg",
      "other_images": [
          {
              "id": 18,
              "productID": 605,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/605/others/1_1686306861.jpg",
              "created_at": "2023-06-09 11:34:21",
              "updated_at": "2023-06-09 11:34:21"
          },
          {
              "id": 19,
              "productID": 605,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/605/others/2_1686306861.jpg",
              "created_at": "2023-06-09 11:34:21",
              "updated_at": "2023-06-09 11:34:21"
          },
          {
              "id": 20,
              "productID": 605,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/605/others/3_1686306861.jpg",
              "created_at": "2023-06-09 11:34:21",
              "updated_at": "2023-06-09 11:34:21"
          }
      ],
      "amount": "45000.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [
          {
              "id": 47,
              "product_id": 605,
              "name": "size",
              "variants": "{\"1\":\"XL\",\"2\":\"SM\",\"3\":\"XXL\"}",
              "created_at": "2023-06-09 11:34:21",
              "updated_at": "2023-06-09 11:34:21"
          }
      ],
      "deliveryDate": 2,
      "transactions": 4,
      "recentTransactions": [
          {
              "posting_date": "2023-06-15 11:02:34",
              "trans_status": "Paid",
              "amount": "738050.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 605
          },
          {
              "posting_date": "2023-06-16 11:45:18",
              "trans_status": "Payment Initiated",
              "amount": "92300.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 605
          },
          {
              "posting_date": "2023-07-03 11:20:15",
              "trans_status": "Payment Initiated",
              "amount": "45675.00",
              "customer_email": "parkcodedr@gmail.com",
              "productID": 605
          },
          {
              "posting_date": "2023-07-04 14:16:34",
              "trans_status": "Paid",
              "amount": "45675.00",
              "customer_email": "parkcodedr@gmail.com",
              "productID": 605
          }
      ],
      "height": 0,
      "weight": 0.01,
      "length": 0,
      "width": 0,
      "quantity": 25,
      "product_type": "",
      "category": {
          "id": 11,
          "category": "Fashion wears"
      },
      "subCategory": "{}"
  },
  {
      "id": 599,
      "productName": "Cola Shirt",
      "productDescription": "it provide more confort",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Aug 16, 2023",
      "dateCreated": "Aug 16, 2023 / 07:27:36",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/599/1686001832.jpg",
      "other_images": [
          {
              "id": 8,
              "productID": 599,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/599/others/1_1685999558.jpeg",
              "created_at": "2023-06-05 22:12:38",
              "updated_at": "2023-06-05 22:12:38"
          },
          {
              "id": 9,
              "productID": 599,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/599/others/2_1685999558.jpeg",
              "created_at": "2023-06-05 22:12:38",
              "updated_at": "2023-06-05 22:12:38"
          },
          {
              "id": 10,
              "productID": 599,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/599/others/1_1686001497.jpg",
              "created_at": "2023-06-05 22:44:57",
              "updated_at": "2023-06-05 22:44:57"
          },
          {
              "id": 11,
              "productID": 599,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/599/others/1_1686001832.jpg",
              "created_at": "2023-06-05 22:50:32",
              "updated_at": "2023-06-05 22:50:32"
          },
          {
              "id": 12,
              "productID": 599,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/599/others/2_1686001832.jpg",
              "created_at": "2023-06-05 22:50:32",
              "updated_at": "2023-06-05 22:50:32"
          }
      ],
      "amount": "190000.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [],
      "deliveryDate": 2,
      "transactions": 0,
      "recentTransactions": [],
      "height": 0,
      "weight": 2,
      "length": 0,
      "width": 0,
      "quantity": 12,
      "product_type": "",
      "category": {
          "id": 3,
          "category": "Electronics & gadgets"
      },
      "subCategory": "{}"
  },
  {
      "id": 591,
      "productName": "Senwei",
      "productDescription": "product",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Jun 07, 2023",
      "dateCreated": "Jun 07, 2023 / 07:47:55",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/591/1685461554.jpg",
      "other_images": [
          {
              "id": 3,
              "productID": 591,
              "image_link": "http://v2.pepperest.com/EscrowBackend/storage/products/591/others/1_1685459024.jpeg",
              "created_at": "2023-05-30 16:03:44",
              "updated_at": "2023-05-30 16:03:44"
          }
      ],
      "amount": "90000.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [
          {
              "id": 41,
              "product_id": 591,
              "name": "color",
              "variants": "{\"1\":\"red\",\"2\":\"blue\"}",
              "created_at": "2023-06-05 22:57:57",
              "updated_at": "2023-06-05 22:57:57"
          },
          {
              "id": 42,
              "product_id": 591,
              "name": "size",
              "variants": "{\"1\":\"44\",\"2\":\"46\"}",
              "created_at": "2023-06-05 22:57:57",
              "updated_at": "2023-06-05 22:57:57"
          }
      ],
      "deliveryDate": 2,
      "transactions": 3,
      "recentTransactions": [
          {
              "posting_date": "2023-05-31 00:04:28",
              "trans_status": "Payment Initiated",
              "amount": "461300.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 591
          },
          {
              "posting_date": "2023-05-31 10:49:27",
              "trans_status": "Paid",
              "amount": "276800.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 591
          },
          {
              "posting_date": "2023-06-07 08:42:23",
              "trans_status": "Paid",
              "amount": "276800.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 591
          }
      ],
      "height": 0,
      "weight": 31,
      "length": 0,
      "width": 0,
      "quantity": 28,
      "product_type": "",
      "category": {
          "id": 3,
          "category": "Electronics & gadgets"
      },
      "subCategory": "{}"
  },
  {
      "id": 589,
      "productName": "Energy Meter",
      "productDescription": "meter",
      "merchantID": 899,
      "store_name": "Kaiva Food",
      "merchant_code": "kaiva-food",
      "merchant_store_banner": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/banners/899/1692825794.jpg",
      "merchant_store_logo": "http://v2.pepperest.com/EscrowBackend/storage/merchants/stores/logos/899/1693580249.jpeg",
      "merchant_email": "bamtest@yopmail.com",
      "date": "Jun 15, 2023",
      "dateCreated": "Jun 15, 2023 / 10:04:51",
      "link": null,
      "image_url": "http://v2.pepperest.com/EscrowBackend/storage/products/589/1685014924.jpg",
      "other_images": [],
      "amount": "90000.00",
      "discount_percent": "0",
      "discount_amount": 0,
      "discounted_price": 0,
      "currency": "NGN",
      "productVariants": [
          {
              "id": 49,
              "product_id": 589,
              "name": "color",
              "variants": "{\"1\":\"red\",\"2\":\"blue\",\"3\":\"green\"}",
              "created_at": "2023-06-09 15:07:32",
              "updated_at": "2023-06-09 15:07:32"
          }
      ],
      "deliveryDate": 2,
      "transactions": 2,
      "recentTransactions": [
          {
              "posting_date": "2023-05-31 00:04:28",
              "trans_status": "Payment Initiated",
              "amount": "461300.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 589
          },
          {
              "posting_date": "2023-06-15 11:02:34",
              "trans_status": "Paid",
              "amount": "738050.00",
              "customer_email": "okwori9@gmail.com",
              "productID": 589
          }
      ],
      "height": 0,
      "weight": 0.02,
      "length": 0,
      "width": 0,
      "quantity": -1,
      "product_type": "Tangible",
      "category": {
          "id": 3,
          "category": "Electronics & gadgets"
      },
      "subCategory": "{}"
  }
]

export {
  payments,
  customers,
  products,
  instagramProducts,
  orders,
  invoices,
  businessFeatures,
  extraBusinessFeatures,
  premiumBenefits,
  premiumFeatures,
  ambassedorBenefits,
  buyerExperienceFeatures,
  states,
  cities,
  premiumPackage,
  marketPlaceProduct,
  stores,
  orderStatus,
  pickupOrderStatus,
  fileTypes,
  invoiceStatus,
  productData
};
export { default as PepperestWebServices } from "./PepperestWebServices";
