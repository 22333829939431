let _cio = window._cio;
function identifyUser(userData) {
  if (userData?.email && userData?.email != "undefined") {
    _cio.identify({
      id: userData?.email,
      created_at: new Date().getTime(),
      ...userData,
    });
  }
}

function trackEvent(event_name, eventData) {
  _cio.track(event_name, eventData);
}

export { trackEvent, identifyUser };
