/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { NavLink } from "components/blocks";
import { Avatar } from "components/shared";
import { DownChevron, HamburgerIcon } from "components/vectors";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const navLinks = [
  {
    title: "Home",
    url: "/",
    exact: false,
  },
  {
    title: "Privacy",
    url: "/privacy",
    exact: false,
  },
  {
    title: "Terms and Conditions",
    url: "/terms-condition",
    exact: false,
  },
];

const Header = (props) => {
  const [state, setState] = useState({ isMobileMenuOpen: false });

  const handleCopy = (text) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(`Copied: ${textField.value} `);
  };

  return (
    <div className="nsHeader">
      <div className="nsHeader-main">
        <a className="nsHeader-logo" href="/">
          <img
            src="/assets/images/logo/pepperest-logo-white.png"
            srcSet="/assets/images/logo/pepperest-logo-white@2x.png 2x,
                            /assets/images/logo/pepperest-logo-white@3x.png 3x"
            alt="logo"
          />
        </a>
        <nav className="nsHeader-nav">
          {navLinks.map(({ title, url, exact }, index) => {
            return (
              <NavLink
                key={url}
                title={title}
                url={url}
                classNames="nsHeader-nav__item"
                exact={exact}
              />
            );
          })}
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
  };
};

export default connect(mapStateToProps, null)(Header);
