
import React from 'react'
import { useLocation } from 'react-router-dom';
import CompletedPayments from './CompletedPayments';
import IncomingPayments from './IncomingPayments';
import { useMemo } from 'react';

const Payments = () => {
  const location = useLocation();
  const tab = useMemo(() => {
    return new URLSearchParams(location.search).get("tab");
  }, [location.search]);

  
  return (
    <main className='payments'>
        {
          tab === 'completed-payments' ? (
            <CompletedPayments />
          ):(
            <IncomingPayments/>
          )
        }
       
    </main>
  )
}

export default Payments