import React from 'react'

const UserIcon = ({color='white',width=22,height=22})=>(
    <svg width={width} height={height} viewBox="0 0 22 22" 
    fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 10.9987C13.5313 10.9987 15.5833 8.94667 15.5833 6.41536C15.5833 3.88406 13.5313 1.83203 11 1.83203C8.46865 1.83203 6.41663 3.88406 6.41663 6.41536C6.41663 8.94667 8.46865 10.9987 11 10.9987Z"
 stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.8742 20.1667C18.8742 16.6192 15.345 13.75 11 13.75C6.65502 13.75 3.12585 16.6192 3.12585 20.1667" 
stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)
export default UserIcon;