import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Orders,
  OrdersErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { PAGE_TYPES as OrdersPageTypes } from "libs/constants/PepperestWebServices/Orders";
import { setAlert } from "./alert";
import { getStringHash } from "libs/utils";
import { FbTrackCustomEvent } from "libs/facebookPanel";
import { setStateInLocalStorage } from "store/utility";
import { clearCart } from "./cart";

// dispatch( setAlert('An error occurred.', 'error', getStringHash()))

export const loadOrders = (token, user, pageType, extraParams = {}) => {
  return (dispatch) => {
    dispatch(loadingOrders());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      customerID: user.customerID,
      pageType: pageType,
      ...extraParams,
    };
    PepperestAxios.get(Orders.ORDERS, { params, headers })
      .then((response) => {
        if (pageType === OrdersPageTypes.DASHBOARD) {
          const orders = response.data.orders;
          const total = response.data.total;
          const canceled = response.data.canceled;
          const pending = response.data.pending;
          const shipped = response.data.shipped;
          const today_orders = response.data.today_orders;
          const yesterday_orders = response.data.yesterday_orders;
          const totalRevenue = response.data.total_revenue;
          dispatch(
            loadedOrdersDashboard(
              orders,
              total,
              canceled,
              pending,
              shipped,
              today_orders,
              yesterday_orders,
              pageType,
              totalRevenue
            )
          );
        } else {
          const orders = response.data.orders.data;
          const meta = response.data.orders.meta;
          const links = response.data.orders.links;
          dispatch(loadedOrders(orders, meta, links));
        }
      })
      .catch((error) => {
        dispatch(
          failedToLoadOrders(pageType, OrdersErrorMessages.getHistoryFailed)
        );
      });
  };
};

export const getReferralStats = (token, user) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
    };
    PepperestAxios.get(Orders.GET_REFFERALS_STATS, { headers })
      .then((response) => {
        dispatch(setReferralStats(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// export const thirdPartyCheckout = (token, paymentUrl, paymentRef, user) => {
//   return (dispatch) => {
//     const headers = {
//       Authorization: token,
//       token: user.merchantCode,
//     };
//     const params = {
//       external_tranx_ref: paymentRef,
//     };

//     PepperestAxios.get(Orders.THIRD_PARTY_PAYMENT_LINK, { params, headers })
//       .then((response) => {
//         console.log(response.data, "the response from the payment checkout");
//         dispatch(confirmThirdPartyLoading(true));
//         dispatch(redirect(response.data.paymentUrl));
//         dispatch(setAlert("Order placed successfully.", "success", getStringHash()));
//       })
//       .catch((error) => {
//         // dispatch(confirmLoading(false));
//         dispatch(confirmThirdPartyLoading(false));
//         console.error(error.response);
//         dispatch(setAlert("An error occurred.", "error", getStringHash()));
//       });
//   };
// };

export const placeOrder = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Orders.PLACE_ORDER, body, { headers: headers })
      .then((response) => {
        const order = response.data.order;
        const cart = response.data.cart;
        FbTrackCustomEvent("INITIATE_CHECKOUT");
        dispatch(redirect(response.data.paymentUrl));
        dispatch(paymentGateway(response.data.order.payment_gateway));
        dispatch(cartOrder({ ...order, cart_item: cart.cart_items }));
        setStateInLocalStorage(
          "cartOrder",
          JSON.stringify({ ...order, cart_item: cart.cart_items })
        );
        dispatch(
          setAlert("Order placed successfully.", "success", getStringHash())
        );
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data?.message
          : "An error occurred.";
        console.error(error.response);
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

export const getCourierRates = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      ...extraParams,
    };

    PepperestAxios.get(Orders.GET_COURIER_SERVICE, { params, headers })
      .then((response) => {
        // dispatch(redirect(response.data.paymentUrl));
        // dispatch(setAlert("Order placed successfully.", "success", getStringHash()));

        dispatch(setCourierService(response.data));
      })
      .catch((error) => {
        if (error.response && error?.response?.status === 401) {
          console.error(error?.response, "from getting courier id");
          return dispatch(setAlert(error?.response?.data.message, "error"));
          // dispatch(
          //   setAlert(
          //     "Please check your selected delivery Address if all details are in place",
          //     "error",
          //   )
          // );
        }
        let message = error.response
          ? error.response?.data?.ResponseMessage
          : "An error occurred";
        return dispatch(setAlert(message, "error"));
      });
  };
};

export const confirmOrder = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch(confirmLoading(true));
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      customerID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.get(Orders.CONFIRM_ORDER, { params, headers })
      .then((response) => {
        dispatch({
          type: actionTypes.CONFIRM_ORDER_DETAILS,
          payload: {
            confirmOrderDetails: response.data,
            paymentGateway: null,
            cartOrder: {},
          },
        });
        dispatch(clearCart());
        dispatch(confirmLoading(false));
      })
      .catch((error) => {
        console.error(error.response);

        dispatch(setAlert("An error occurred.", "error"));
        dispatch(confirmLoading(false));
      });
  };
};

// View order details actionTypes
export const viewOrderDetails = (extraParams = {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.VIEW_ORDER_DETAILS });
    PepperestAxios.get(Orders.VIEW_ORDER, { params: extraParams })
      .then((response) => {
        dispatch({
          type: actionTypes.VIEW_ORDER_DETAILS_SUCCESS,
          payload: {
            orderDetails: response?.data?.order,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        const message = error?.response?.data?.ResponseMessage;
        dispatch({ type: actionTypes.VIEW_ORDER_DETAILS_FAIL, error: message });
      });
  };
};

// Third Party transaction reference
export const getExternalTrxRef = (token, externalTrxRef) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      token: "emma1-1615386441",
    };
    const params = {
      external_tranx_ref: externalTrxRef,
    };
    dispatch(confirmLoading(true));
    PepperestAxios.get(Orders.GET_THIRDPARTY_ORDER, { params, headers })
      .then((response) => {
        dispatch({
          type: actionTypes.CONFIRM_THIRDPARTY_ORDER_DETAILS,
          payload: response.data.order,
        });
        dispatch(confirmLoading(false));
      })
      .catch((error) => {
        console.error(error.response);

        dispatch(setAlert("An error occurred.", "error", getStringHash()));
        dispatch(confirmLoading(false));
      });
  };
};

export const getAddress = (token, user) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      customerID: user.customerID,
    };
    PepperestAxios.get(Orders.ADDRESS, { params, headers })
      .then((response) => {
        dispatch(setAddress(response.data.addresses));
      })
      .catch((error) => {
        dispatch(setAlert("An error occurred.", "error", getStringHash()));
        dispatch(addressError());
        // dispatch(failedToLoadOrders(pageType, OrdersErrorMessages.getHistoryFailed))
      });
  };
};

export const changeOrderStatus = (token, user, orderID, status) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CHANGE_ORDER_STATUS });
    const { ...headers } = {
      Authorization: token,
      customerID: user.customerID,
    };

    const body = {
      orderID: orderID,
      action: status,
      proof_docs: [],
    };
    PepperestAxios.post(Orders.CHANGE_ORDER_STATUS, body, { headers })
      .then((response) => {
        dispatch(setOrderStatus(response?.data?.order));
        dispatch({ type: actionTypes.CHANGE_ORDER_STATUS_SUCCESS });
        dispatch(setAlert("Order status changed", "success"));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch({ type: actionTypes.CHANGE_ORDER_STATUS_FAILED });
          return dispatch(
            setAlert(error?.response?.data?.ResponseMessage, "error")
          );
        }
      });
  };
};

export const addAddress = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const { ...headers } = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      name: user?.name || user.email,
      ...extraParams,
    };

    PepperestAxios.post(Orders.ADD_ADDRESS, body, { headers })
      .then((response) => {
        dispatch(setAddress(response.data.addresses));
        dispatch(setAlert("Address added.", "success"));
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data?.message
          : "An error occurred.";
        console.error(error.response);
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

// Get the pickup address for the merchant that
export const getPickupAddress = (token) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
    };

    PepperestAxios.get(Orders.GET_PICK_UP_ADDRESS, { headers })
      .then((response) => {
        dispatch(setPickUpAddress(response.data.p_address));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Add a Pick up address for the Merchant
export const addPickUpAddresses = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      ...extraParams,
    };

    PepperestAxios.post(Orders.ADD_PICK_UP_ADDRESS, params, { headers })
      .then((response) => {
        dispatch(setPickUpAddress(response.data.p_address));
        dispatch(setAlert("Address added.", "success", getStringHash()));
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data?.message
          : "An error occurred.";
        console.error(error.response);
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

// Update Pick up address
export const updatePickUpAddress = (token, user, extraParams = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_PICKUP_ADDRESS_START });
    const addressId = getState().orders.pickUpAddress.id;
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      addressId,
      ...extraParams,
    };
    PepperestAxios.post(Orders.UPDATE_PICK_UP_ADDRESS, params, { headers })
      .then((response) => {
        dispatch(setPickUpAddress(response.data.p_address));
        dispatch(
          setAlert("Address updated successfully.", "success", getStringHash())
        );
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data?.message
          : "An error occurred.";
        console.error(error.response);
        dispatch({ type: actionTypes.ADD_PICKUP_ADDRESS_FAIL });
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

export const deleteAddress = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Orders.REMOVE_ADDRESS, body, { headers })
      .then((response) => {
        dispatch(setAddress(response.data.addresses));
        dispatch(setAlert("Address deleted.", "success", getStringHash()));
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data?.message
          : "An error occurred.";
        console.error(error.response);
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

export const editAddress = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_PICK_UP_ADDRESS });
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      name: user.name,
      ...extraParams,
    };
    PepperestAxios.post(Orders.UPDATE_ADDRESS, body, { headers })
      .then((response) => {
        dispatch(setAddress(response.data.addresses));
        dispatch(setAlert("Address updated.", "success", getStringHash()));
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data?.message
          : "An error occurred.";
        console.error(error.response);
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

export const setAddress = (addresses) => {
  return {
    type: actionTypes.LOAD_ADDRESS,
    addresses,
  };
};
export const setPickUpAddress = (address) => {
  return {
    type: actionTypes.ADD_PICK_UP_ADDRESS,
    address,
  };
};
export const setReferralStats = (stats) => {
  return {
    type: actionTypes.SET_REFERRAL_STATS,
    stats,
  };
};
export const setOrderStatus = (order) => {
  return {
    type: actionTypes.CHANGE_ORDER_STATUS,
    order,
  };
};

const setCourierService = (rates) => {
  return {
    type: actionTypes.SET_COURIER_SERVICE,
    rates,
  };
};

const redirect = (url) => {
  return {
    type: actionTypes.ORDER_REDIRECT,
    redirectUrl: url,
  };
};
const paymentGateway = (provider) => {
  return {
    type: actionTypes.ORDER_PAYMENT_PROVIDER,
    paymentGateway: provider,
  };
};

const cartOrder = (order) => {
  return {
    type: actionTypes.CART_ORDER,
    cartOrder: order,
  };
};

const confirmLoading = (value) => {
  return {
    type: actionTypes.CONFIRM_LOADING,
    loading: value,
  };
};
// const confirmThirdPartyLoading = (value) => {
//   return {
//     type: actionTypes.CONFIRM_THIRDPARTY_LOADING,
//     loading: value,
//   };
// };

const addressError = () => {
  return {
    type: actionTypes.LOAD_ADDRESS_ERROR,
  };
};

export const loadingOrders = () => {
  return {
    type: actionTypes.LOADING_ORDERS,
  };
};

export const loadedOrdersDashboard = (
  orders,
  total,
  canceled,
  pending,
  shipped,
  today_orders,
  yesterday_orders,
  pageType,
  totalRevenue
) => {
  const pageTypeUpdate = {
    orders,
    total: total,
    canceled: canceled,
    pending: pending,
    shipped: shipped,
    today_orders: today_orders,
    yesterday_orders: yesterday_orders,
    loading: false,
    loaded: true,
    error: null,
    totalRevenue,
  };
  return {
    type: actionTypes.FINISHED_LOADING_ORDERS,
    pageType: pageType,
    pageTypeUpdate: pageTypeUpdate,
  };
};
export const loadedOrders = (orders, meta, links) => {
  const pageTypeUpdate = {
    orders,
    meta,
    links,
    loading: false,
    loaded: true,
    error: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_ORDERS,
    pageTypeUpdate: pageTypeUpdate,
  };
};
export const failedToLoadOrders = (pageType, error) => {
  return {
    type: actionTypes.LOADING_ORDERS_FAILED,
    pageType: pageType,
    error: error,
  };
};
