import React from "react";
import { Modal } from "react-bootstrap";


const ModalComponent = ({ children, ...otherProps }) => {
  return (
    <Modal {...otherProps}>
      {children}
    </Modal>
  );
};

export default ModalComponent;
