import React from "react";

import {
  ProductBox,
  productBoxContents,
  ProductMainSection,
} from "components/newDashboardComponents/Products/ProductMainSection";
import Footer from "components/newDashboardComponents/Footer/Footer";
import SectionBeforeFooter from "components/newDashboardComponents/SectionBeforeFooter";
import NavBar from "components/landingPageComponent/NavBar";
import CallToAction from "components/landingPageComponent/CallToAction";
import AppleStoreButton from "components/vectors/AppleStoreButton";
import PlayStoreButton from "components/vectors/PlayStoreButton";
import PlayStore from "components/vectors/PlayStore";
import MetaTag from "components/shared/MetaTag";

const index = () => {
  return (
    <div className="newDashboard landing__page">
      <MetaTag
        title="Peppa App: Shop with Confidence & Protect Your Online Purchases"
        description="Discover Peppa App – your ultimate online shopping assistant! Safeguard your purchases, with payment protection service, and manage your business seamlessly. Download now and redefine secure online shopping."
        keywords="product, online store, description"
        route="products"
      />
      <div className="products__page">
        <div
          className="mb-5"
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1,
            top: "0",
            left: "0",
          }}
        >
          <NavBar />
        </div>
        <section className="mb-5 row pageTitle">
          <header className="text-justify mb-2 mt-5 mx-auto col-md-7 products__header">
            <h1 className=" mb-2 products__header__title">Products</h1>
            <p className="products__header__text mt-2">
              Everything you need to set up, manage and grow your business
              online.
            </p>
          </header>

          {/* <main className="products__box__container  mt-5 mx-auto py-3 px-0 row w-100 container">
            {productBoxContents.map(({ text, icon, link }, index) => (
              <ProductBox text={text} key={index} icon={icon} link={link} />
            ))}
          </main> */}
        </section>
        <ProductMainSection
          title="peppa app"
          btnText={
            <div className="app__download__container">
              <a
                href="https://play.google.com/store/apps/details?id=com.paysure.peppa"
                target="_blank"
              >
                <PlayStore />
              </a>
              <a
                href="https://apps.apple.com/app/peppa/id6450179414"
                target="_blank"
              >
                <AppleStoreButton />
              </a>
            </div>
          }
          id="peppa app"
          target={true}
          routeTo="#"
          imgLink="/assets/images/peppa-app-image.png"
          mainText={`Buy anything from any online vendor without the fear of losing your money. Get your money back if you buy from a scam account. Download the app now and start shopping online with confidence.`}
        />
        <ProductMainSection
          title="Payment Protection Service"
          reverse
          id="escrow"
          target={false}
          routeTo="/escrow"
          imgLink="/assets/images/escrow-hands.png"
          mainText="peppa.io provides payment protection service to protect merchants and their customers by acting as a safe deposit to collect and hold payment from customers and then release payments to merchants when the customers confirm that the products they have received is satisfactory. The payment protection service ensures that buyers and merchants keep to their ends of agreements and helps to boost trust and Increase sales."
        />

        <ProductMainSection
          id="storefront"
          title="Online Storefront"
          btnText="Start Selling"
          routeTo="/register"
          imgLink="/assets/images/store-front.png"
          target={false}
        />

        {/* <ProductMainSection
          title="Foreign Payment Processing"
          id="payment"
          routeTo="/register"
          imgLink="/assets/images/big-creative-blue.png"
          mainText="With Pepperest, customers from anywhere in the world can quickly pay Merchants with online card payments. Receiving payments for products has never been easier"
        /> */}
        {/* <ProductMainSection
          title="Logistics & Delivery"
          reverse
          id="logistic"
          routeTo="/register"
          imgLink="/assets/images/big-creative-lightgreen.png"
          mainText="Pepperest provides tools to allow Merchants easily deliver goods to anywhere in the world.  Merchants can develop fast deliveries, monitor order progress and easily process returns with the delivery tool on Pepperest."
        /> */}
        {/* <ProductMainSection
          id="options"
          title="Multiple Payment Options"
          imgLink="/assets/images/big-creative-purple.png"
          routeTo="/register"
          mainText="Pepperest allows merchants easily receive payments from customers through online card payments, bank transfers or USSD."
        /> */}
        <CallToAction />
        <Footer />
      </div>
    </div>
  );
};

export default index;
