import OverviewCard from "components/dashboardComponent/OverviewCard";
import SaleGraph from "components/dashboardComponent/SaleGraph";
import WelcomeHeader from "components/dashboardComponent/WelcomeHeader";
import { LoaderProcessing } from "components/shared";
import User from "components/vectors/User";
import { PepperestWebServices } from "libs/constants";
import { currencyFormatter, handleDownload } from "libs/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  downloadSalesReport,
  downloadSalesReportComplete,
  getDashboardMetrics,
  getRecentOrders,
  getSalesReport,
  getTopCustomers,
} from "store/actions/dashboard";
import Settings from "../Settings";

const ConnectToFacebookStore = "EscrowBackend/auth/facebook";

const Overview = () => {
  const [salesGraphMode, setSalesGraphMode] = useState("week");
  const history = useHistory();
  const { userProfile, loading: loadingProfile } = useSelector(
    (state) => state.userAccount
  );
  const dispatch = useDispatch();
  const {
    overview,
    loading,
    loadingReports,
    error,
    topCustomers,
    recentOrders,
    salesReports,
    download_report_url,
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardMetrics());
    dispatch(getTopCustomers());
    dispatch(getRecentOrders());
    dispatch(getSalesReport());
  }, []);

  const handleSalesGraphMode = (mode) => {
    setSalesGraphMode(mode);
  };
  const loginWithFacebook = () => {
    const windowOptions = "width=600,height=400,left=100,top=100";
    window.open(
      `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`,
      "Share",
      windowOptions
    );
    // window.location.href = `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`;
  };

  const downloadReport = () => {
    dispatch(
      downloadSalesReport({
        type: "All",
        period: salesGraphMode,
      })
    );
  };

  useEffect(() => {
    if (download_report_url) {
      handleDownload(`${salesGraphMode}_sales_report`, download_report_url);
      dispatch(downloadSalesReportComplete());
    }
  }, [download_report_url]);

  return (
    <main className="overview">
      {loading || loadingReports ? (
        <div className="loader__container">
          <LoaderProcessing />
        </div>
      ) : error ? (
        <div className="empty__state">
          <p>{error}</p>
        </div>
      ) : (
        <>
          {!userProfile?.isPremium ||
          // !userProfile?.bankName ||
          !userProfile?.email_verified ? (
            <Settings />
          ) : (
            <>
              <WelcomeHeader handleClick={loginWithFacebook} />
              <div className="overview__metrics">
                <OverviewCard
                  title="Total products"
                  type="products"
                  itemCount={overview?.total_products}
                  icon="product-box.svg"
                />
                <OverviewCard
                  title="Total Orders"
                  type="orders"
                  itemCount={overview?.total_orders}
                  icon="truck-tick.svg"
                />
                <OverviewCard
                  title="Total customers"
                  type="customers"
                  itemCount={overview?.total_customers}
                  icon="user-box.svg"
                />
                {userProfile?.isPremium ? (
                  <OverviewCard
                    title="Total Store Visit"
                    type="visits"
                    itemCount={overview?.total_visits}
                    icon="ion_stats-chart.svg"
                  />
                ) : null}

                <OverviewCard
                  title="Total revenues"
                  type="revenues"
                  itemCount={overview?.total_revenue}
                  icon="dollar-circle.svg"
                />
              </div>
              <section className="report__wrapper">
                <div className="sales__report">
                  <div className="report__header">
                    <p className="title">Sales</p>
                    <div className="report__control">
                      <div className="control">
                        <p
                          className={`control__label ${
                            salesGraphMode == "week" ? "active__control" : ""
                          } `}
                          onClick={() => handleSalesGraphMode("week")}
                        >
                          This week
                        </p>
                      </div>
                      <div className="control">
                        <p
                          className={`control__label ${
                            salesGraphMode == "month" ? "active__control" : ""
                          } `}
                          onClick={() => handleSalesGraphMode("month")}
                        >
                          This month
                        </p>
                      </div>

                      {userProfile?.isPremium ? (
                        <div className="control">
                          <button
                            className="button__download__report"
                            onClick={downloadReport}
                          >
                            Download CSV
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <SaleGraph
                    salesGraphMode={salesGraphMode}
                    data={salesReports}
                  />
                </div>
                <div className="top__customers">
                  <div className="customer__header">
                    <p className="title">Top Customer</p>
                  </div>

                  {topCustomers.length > 0 ? (
                    <>
                      {/* <div className='customer__list__header'>
                            <p>Name</p>
                        
                        </div> */}
                      {topCustomers.map((customer, index) => (
                        <div className="customer__list__item" key={index}>
                          {customer?.profile_img ? (
                            <img src={customer?.profile_img} />
                          ) : (
                            <div className="user__icon">
                              <User />
                            </div>
                          )}

                          <div>
                            <p className="name">{customer?.name}</p>
                            <p>{customer?.email}</p>
                          </div>
                        </div>
                      ))}
                      <p className="text-center text__primary mt-4">See All</p>
                    </>
                  ) : (
                    <div className="customers">
                      <p>No customers</p>
                    </div>
                  )}
                </div>
              </section>
              <div className="recent__orders">
                <p className="title">Recent Orders</p>
                <div className="table__wrapper">
                  <table className="dashboard__table product__table table__large">
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Status</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {recentOrders.length > 0 ? (
                        recentOrders?.map((order, index) => (
                          <tr key={index}>
                            <td>{order?.customerName}</td>
                            <td>
                              {order?.orderItems.map((item, index) => (
                                <span key={index}>{item?.productname}, </span>
                              ))}
                            </td>
                            <td>{currencyFormatter.format(order?.cost)}</td>
                            <td>{order?.orderItems?.length}</td>
                            <td width={150}>
                              <span
                                className={`order__status status__${order?.status?.toLowerCase()} d-block`}
                              >
                                {order?.status}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            Recent orders will appear here
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </main>
  );
};

export default Overview;
