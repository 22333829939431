import React from "react";

function CircularDollarIcon({ size = 21 }) {
  return (
    <svg width={size} 
    height={size+3} 
    viewBox="0 0 16 14" 
    fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9727 3.64582H11.766C11.6552 3.48832 11.5385 3.34249 11.416 3.19666L10.9727 3.64582Z" fill="#FE8F01"/>
<path d="M10.8032 2.57827C10.6573 2.45577 10.5115 2.3391 10.354 2.22827V3.0216L10.8032 2.57827Z" fill="#FE8F01"/>
<path d="M11.4216 3.19663L13.1424 1.4758C13.3116 1.30663 13.3116 1.02663 13.1424 0.857466C12.9732 0.688299 12.6932 0.688299 12.5241 0.857466L10.8032 2.5783C11.0249 2.7708 11.2291 2.9808 11.4216 3.19663Z" fill="#FE8F01"/>
<path d="M10.354 1.75C10.354 1.51083 10.1557 1.3125 9.91653 1.3125C9.6832 1.3125 9.49653 1.49917 9.48486 1.72667C9.7882 1.8725 10.0799 2.03583 10.354 2.22833V1.75Z" fill="#FE8F01"/>
<path d="M12.6873 4.08337C12.6873 3.84421 12.489 3.64587 12.2498 3.64587H11.7656C11.9581 3.92004 12.1273 4.21171 12.2673 4.51504C12.5006 4.50337 12.6873 4.31671 12.6873 4.08337Z" fill="#FE8F01"/>
<path d="M7.4375 8.60422H7.6125C7.84 8.60422 8.02083 8.40005 8.02083 8.14922C8.02083 7.83422 7.93333 7.78755 7.735 7.71755L7.4375 7.61255V8.60422Z" fill="#FE8F01"/>
<path d="M12.2732 4.51496C12.2673 4.51496 12.2615 4.52079 12.2498 4.52079H9.9165C9.85817 4.52079 9.80567 4.50913 9.74734 4.48579C9.64234 4.43913 9.55484 4.35746 9.50817 4.24663C9.49067 4.19413 9.479 4.14163 9.479 4.08329V1.74996C9.479 1.74413 9.48484 1.73829 9.48484 1.72663C8.7265 1.37079 7.8865 1.16663 6.99984 1.16663C3.77984 1.16663 1.1665 3.77996 1.1665 6.99996C1.1665 10.22 3.77984 12.8333 6.99984 12.8333C10.2198 12.8333 12.8332 10.22 12.8332 6.99996C12.8332 6.11329 12.629 5.27329 12.2732 4.51496ZM8.02067 6.89496C8.394 7.02329 8.89567 7.29746 8.89567 8.15496C8.89567 8.88413 8.31817 9.48496 7.61234 9.48496H7.43734V9.63079C7.43734 9.86996 7.239 10.0683 6.99984 10.0683C6.76067 10.0683 6.56234 9.86996 6.56234 9.63079V9.48496H6.51567C5.73984 9.48496 5.104 8.83163 5.104 8.02663C5.104 7.78163 5.30234 7.58329 5.5415 7.58329C5.78067 7.58329 5.979 7.78163 5.979 8.02079C5.979 8.34163 6.21817 8.60413 6.51567 8.60413H6.56234V7.30913L5.979 7.10496C5.60567 6.97663 5.104 6.70246 5.104 5.84496C5.104 5.11579 5.6815 4.51496 6.38734 4.51496H6.56234V4.37496C6.56234 4.13579 6.76067 3.93746 6.99984 3.93746C7.239 3.93746 7.43734 4.13579 7.43734 4.37496V4.52079H7.484C8.25984 4.52079 8.89567 5.17413 8.89567 5.97913C8.89567 6.21829 8.69734 6.41663 8.45817 6.41663C8.219 6.41663 8.02067 6.21829 8.02067 5.97913C8.02067 5.65829 7.7815 5.39579 7.484 5.39579H7.43734V6.69079L8.02067 6.89496Z" fill="#FE8F01"/>
<path d="M5.979 5.85087C5.979 6.16587 6.0665 6.21254 6.26484 6.28254L6.56234 6.38754V5.39587H6.38734C6.16567 5.39587 5.979 5.60004 5.979 5.85087Z" fill="#FE8F01"/>
<path d="M15.5107 2.96072C15.4641 2.85572 15.3824 2.76822 15.2716 2.72155C15.2191 2.70405 15.1666 2.69238 15.1082 2.69238H12.7749C12.5357 2.69238 12.3374 2.89072 12.3374 3.12988C12.3374 3.36905 12.5357 3.56738 12.7749 3.56738H14.0524L13.0782 4.54155C13.2999 4.73405 13.5041 4.93822 13.6966 5.15988L14.6707 4.18572V5.46322C14.6707 5.70238 14.8691 5.90072 15.1082 5.90072C15.3474 5.90072 15.5457 5.70238 15.5457 5.46322V3.12988C15.5457 3.07155 15.5341 3.01905 15.5107 2.96072Z" fill="#FE8F01"/>
<path d="M13.2378 4.38232L13.8544 5.00955L12.507 6.33407C12.3338 6.50433 12.0554 6.50195 11.8851 6.32875C11.7148 6.15554 11.7172 5.8771 11.8904 5.70684L13.2378 4.38232Z" fill="white"/>
</svg>

  );
}

export default CircularDollarIcon;
