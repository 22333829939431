import { CheckedIcon } from "components/vectors";
import { premiumPackage } from "libs/constants";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const PremiumPlan = () => {
  const history = useHistory();
  const { userProfile } = useSelector((state) => state.userAccount);
  const skip = () => {
    history.push("/dashboard/complete-setup");
  };
  const startTrial = () => {
    history.push("/dashboard/upgrade-plan");
  };
  const next = () => {
    history.push("/dashboard/home");
  };
  return (
    <main>
      {userProfile?.isPremium ? (
        <>
          <p className="title text-center">Premium</p>
          <p className="description">
            You have subscried to peppa.io premium plan
          </p>

          <div className="d-flex justify-content-center">
            <button
              className="button button--purple button__content px-5"
              onClick={next}
            >
              Done
            </button>
          </div>
        </>
      ) : (
        <div className="premium__plan__wrapper ">
          <div className="plan__header">
            <p className="plan__title">Premium</p>
            <p className="pricing">
              <span className="amount">N2000</span>
              <small className="term">per month</small>
            </p>
          </div>
          <div className="plan__content">
            {premiumPackage.map((item, index) => (
              <li className="plan__item" key={index}>
                <CheckedIcon color="#5E60CE" width="16" height="16" />
                <p className="plan__text">{item}</p>
              </li>
            ))}
          </div>
          <button className="button button--purple" onClick={startTrial}>
            Proceed
          </button>
          <p className="skip" onClick={skip}>
            Skip for now
          </p>
        </div>
      )}
    </main>
  );
};

export default PremiumPlan;
