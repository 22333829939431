export default {
    PRODUCT_CATEGORIES : '/EscrowBackend/api/marketplace/getCategories',
    STORE_BY_CATEGORY : "/EscrowBackend/api//marketplace/getStoresByCategory",
    STORES : "/EscrowBackend/api/marketplace/getAllStores",
    TOP_SELLING : "/EscrowBackend/api/marketplace/getTopSellingCategories",
    DEALS : "/EscrowBackend/api/marketplace/getTodayDeal",
    FEATURED_STORES : "/EscrowBackend/api/marketplace/getFeaturedStores",
    SEARCH : "/EscrowBackend/api/marketplace/search",
    SEARCH_IN_STORE : "/EscrowBackend/api/marketplace/searchInStore",
    CATEGORIZE_STORE : "/EscrowBackend/api/marketplace/stores/categorize",
    PRE_CHECKOUT : "/EscrowBackend/api/order/preCheckout",
    PRODUCT_BY_CATEGORY : "/EscrowBackend/api/marketplace/getProductsByCategory",
    
}

export const ErrorMessages =
{
    getMarketplaceFailed : "We are currently unable to get products",
}
