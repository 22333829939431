export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const setStateInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getStateFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  return value;
};

export const removeStateFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "NGN",
  style: "currency",
  minimumFractionDigits: 0,
  signDisplay: "auto",
  currencyDisplay: "symbol",
});

