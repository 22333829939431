import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { STATUS as PaymentStatus } from "libs/constants/PepperestWebServices/Payments";

let initialState = {
  vendorPayment: {
    loading: true,
  },
  error: null,
  loadingPayments: false,
};
// Object.values(PaymentStatus).forEach((status) => {
//   initialState[status] = {
//     payments: [],
//     meta: null,
//     links: null,
//     loading: false,
//     loaded: false,
//     error: null,
//   };
// });

const loading = (state, action) => {
  const updatedPaymentLoadingState = updateObject(state[action.status], {
    loading: true,
  });
  let update = {};
  update[action.status] = updatedPaymentLoadingState;
  return updateObject(state, update);
};

const loadedPayment = (state, action) => {
  let update = {};
  update[action.status] = action.statusUpdate;
  return updateObject(state, update);
};

const failedToLoadPayment = (state, action) => {
  const errorChanges = {
    loading: false,
    loaded: false,
    error: action.error,
  };
  const updatedPaymentLoadingState = updateObject(
    state[action.status],
    errorChanges
  );
  let update = {};
  update[action.status] = updatedPaymentLoadingState;
  return updateObject(state, update);
};

const getPayments = (state, action) => {
  let update = {
    loadingPayments: true,
    error: null,
  };
  return updateObject(state, update);
};

const getPaymentsSuccess = (state, action) => {
  let update = {
    allPayments: {
      data: action.payments,
      meta: action.meta,
    },
    loadingPayments: false,
  };
  return updateObject(state, update);
};

const getPaymentsFailed = (state, action) => {
  let update = {
    allPayments: {
      data: [],
      meta: null,
    },
    error: action.error,
    loadingPayments: false,
  };
  return updateObject(state, update);
};

const loadingPayment = (state, action) => {
  const update = {
    error: null,
    loading: true,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};
const getIncomingPaymentSuccess = (state, action) => {
  const update = {
    error: null,
    order: action.order,
    userInfo: action.userInfo,
    loading: false,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};

const getIncomingPaymentFail = (state, action) => {
  const update = {
    error: action.error,
    loading: false,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};

const trackIncomingPaymentSuccess = (state, action) => {
  const update = {
    error: null,
    orderEvents: action.orderEvents,
    releaseCode: action.releaseCode,
    order:action.order,
    loading: false,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};

const trackIncomingPaymentFail = (state, action) => {
  const update = {
    error: action.error,
    loading: false,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};

const declineIncomingPayment = (state, action) => {
  console.log({ state });
  const update = {
    decliningPayment: true,
    ...state.vendorPayment,
    loading: false,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};

const declineIncomingPaymentSuccess = (state, action) => {
  const update = {
    decliningPayment: false,
    declined: true,
    ...state.vendorPayment,
    loading: false,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};

const declineIncomingPaymentFail = (state, action) => {
  const update = {
    decliningPayment: false,
    declined: false,
    declineError: action.error,
    ...state.vendorPayment,
    loading: false,
  };
  return updateObject(state, {
    vendorPayment: update,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_PAYMENTS:
      return loading(state, action);
    case actionTypes.FINISHED_LOADING_PAYMENTS:
      return loadedPayment(state, action);
    case actionTypes.LOADING_PAYMENTS_FAILED:
      return failedToLoadPayment(state, action);
    case actionTypes.GET_INCOMING_PAYMENT:
      return loadingPayment(state, action);
    case actionTypes.GET_INCOMING_PAYMENT_SUCCESS:
      return getIncomingPaymentSuccess(state, action);
    case actionTypes.GET_INCOMING_PAYMENT_FAIL:
      return getIncomingPaymentFail(state, action);
    case actionTypes.TRACK_INCOMING_PAYMENT:
      return loadingPayment(state, action);
    case actionTypes.TRACK_INCOMING_PAYMENT_SUCCESS:
      return trackIncomingPaymentSuccess(state, action);
    case actionTypes.TRACK_INCOMING_PAYMENT_FAIL:
      return trackIncomingPaymentFail(state, action);
    case actionTypes.GET_PAYMENTS:
      return getPayments(state, action);
    case actionTypes.GET_PAYMENTS_SUCCESS:
      return getPaymentsSuccess(state, action);
    case actionTypes.GET_PAYMENTS_FAILED:
      return getPaymentsFailed(state, action);
    case actionTypes.GET_STORE_PAYMENTS:
      return getPayments(state, action);
    case actionTypes.GET_STORE_PAYMENTS_SUCCESS:
      return getPaymentsSuccess(state, action);
    case actionTypes.GET_STORE_PAYMENTS_FAILED:
      return getPaymentsFailed(state, action);
    case actionTypes.DECLINE_INCOMING_PAYMENT:
      return declineIncomingPayment(state, action);
    case actionTypes.DECLINE_INCOMING_PAYMENT_SUCCESS:
      return declineIncomingPaymentSuccess(state, action);
    case actionTypes.DECLINE_INCOMING_PAYMENT_FAIL:
      return declineIncomingPaymentFail(state, action);
    default:
      return state;
  }
};

export default reducer;
