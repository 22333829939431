import AppleStoreButton from "components/vectors/AppleStoreButton";
import PlayStore from "components/vectors/PlayStore";
import React from "react";
import PhoneDown from "../../../assets/images/cta-image-down.png";
import PhoneUp from "../../../assets/images/cta-image-up.png";

function CallToAction() {
  return (
    <div className="cta__wrapper">
      <div className="cta__banner ">
        <div className="cta">
          <section className="cta__button__wrapper">
            <p className="title">
              <span className="first__line">Download the peppa</span> app to get
              started!
            </p>
            <div className="app__download__container">
              <a href="https://t.peppa.io/DaST/website" target="_blank">
                <PlayStore />
              </a>
              <a href="https://t.peppa.io/DaST/website" target="_blank">
                <AppleStoreButton />
              </a>
            </div>
          </section>
          <section className="cta__image__wrapper">
            <div>
              <img src={PhoneDown} />
            </div>
            <div className="phone__up">
              <img src={PhoneUp} />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
