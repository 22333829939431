import PepperestAxios from "../../libs/utils/PepperestAxios";
import { Marketplace, Products, ProductsErrorMessages } from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { setAlert } from "./alert";
import { getStringHash } from "libs/utils";

export const getMerchantStoreProducts = (merchantCode, page = 1) => {
  return (dispatch) => {
    dispatch(loadingMerchantPage());
    const params = { merchantCode };
    PepperestAxios.get(`${Products.MERCHANT_STORE}?page=${page}`, { params })
      .then((response) => {
        const products = response.data.products.data;
        const meta = response.data.products.meta;
        const links = response.data.products.links;
        const merchantID = response.data;
        dispatch(loadedProduct(products, meta, links, merchantID));
      })
      .catch((error) =>{
        console.log({error});
        dispatch( failedToLoadMerchantPage( ProductsErrorMessages.getMerchantFailed ) )
        // dispatch(setAlert(ProductsErrorMessages.getMerchantFailed, 'error', getStringHash()))
        console.log(ProductsErrorMessages.getMerchantFailed)
  });
  };
};

export const getMerchantStoreProductsByCategory = (category_id, merchantCode='', page = 1) => {
  return (dispatch) => {
    dispatch(loadingMerchantPage());
    const params = { merchant_code:merchantCode,category_id };
    PepperestAxios.get(`${Marketplace.PRODUCT_BY_CATEGORY}?page=${page}`, { params })
      .then((response) => {
        const {data,meta,links} = response.data.products;
        
        //const merchantID = response.data;
        dispatch(loadedProduct(data, meta, links));
      })
      .catch((error) =>{
        console.log({error});
        dispatch( failedToLoadMerchantPage( ProductsErrorMessages.getMerchantFailed ) )
        // dispatch(setAlert(ProductsErrorMessages.getMerchantFailed, 'error', getStringHash()))
        console.log(ProductsErrorMessages.getMerchantFailed)
  });
  };
};

export const createInvoice =
  (token, user, extraParams = {}) =>
  (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      merchant_id: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Products.CREATE_INVOICE, body, { headers })
      .then((response) => {
        console.log(response);
        dispatch(setAlert("Operation Successful", "success"));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(setAlert("An error occurred", "error"));
      });
  };

export const loadingMerchantPage = () => {
  return {
    type: actionTypes.LOADING_MERCHANT_PAGE,
  };
};

export const failedToLoadMerchantPage = (error) => {
  return {
    type: actionTypes.FAILED_LOADING_MERCHANT_PAGE,
    error: error,
  };
};

export const loadedProduct = (products, meta, links, merchantID) => {
  const update = {
    products,
    meta,
    links,
    loading: false,
    loaded: true,
    error: null,
    merchantID,
  };
  return {
    type: actionTypes.LOADED_MERCHANT_PAGE,
    update: update,
  };
};

export const getProductDetail = (productID) => {
  return (dispatch) => {
    dispatch(loadProductDetail())
    const headers = {
      "Content-Type":"application/json"
     
    };
    const params = {
      productID: productID,
    };

    PepperestAxios.get(Products.GET_SINGLE_PRODUCT, { headers, params })
      .then((response) => {
        const product = response.data.product;
        dispatch(loadProductDetailSuccess(product));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(loadProductDetailFail(error.response))
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const loadProductDetail = () => {
  return {
    type: actionTypes.GET_PRODUCT_DETAIL,
  };
};

export const loadProductDetailFail = (error) => {
  return {
    type: actionTypes.GET_PRODUCT_DETAIL_FAIL,
    error: error,
  };
};

export const loadProductDetailSuccess = (product) => {
  const update = {
    product,
    loading: false,
    error: null,
  };
  return {
    type: actionTypes.GET_PRODUCT_DETAIL_SUCCESS,
    update: update,
  };
};