import React from 'react'

const ViewIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.75 8.25004L15.9 2.10004" stroke="#5E60CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.5004 5.1V1.5H12.9004" stroke="#5E60CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75" stroke="#5E60CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default ViewIcon