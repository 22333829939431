export default {
  CONFIRM_INVOICE: "/EscrowBackend/api/invoice/changeInvoiceStatus",
  INVOICE: "/EscrowBackend/api/invoice/getInvoiceDetail",
  INVOICES: "/EscrowBackend/api/invoice/getMyInvoice",
  PLACE_INVOICE: "/EscrowBackend/api/invoice/placeInvoicePayment",
  CONFIRM_INVOICE_PAYMENT: "/EscrowBackend/api/invoice/handleCallback",
  CREATE_INVOICE: "/EscrowBackend/api/invoicing/createInvoice",
  NEW_INVOICES: "/EscrowBackend/api/invoicing/getInvoices",
  NEW_SINGLE_INVOICE: "/EscrowBackend/api/invoicing/getInvoice",
  CHANGE_INVOICE_STATUS: "/EscrowBackend/api/invoicing/changeInvoiceStatus",
  UPDATE_INVOICE: "/EscrowBackend/api/invoicing/updateInvoice",
  INVOICE_BY_REF: "/EscrowBackend/api/invoicing/getInvoiceByRef",
  VERIFY_TRANSFER: "/EscrowBackend/api/invoicing/verifyInvoicePayment",
  VERIFY_INVOICE_PAYMENT_REF:
    "/EscrowBackend/api/invoicing/verifyInvoicePaymentCard",
  UPLOAD_INVOICE_PROOF: "/EscrowBackend/api/invoicing/uploadProof",
  REQUEST_PAYMENT: "/EscrowBackend/api/invoicing/requestPayment",
  DELETE_INVOICE: "/EscrowBackend/api/invoicing/deleteInvoice",
};

export const PAGE_TYPES = {
  DASHBOARD: "Dashboard",
  ALL: "All",
  CONFIRMED: "Confirmed",
  PENDING: "Pending",
  CANCELED: "Canceled",
  PAID: "Paid",
};

export const ErrorMessages = {
  getHistoryFailed: "We are currently unable to get your invoice data",
};
