import React from "react";
import { Link, useParams } from "react-router-dom";
import { getFromStorage } from "../../buyer-constants";
import { getMerchant } from "../../buyer-api";
import styles from "./header.module.css";
import { useQuery } from "@tanstack/react-query";

const nonRoutes = [
	"/confirmation",
	"/account",
	"/cart",
	"/payment",
	"/tracker",
];

const Header = () => {
	const { merchantID } = useParams();

	const storeID = merchantID || getFromStorage("storeID");
	const businessname = getFromStorage("businessname");

	// const { data } = useQuery({
	// 	queryKey: ["mechart", storeID],
	// 	queryFn: () => getMerchant({ id: storeID }),
	// });

	return (
		<div className={styles.bb}>
			<div className="container">
				<div className={`d-flex justify-content-between align-items-center`}>
					<Link to={`/store/${storeID}`}>
						<img
							className={styles.headerLogo}
							src={`https://ui-avatars.com/api/?name=${businessname}`}
							alt="business"
						/>
					</Link>
					<h2 className={`${styles.storeName}`}>{businessname}</h2>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default Header;
