import React from "react";

const EditIcon = ({ color = "white", width = "22", height = "23" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1554 3.79963L4.62956 11.7655C4.34539 12.068 4.07039 12.6638 4.01539 13.0763L3.67623 16.0463C3.55706 17.1188 4.32706 17.8521 5.39039 17.6688L8.34206 17.1646C8.75456 17.0913 9.33206 16.7888 9.61623 16.4771L17.1421 8.5113C18.4437 7.1363 19.0304 5.5688 17.0046 3.65296C14.9879 1.75546 13.4571 2.42463 12.1554 3.79963Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8994 5.12891C11.2936 7.65891 13.3469 9.59307 15.8952 9.84974"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 20.666H19.25"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
