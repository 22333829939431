import React from 'react'

const SearchIcon = ({color='white',width=22,height=22})=>(
    <svg width={width} 
    height={height} 
    viewBox="0 0 22 22" 
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5418 19.2487C15.3513 19.2487 19.2502 15.3498 
    19.2502 10.5404C19.2502 5.73088 15.3513 1.83203 10.5418 1.83203C5.73235 
    1.83203 1.8335 5.73088 1.8335 10.5404C1.8335 15.3498 5.73235 19.2487 10.5418 
    19.2487Z" stroke={color?color:'white'} strokeWidth="1.5" 
    strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.1668 20.1654L18.3335 18.332" 
    stroke={color} strokeWidth="1.5" strokeLinecap="round" 
    strokeLinejoin="round"/>
    </svg>

)
export default SearchIcon;