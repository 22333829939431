import React from "react";

const ShopIcon = ({ size = 22 }) => {
  return (
    <svg width={size} height={size+2} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9863 4.65115L13.7912 2.81587C13.5086 0.814946 12.5869 0 10.6158 0H9.02803H8.03234H5.98714H4.99146H3.37682C1.3989 0 0.483944 0.814946 0.194655 2.83575L0.0130095 4.65778C-0.0542668 5.36671 0.140835 6.05577 0.564675 6.59245C1.07597 7.24838 1.86311 7.61941 2.7377 7.61941C3.58538 7.61941 4.39942 7.202 4.91072 6.53282C5.3682 7.202 6.14861 7.61941 7.01647 7.61941C7.88434 7.61941 8.64456 7.22188 9.10876 6.55932C9.62679 7.21525 10.4274 7.61941 11.2616 7.61941C12.1564 7.61941 12.9637 7.2285 13.4683 6.53944C13.8719 6.0094 14.0536 5.34021 13.9863 4.65115Z" fill="#FE8F01"/>
<path d="M6.57887 10.2102C5.72447 10.2964 5.07861 11.0119 5.07861 11.86V13.6754C5.07861 13.8543 5.22662 14.0001 5.40827 14.0001H8.61735C8.79899 14.0001 8.947 13.8543 8.947 13.6754V12.0919C8.95373 10.7072 8.12623 10.0512 6.57887 10.2102Z" fill="#FE8F01"/>
<path d="M13.32 8.71269V10.6871C13.32 12.5158 11.813 13.9999 9.95617 13.9999C9.77452 13.9999 9.62651 13.8541 9.62651 13.6752V12.0917C9.62651 11.2437 9.36413 10.5811 8.85283 10.1306C8.40208 9.7264 7.78987 9.52764 7.02965 9.52764C6.86146 9.52764 6.69326 9.53426 6.51162 9.55414C5.3141 9.6734 4.40587 10.6672 4.40587 11.8598V13.6752C4.40587 13.8541 4.25786 13.9999 4.07622 13.9999C2.21939 13.9999 0.712402 12.5158 0.712402 10.6871V8.72594C0.712402 8.26215 1.17661 7.95075 1.6139 8.10314C1.79555 8.16277 1.9772 8.20915 2.16557 8.23565C2.2463 8.2489 2.33376 8.26215 2.41449 8.26215C2.52213 8.2754 2.62978 8.28203 2.73742 8.28203C3.51782 8.28203 4.28477 7.99713 4.89026 7.50684C5.46884 7.99713 6.22233 8.28203 7.01619 8.28203C7.81678 8.28203 8.55682 8.01038 9.1354 7.52009C9.74088 8.00375 10.4944 8.28203 11.2613 8.28203C11.3824 8.28203 11.5035 8.2754 11.6179 8.26215C11.6986 8.25553 11.7726 8.2489 11.8466 8.23565C12.0552 8.20915 12.2436 8.14952 12.4319 8.08989C12.8692 7.94412 13.32 8.26215 13.32 8.71269Z" fill="#FE8F01"/>
</svg>

  );
};

export default ShopIcon;
