import React from "react";

const TrackCircle = () => (
  <svg
    maxwidth="100%"
    maxheight="100%"
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="26" cy="26" r="26" fill="#C4C4C4" />
    <circle cx="26" cy="26" r="11" fill="white" />
  </svg>
);

export default TrackCircle;
