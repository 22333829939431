import React, { useState, useEffect } from "react";
import { CloseIcon, SpinnerIcon } from "components/vectors";
import { InputWithoutLabel, SelectInputWithoutLabel, TextArea } from "components/blocks";
// import { PepperestContext } from "components/helpers/constant";
import EscapeCloseModalHelper from "components/helpers/EscapeCloseModalHelper";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import { useHistory } from "react-router-dom";
import { PepperestAxios } from "libs/utils";
// import { Products } from "../../../libs/constants/PepperestWebServices";
import { mixPanelUserProperty } from "libs/mixPanel";
import { PepperestWebServices } from "libs/constants";

const boxSizesEndPoint = `${PepperestWebServices.HOST}/EscrowBackend/api/product/packageBoxes`;
const productCategoryEndpoint = `${PepperestWebServices.HOST}/EscrowBackend/api/product/categories`;
const weightUnitsEndpoint = `${PepperestWebServices.HOST}/EscrowBackend/api/product/weightUnits`;

const BOXSIZE_PENDING = "BOXSIZE_PENDING";
const BOXSIZE_RESOLVED = "BOXSIZE_RESOLVED";
const BOXSIZE_REJECTED = "BOXSIZE_REJECTED";

const WEIGHT_UNIT_PENDING = "WEIGHT_UNIT_PENDING";
const WEIGHT_UNIT_RESOLVED = "WEIGHT_UNIT_RESOLVED";
const WEIGHT_UNIT_REJECTED = "WEIGHT_UNIT_REJECTED";

const ProductModal = (props) => {
  const {
    user,
    token,
    publishProduct,
    context,
    publishProductSuccess,
    publishSingleProductComplete,
    publishProductError,
    holdFormValuesInStore,
    publishingProduct,
  } = props;
  const history = useHistory();
  const [ProductName, setProductName] = useState("");
  const [discount, setDisoount] = useState(0);
  const [ProductDescription, setProductDescription] = useState("");
  const [ChangeAmount, setChangeAmount] = useState("");
  const [ChangeCurrency, setChangeCurrency] = useState("");
  const [DeliveryPeriod, setDeliveryPeriod] = useState("");
  const [Link, setLink] = useState(context.state.item);
  const [error, setError] = useState(false);
  const [image, setImage] = useState(null);
  const [height, setHeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [productType, setProductType] = useState("Tangible");
  const [productCategory, setProductCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedBoxSize, setSelectedBoxSize] = useState({});
  const [boxSize, setBoxSize] = useState([]);
  const [weightUnits, setWeightUnits] = useState([]);
  const [boxSizeLoading, setBoxSizeLoading] = useState(BOXSIZE_PENDING);
  const [weightUnitsLoading, setWeightUnitsLoading] = useState(WEIGHT_UNIT_PENDING);

  const handleChangeProductName = (e) => setProductName(e.target.value);
  const handleChangeDiscount = (e) => setDisoount(e.target.value);
  const handleChangeProductDescription = (e) => setProductDescription(e.target.value);
  const handleChangeAmount = (e) => setChangeAmount(e.target.value);
  const handleChangeCurrency = (e) => setChangeCurrency(e.target.value);
  const handleChangeDeliveryPeriod = (e) => {
    setDeliveryPeriod(e.target.value);
  };
  const handleChangeLink = (e) => setLink(e.target.value);
  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };
  const handleSetheight = (e) => setHeight(e.target.value);
  const handleSetLength = (e) => setLength(e.target.value);
  const handleSetWidth = (e) => setWidth(e.target.value);
  const handleSetWeight = (e) => setWeight(e.target.value);
  const handleSetWeightUnit = (e) => setWeightUnit(e.target.value);
  const handleProductType = (e) => {
    setProductType(e.target.value);
  };

  const handleProductCategorySelection = (e) => {
    setSelectedCategory(productCategory[e.target.value]);
  };
  const handleBoxSizeSelection = (e) => {
    setSelectedBoxSize(boxSize[e.target.value]);
  };
  const handleWeightUnitSelection = (e) => {
    setWeightUnit(e.target.value);
  };

  const handleSetQuantity = (e) => setQuantity(e.target.value);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const extraParams = {
      productname: ProductName,
      description: ProductDescription,
      price: ChangeAmount,
      currency: "NGN",
      deliveryperiod: DeliveryPeriod,
      product_image: image,
      box_size_id: Object.keys(selectedBoxSize).length
        ? selectedBoxSize?.box_size_id
        : boxSize[0]?.box_size_id,
      category_id: Object.keys(selectedCategory).length
        ? selectedCategory?.category_id
        : productCategory[0]?.category_id,
      height,
      length,
      width,
      weight,
      discount_percent:discount,
      weight_unit:weightUnit,
      productType,
      quantity,
    };

    if (!user) {
      history.push("/login");
      publishProduct(token, user, extraParams);
      holdFormValuesInStore(extraParams);
      history.push("/products/single");
    }
    publishProduct(token, user, extraParams);
    history.push("/products/single");
   
    
  };

  if(publishProductSuccess){
    context.updateShowProductModal(false);
    publishSingleProductComplete();
  }
  
  const fetchBoxSizes = async () => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    setBoxSizeLoading(BOXSIZE_PENDING);
    PepperestAxios.get(boxSizesEndPoint, {
      headers,
    })
      .then((res) => {
        const modifiedBoxSizeArray = res.data.boxes.slice(9).map((box, index) => ({
          ...box,
          label: box.name,
          value: index,
        }));
        setBoxSize(modifiedBoxSizeArray);
        setBoxSizeLoading(BOXSIZE_RESOLVED);
      })
      .catch((err) => {
        console.error(err, "Error while fetching box sizes");
        setBoxSizeLoading(BOXSIZE_REJECTED);
      });
  };

  const fetchWeightUnits = async () => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    setWeightUnitsLoading(WEIGHT_UNIT_PENDING);
    PepperestAxios.get(weightUnitsEndpoint, {
      headers,
    })
      .then((res) => {
        const units = res.data.units;
        const modifiedWeightUnitsArray = units.map((item,index)=>{
          let label = item;
          let value = item;
          return {label,value};
        })
        setWeightUnits(modifiedWeightUnitsArray);
        setBoxSizeLoading(WEIGHT_UNIT_RESOLVED);
      })
      .catch((err) => {
        console.error(err, "Error while fetching weight units");
        setBoxSizeLoading(WEIGHT_UNIT_REJECTED);
      });
  };


  const getProductCategories = () => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    PepperestAxios.get(productCategoryEndpoint, {
      headers,
    })
      .then((res) => {
        const modifiedCategoryList = res.data.categories.map((category, index) => ({
          ...category,
          value: index,
          label: category.category,
        }));
        setProductCategory(modifiedCategoryList);
      })
      .catch((err) => {
        console.error(err, "error while fetching category");
      });
  };

  useEffect(() => {
    fetchBoxSizes();
    getProductCategories();
    fetchWeightUnits();

  }, []);

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <div className="pModal">
          <div className="pModal-overlay" />
          <div className="pModal-content">
            <div className="pModal-header">
              <h6 className="text--small">Add Product</h6>
              <div
                onClick={() => {
                  context.updateShowProductModal(false);
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="pModal-main">
              {!user && (
                <div className="pModal-main__notification text--smallest">
                  Tell us more about the product you want to sell
                </div>
              )}
              <div className="pModal-form">
                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Product Name</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      name="product"
                      type="text"
                      placeholder=""
                      id="product"
                      value={ProductName}
                      onChange={handleChangeProductName}
                      className="nsForm-input__alternate"
                      errorMessage={error ? "Enter product name" : ""}
                    />
                  </div>
                </div>
                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Product Description</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <TextArea
                      name="description"
                      value={ProductDescription}
                      placeholder=""
                      onChange={handleChangeProductDescription}
                      required
                      errorMessage={error ? "Enter product description" : ""}
                    />
                  </div>
                </div>
                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label htmlFor="cost_item" className="pModal-form__label">
                        Product Price
                      </label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      name="cost_item"
                      type="text"
                      placeholder=""
                      id="cost_item"
                      value={ChangeAmount}
                      onChange={handleChangeAmount}
                      required
                      errorMessage={error ? "Enter product amount" : ""}
                      className="nsForm-input__alternate"
                    />
                    <p className="text-right" style={{ fontSize: "10px" }}>
                      No special character is allowed (.,/*_-)
                    </p>
                  </div>
                </div>
                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Currency</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <SelectInputWithoutLabel
                      options={[]}
                      name="currency"
                      id="currency"
                      value={ChangeCurrency}
                      onChange={handleChangeCurrency}
                      defaultValue="Nigerian Naira"
                     
                      errorMessage={error ? "Select currency" : ""}
                      className="nsForm-select__alternate"
                    />
                  </div>
                </div>

                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Product Type</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <SelectInputWithoutLabel
                      options={[
                        { value: "Tangible", label: "Tangible" },
                        // { value: "Intangible", label: "Intangible" },
                      ]}
                      name="productType"
                      id="productType"
                      value={productType}
                      onChange={handleProductType}
                      required
                      errorMessage={error ? "Select product type" : ""}
                      className="nsForm-select__alternate"
                    />
                  </div>
                </div>

                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-6 px-0 px-md-auto">
                    <div className="col-md-10">
                      <div
                        className="pModal-form__label-control"
                        style={{ alignItems: "flex-start" }}
                      >
                        <label className="pModal-form__label">Delivery Period*</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <InputWithoutLabel
                        name="delivery_days"
                        type="number"
                        placeholder="Delivery period (Days)"
                        id="delivery_period"
                        value={DeliveryPeriod}
                        onChange={handleChangeDeliveryPeriod}
                        className="nsForm-input__alternate"
                        errorMessage={error ? "Enter delivery days" : ""}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 px-0 px-md-auto">
                    <div className="col-md-8">
                      <div
                        className="pModal-form__label-control"
                        style={{ alignItems: "flex-start" }}
                      >
                        <label className="pModal-form__label">Quantity</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <InputWithoutLabel
                        name="weight"
                        type="number"
                        placeholder=""
                        id="cost_item"
                        value={quantity}
                        onChange={handleSetQuantity}
                        required
                        errorMessage={error ? "Enter product weight" : ""}
                        className="nsForm-input__alternate"
                      />
                    </div>
                  </div>
                </div>

                {/*Product heigth, weight, width, length starts under here */}
                {productType === "Tangible" && (
                  <div className="mx-0 pModal-form-control row">
                    <div className="col-md-6 px-0 px-md-auto">
                      <div className="col-md-10">
                        <div
                          className="pModal-form__label-control"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label className="pModal-form__label">Product Weight*</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <InputWithoutLabel
                          name="weight"
                          type="number"
                          placeholder="Enter exact weight"
                          id="cost_item"
                          value={weight}
                          onChange={handleSetWeight}
                          required
                          errorMessage={error ? "Enter product weight" : ""}
                          className="nsForm-input__alternate"
                        />
                      </div>
                    </div>
                    

                    <div className="col-md-6 px-0 px-md-auto">
                      <div className="col-md-8">
                        <div
                          className="pModal-form__label-control"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label className="pModal-form__label">Product Category</label>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <SelectInputWithoutLabel
                          options={productCategory}
                          name="category_id"
                          id="category_id"
                          value={
                           Object.keys(selectedCategory).length
                            ? selectedCategory?.value
                            : productCategory[0]?.category_id
                          }
                          onChange={handleProductCategorySelection}
                          required
                          errorMessage={error ? "Select product type" : ""}
                          className="nsForm-select__alternate border border-black"
                        />
                      </div>
                    </div>
                  </div>
                )}

        <div className="mx-0 pModal-form-control row">
                  <div className="col-md-6 px-0 px-md-auto">
                    <div className="col-md-10">
                      <div
                        className="pModal-form__label-control"
                        style={{ alignItems: "flex-start" }}
                      >
                        <label className="pModal-form__label">Product Weight Unit *</label>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                        <SelectInputWithoutLabel
                          options={weightUnits}
                          name="weight_unit"
                          id="weight_unit"
                          value={weightUnit}
                          onChange={handleWeightUnitSelection}
                          required
                          errorMessage={error ? "Select product weight unit" : ""}
                          className="nsForm-select__alternate border border-black"
                        />
                      </div>
                  </div>

                  <div className="col-md-6 px-0 px-md-auto">
                    <div className="col-md-8">
                      <div
                        className="pModal-form__label-control"
                        style={{ alignItems: "flex-start" }}
                      >
                        <label className="pModal-form__label">Discount Percent</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <InputWithoutLabel
                        name="discount"
                        type="number"
                        placeholder=""
                        id="cost_item"
                        value={discount}
                        onChange={handleChangeDiscount}
                        
                        errorMessage={error ? "Enter product Discount percent" : ""}
                        className="nsForm-input__alternate"
                      />
                    </div>
                  </div>
                </div>
                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-6 px-0 px-md-auto">
                    <div className="col-md-8">
                      <div
                        className="pModal-form__label-control"
                        style={{ alignItems: "flex-start" }}
                      >
                        <label className="pModal-form__label">Box Sizes</label>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <SelectInputWithoutLabel
                        options={boxSize}
                        name="boxSize"
                        id="boxSize"
                        value={
                          Object.keys(selectedBoxSize).length
                            ? selectedBoxSize?.value
                            : boxSize[0]?.box_size_id
                        }
                        onChange={handleBoxSizeSelection}
                        required
                        errorMessage={error ? "Select product type" : ""}
                        className="nsForm-select__alternate border border-black"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 px-0 px-md-auto">
                    {selectedBoxSize?.description_image_url && (
                      <div
                        className="w-full d-flex justify-content-center align-items-center mt-5 mt-md-0   mx-auto"
                        style={{ minHeight: "250px", maxHeight: "260px" }}
                      >
                        <img
                          src={selectedBoxSize?.description_image_url}
                          alt="the box size"
                          className="h-full w-full"
                        ></img>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mx-0 pModal-form-control row">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Display Image</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      name="product_image"
                      type="file"
                      placeholder=""
                      id="cost_item"
                     
                      required
                      errorMessage={error ? "Upload Product Image" : ""}
                      onChange={handleImage}
                      className="nsForm-input__alternate"
                      style={{ border: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pModal-footer">
              <div
                className="button button--auto button-md button--neutral"
                onClick={() => {
                  context.updateShowProductModal(false);
                }}
              >
                CANCEL
              </div>
              <button type="submit" className="button button-md button--orange">
                {publishingProduct? (
                  <SpinnerIcon />
                ):(
                  <>ADD A PRODUCT</>
                )}
                
               
              </button>
            </div>
          </div>
        </div>
        <EscapeCloseModalHelper />
      </form>
    </>
  );
};

const mapStateToProps = (state, { context }) => {
  return {
    token: state.auth.token,
    user: state.auth.userInfo,
    context: context,
    loaded: state.products.loaded,
    loading: state.products.loading,
    error: state.products.error,
    publishProductError:state.products?.publishProductError,
    publishProductSuccess:state.products?.publishProductSuccess,
    publishingProduct:state.products?.publishingProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publishSingleProductComplete:()=>dispatch(actions.publishSingleProductComplete()),
    publishProduct: (token, user, extraParams) =>
      dispatch(actions.publishSingleProduct(token, user, extraParams)),
    holdFormValuesInStore: (extraParams) => dispatch(actions.holdProductValuesInStore(extraParams)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
