import InputField from "components/blocks/InputField";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { updateProfile } from "store/actions";
import { EditIcon } from "components/vectors";
import User from "components/vectors/User";
import { FileUploader } from "react-drag-drop-files";
import { fileTypes } from "libs/constants";

const UserProfile = () => {
  const [logoPreview, setLogoPreview] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const { userProfile, updatingProfile } = useSelector(
    (state) => state.userAccount
  );
  const { token, userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const profileSchema = yup.object().shape({
    name: yup.string().required("Fullname is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone number is required"),
    businessname: yup.string().required("Business name is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
    mode: "all",
  });

  useEffect(() => {
    if (userProfile) {
      reset({
        name: userProfile?.name,
        email: userProfile?.email,
        businessname: userProfile?.businessname,
        phone: userProfile?.phone,
        address: userProfile?.address,
      });
    }
  }, [userProfile, profilePic]);

  const submit = (data) => {
    // if (data.phone?.length > 11) {
    //   data.phone = "+" + data.phone;
    // }

    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    if (profilePic) {
      formData.append("profile_pic", profilePic);
    }
    formData.append("customerID", userInfo?.customerID);
    dispatch(updateProfile(formData, token, userInfo));
  };

  const handleFileChange = (file) => {
    const imageFile = file;
    const reader = new FileReader();
    reader.onload = (event) => {
      setLogoPreview(event.target.result);
    };
    reader.readAsDataURL(imageFile);
    setProfilePic(file);
  };

  return (
    <div className="profile__info">
      <p className="title mb-3">Your profile</p>
      <form onSubmit={handleSubmit(submit)}>
        <section className="row mt-4">
          <div className="col-md-3 ">
            <FileUploader handleChange={handleFileChange} types={fileTypes}>
              <div className="image__container">
                {logoPreview ? (
                  <>
                    <img src={logoPreview} className="logo__preview" />
                  </>
                ) : (
                  <>
                    {userProfile?.profile_pic ? (
                      <img
                        src={userProfile?.profile_pic}
                        className="logo__preview"
                      />
                    ) : (
                      <>
                        <User width="50" height="50" />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="edit__image__icon">
                <EditIcon color="white" height="18" width="18" />
              </div>
            </FileUploader>
          </div>
          <div className="col-md-9 mt-lg-0 mt-2">
            <div className="form__container">
              <div className="form__wrapper">
                <InputField
                  id="fullname"
                  label="Fullname"
                  name="name"
                  placeHolder="Fullname"
                  register={register}
                  error={errors.name?.message}
                  type="text"
                />
              </div>
              <div className="form__wrapper">
                <InputField
                  id="businessname"
                  label="Business Name"
                  name="businessname"
                  placeHolder="Business Name"
                  register={register}
                  error={errors.businessname?.message}
                  type="text"
                />
              </div>
              <div className="form__wrapper">
                <InputField
                  id="phone"
                  label="Phone number"
                  name="phone"
                  placeHolder="Phone Number"
                  register={register}
                  error={errors.phone?.message}
                  type="tel"
                />
              </div>
              <div className="form__wrapper">
                <InputField
                  id="email"
                  label="Email Address"
                  name="email"
                  placeHolder="Email Address"
                  register={register}
                  error={errors.email?.message}
                  type="email"
                />
              </div>
              <div className="form__wrapper">
                <InputField
                  id="address"
                  label="Home Address"
                  name="address"
                  placeHolder="Home Address"
                  register={register}
                  error={errors.address?.message}
                  type="textarea"
                />
              </div>
              <div className="button__wrapper">
                <button
                  className="button button--purple button__content"
                  disabled={updatingProfile}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default UserProfile;
