import React from "react";

const UploadIcon = () => {
  return (
    <svg
      width="57"
      height="54"
      viewBox="0 0 57 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="14.2296"
        y="6.9324"
        width="32.8799"
        height="29.151"
        rx="1"
        transform="rotate(12.3584 14.2296 6.9324)"
        stroke="black"
        strokeWidth="2"
      />
      <rect
        width="37.375"
        height="31.625"
        transform="translate(4.3125 15.1875)"
        fill="white"
      />
      <path
        d="M38.8125 15.1875H7.1875C6.425 15.1875 5.69373 15.4904 5.15457 16.0296C4.6154 16.5687 4.3125 17.3 4.3125 18.0625V43.9375C4.3125 44.7 4.6154 45.4313 5.15457 45.9704C5.69373 46.5096 6.425 46.8125 7.1875 46.8125H38.8125C39.575 46.8125 40.3063 46.5096 40.8454 45.9704C41.3846 45.4313 41.6875 44.7 41.6875 43.9375V18.0625C41.6875 17.3 41.3846 16.5687 40.8454 16.0296C40.3063 15.4904 39.575 15.1875 38.8125 15.1875ZM38.8125 18.0625V36.5254L34.128 31.8427C33.8611 31.5757 33.5441 31.3639 33.1952 31.2193C32.8464 31.0748 32.4725 31.0004 32.0949 31.0004C31.7173 31.0004 31.3434 31.0748 30.9945 31.2193C30.6457 31.3639 30.3287 31.5757 30.0617 31.8427L26.468 35.4365L18.5617 27.5302C18.0226 26.9915 17.2916 26.6888 16.5295 26.6888C15.7673 26.6888 15.0363 26.9915 14.4972 27.5302L7.1875 34.8399V18.0625H38.8125ZM7.1875 38.9062L16.5312 29.5625L30.9062 43.9375H7.1875V38.9062ZM38.8125 43.9375H34.9726L28.5038 37.4688L32.0976 33.875L38.8125 40.5917V43.9375ZM25.875 25.9688C25.875 25.5423 26.0015 25.1254 26.2384 24.7708C26.4753 24.4162 26.8121 24.1398 27.2061 23.9766C27.6001 23.8134 28.0336 23.7707 28.4519 23.8539C28.8702 23.9371 29.2544 24.1425 29.5559 24.4441C29.8575 24.7456 30.0629 25.1298 30.1461 25.5481C30.2293 25.9664 30.1866 26.3999 30.0234 26.7939C29.8602 27.1879 29.5838 27.5247 29.2292 27.7616C28.8746 27.9985 28.4577 28.125 28.0312 28.125C27.4594 28.125 26.9109 27.8978 26.5066 27.4934C26.1022 27.0891 25.875 26.5406 25.875 25.9688Z"
        fill="black"
      />
    </svg>
  );
};

export default UploadIcon;
