import ArrowDown from "components/vectors/ArrowDown";
import React, { useState, useEffect } from "react";


function Dropdown({options,bordered}) {
  const [dropdownState, setDropdownState] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
  };
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value);
    setDropdownState(prev=>!prev);
  };

  return (
    <div className="container">
      <div className={`dropdown`} onClick={handleDropdownClick}>
        <button  className={`dropdown-btn ${bordered?'bordered':''}`}>
          {dropdownValue === "" ? options[0] : dropdownValue}
        </button>
         <div className={`arrow ${bordered?'arrow__bordered':''}`}><ArrowDown/></div>
        <div
          className={`dropdown-items ${
            dropdownState ? "isVisible" : "isHidden"
          }`}
        >
          {
            options?.map((option,index)=>(
              <div className="dropdown__item" key={index}>
            <div
              className="dropdown__link"
              onClick={() => handleSetDropdownValue(option)}
            >
              {option}
            </div>
          </div>
            ))
          }
         
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
