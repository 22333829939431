import React from 'react'

const TrackStepLineMobile = () => (
  <svg width="2" height="280" viewBox="0 0 2 280" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="1" y1="4.37114e-08" x2="0.999991" y2="280" stroke="#5E60CE" strokeWidth="2"/>
</svg>


)

export default TrackStepLineMobile