import React from "react";

const UserIconFill = ({ width = 24, height = 24, color = "#FE8F01" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.0013 7.9987C9.84225 7.9987 11.3346 6.50631 11.3346 4.66536C11.3346 2.82442 9.84225 1.33203 8.0013 1.33203C6.16035 1.33203 4.66797 2.82442 4.66797 4.66536C4.66797 6.50631 6.16035 7.9987 8.0013 7.9987Z"
      fill={color}
    />
    <path
      d="M8.00141 9.66797C4.66141 9.66797 1.94141 11.908 1.94141 14.668C1.94141 14.8546 2.08807 15.0013 2.27474 15.0013H13.7281C13.9147 15.0013 14.0614 14.8546 14.0614 14.668C14.0614 11.908 11.3414 9.66797 8.00141 9.66797Z"
      fill={color}
    />
  </svg>
);

export default UserIconFill;
