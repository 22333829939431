import Avatar from "./img/avatar.png";
import Bg from "./img/bg.png";
import SantaBg from "./img/santa-offer-banner.png";
import SantaLogo from "./img/santa-logo.webp";
import { ReactComponent as CartSVG } from "./img/svg/shopping-bag.svg";
import { ReactComponent as OutOfStockSVG } from "./img/svg/no-stock.svg";
import { ReactComponent as Checked } from "./img/svg/checked.svg";
import { ReactComponent as FacebookIcon } from "./img/svg/facebook.svg";
import { ReactComponent as InstagramIcon } from "./img/svg/instagram.svg";
import { ReactComponent as ProfileIcon } from "./img/svg/profile.svg";
import { ReactComponent as WhatsAppIcon } from "./img/svg/whatsapp.svg";
import { ReactComponent as TwitterIcon } from "./img/svg/twitter.svg";
import { ReactComponent as VisaIcon } from "./img/svg/visa.svg";
import { ReactComponent as NoItemIcon } from "./img/svg/no-item.svg";
import Product from "./img/product.png";
import Product2 from "./img/product2.png";

export {
  Bg,
  Product2,
  Product,
  CartSVG,
  Checked,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  ProfileIcon,
  WhatsAppIcon,
  VisaIcon,
  Avatar,
  NoItemIcon,
  OutOfStockSVG,
  SantaBg,
  SantaLogo,
};
