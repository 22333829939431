import React from "react";

const PremiumIcon = () => {
  return (
    <svg
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <circle cx="40" cy="40" r="40" fill="#4CAF50" />
      <path
        d="M45.9634 49.0986H33.0384C32.4609 49.0986 31.8146 48.6448 31.6221 48.0948L25.9296 32.1723C25.1184 29.8898 26.0671 29.1886 28.0196 30.5911L33.3821 34.4273C34.2759 35.0461 35.2934 34.7298 35.6784 33.7261L38.0984 27.2773C38.8684 25.2148 40.1471 25.2148 40.9171 27.2773L43.3371 33.7261C43.7221 34.7298 44.7396 35.0461 45.6196 34.4273L50.6521 30.8386C52.7971 29.2986 53.8284 30.0823 52.9484 32.5711L47.3934 48.1223C47.1871 48.6448 46.5409 49.0986 45.9634 49.0986Z"
        fill="#4CAF50"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.938 53.252H47.063"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.063 42.248H42.938"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PremiumIcon;
