import React from 'react'

function SuccessIcon() {
  return (

    <div>SuccessIcon</div>

  )
}

export default SuccessIcon
