import React from "react";

const CheckedIcon = ({ color = "#219653", width = 16, height = 17 }) => (
  <svg
    width={width}
    maxwidth="100%"
    maxweight="100%"
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{ zIndex: 999 }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8.5C0 6.37827 0.842855 4.34344 2.34315 2.84315C3.84344 1.34285 5.87827 0.5 8 0.5C10.1217 0.5 12.1566 1.34285 13.6569 2.84315C15.1571 4.34344 16 6.37827 16 8.5C16 10.6217 15.1571 12.6566 13.6569 14.1569C12.1566 15.6571 10.1217 16.5 8 16.5C5.87827 16.5 3.84344 15.6571 2.34315 14.1569C0.842855 12.6566 0 10.6217 0 8.5H0ZM7.54347 11.924L12.1493 6.16613L11.3173 5.50053L7.38987 10.4083L4.608 8.0904L3.92533 8.9096L7.54347 11.9251V11.924Z"
      fill={color}
    />
  </svg>
);

export default CheckedIcon;
