import axios from "axios";
import { fireSwalError, getFromStorage } from "../buyer-constants";

const api = axios.create({
  // baseURL:
  // 	process.env.NODE_ENV === "development"
  // 		? `${process.env.REACT_APP_TESTURL}`
  //  baseURL: `${process.env.REACT_APP_LIVEURL}`,
  baseURL: `${process.env.REACT_APP_LIVEURL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// api.interceptors.response.use((response) => response.data, null);
api.interceptors.response.use(
  (response) => response.data,

  (error) => {
    console.log({ error });
    if (
      error.response.data.ResponseCode === 500 &&
      error.response.data.message == "Invalid or expired or no token"
    ) {
      fireSwalError(error.response.data.message).then(() => {
        // window.location.href = "/";
      });
    } else if (
      error.response.data.ResponseCode == 400 &&
      error.response.data.ResponseMessage == "customer/merchant not found"
    ) {
      window.location.href = "/404";
      throw new Error(error.response.data, {
        cause: error.response.data,
      });
    } else {
      throw new Error(error.response.data.ResponseMessage, {
        cause: error.response.data.data,
      });
    }
  }
);

export const getMerchant = ({
  id,
  page = 1,
  sortBy = "id",
  direction = "ASC",
  keyword = "",
}) =>
  api.get(`/product/merchantStore`, {
    params: {
      merchantCode: id,
      page,
      sortBy,
      direction,
      keyword,
    },
  });

export const getAllProducts = ({
  id,
  page = 1,
  sortBy = "id",
  direction = "ASC",
  keyword = "",
}) =>
  api.get(`/marketplace/allProducts`, {
    params: {
      page,
      sortBy,
      direction,
      keyword,
    },
  });

export const getProductDetails = (id) =>
  api.get(`/product/getProduct?productID=${id}`);

export const preCheckout = (data) => api.post("/order/preCheckout", data);

export const placeOrder = ({ data, token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return api.post("/order/placeOrder", data, config);
};

export const getStorePickupAddress = ({ token, merchantID }) => {
  return api.get("/precheckout/getMerchantPickupAddress", {
    params: {
      merchantID,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const verifyToken = ({ reference, token }) =>
  api.get("/order/payment/callback", {
    params: {
      reference,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllUserOrders = ({ token, id }) => {
  return api.get("/myOrders", {
    params: {
      customerID: id,
      pageType: "All",
      query: "",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const refreshToken = ({ id, token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      customerID: id,
    },
  };
  return api.post("/refreshToken", {}, config);
};

export const reportDispute = ({ data, token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return api.post("/orderDispute/reportDispute", data, config);
};

export const trackOrder = ({ token, id }) => {
  return api.get("/order/getStatusChanges", {
    params: {
      orderID: id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getWishlist = async (ref) => {
  return await api.get(`/wishlist/getWishlistByRef?ref=${ref}`);
};

export const getWishlistShippingRate = async (data) => {
  return await api.post(`/wishlist/getShipmentRates`, data);
};

export const checkoutWishlist = async (data) => {
  return await api.post(`/wishlist/wishlistCheckout`, data);
};

export const verifyWishlistPayment = async ({ reference, token }) => {
  return await api.post(
    `/wishlist/verifyWishlistCardPayment`,
    { reference },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const applyWishlistCoupon = async (data) => {
  return await api.post(`/wishlist/applyCoupon`, data);
};

export const applyOrderCoupon = async (data) => {
  const token = getFromStorage("userToken");
  console.log({ data, token });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await api.post(`/order/applyCoupon`, data, config);
};

export const getAllStore = ({
  page = 1,
  per_page = 12,
  category = "",
  keyword = "",
}) =>
  api.get(`/marketplace/getAllStores`, {
    params: {
      page,
      category_id: category,
      keyword,
      per_page,
    },
  });

export const getAllCategories = () => api.get(`/marketplace/getCategories`, {});
