import EmailInput from "components/blocks/EmailInput";
import { AttachmentIcon } from "components/vectors";
import React from "react";

const SendBroadcast = () => {
  return (
    <div className="profile__info">
      <p className="title mb-3">Send Broadcast</p>
      <form>
        <div className="form__container">
          <EmailInput label="To:" type="email" />
          <EmailInput label="Subject:" type="text" />
          <EmailInput type="textarea" placeHolder="Enter Text" />

          <section className="attachment__wrapper">
            <div className="attachment">
              <AttachmentIcon />
              <p>Attach file</p>
            </div>
            <div className="send__button__wrapper">
              <button className="button button--purple button__content px-4">
                Send
              </button>
            </div>
          </section>
        </div>
      </form>
    </div>
  );
};

export default SendBroadcast;
