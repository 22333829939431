import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  fireSwalError,
  fireSwalSuccess,
  getFromStorage,
  setToStorage,
} from "../buyer-constants";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const initialState = getFromStorage("state") || {
    merchant_email: null,
    wishlist_id: null,
    cart: [],
    wishlistCart: [],
    coupon_value: 0,
    order_coupon: 0,
  };

  const reducer = (state, action) => {
    console.log({ "ACTION TYPE": action.type });
    switch (action.type) {
      case "ADD":
        //? if is not null and the merchant of the new item isnt the merchant in state return state
        if (
          state.merchant_email &&
          action.payload.product.merchant_email !== state?.merchant_email
        ) {
          fireSwalError(
            "You cant have products from different stores in the cart"
          );
          return state;
        }

        //? if item is in cart return state
        if (
          state.cart.some(
            (item) => item.product.id === action.payload.product.id
          )
        ) {
          fireSwalSuccess("Item already in cart");
          return state;
        }

        var stateCopy = { ...state };

        stateCopy = { ...stateCopy, cart: [...stateCopy.cart, action.payload] };

        //? if there is nothing in the cart set the merchant to the merchant of the item
        if (!state.cart.length) {
          stateCopy = {
            ...stateCopy,
            merchant_email: action.payload.product.merchant_email,
          };
        }

        fireSwalSuccess("Item added to cart");

        return stateCopy;

      case "REMOVE":
        const newCart = state.cart.filter(
          (item) => item.product.id !== action.payload
        );

        if (newCart?.length > 0) {
          return {
            ...state,
            cart: newCart,
          };
        } else {
          return {
            ...state,
            merchant_email: null,
            cart: [],
          };
        }

      case "CHANGE-QUANTITY":
        // if quantity is zero remove item form cart
        if (action.payload.quantity === 0) {
          // if cart is empty clear merchant
          const newCart = state.cart.filter(
            (item) => item.product.id !== action.payload.id
          );

          if (!!newCart.length) {
            console.log("asas", !!newCart.length);
            return {
              ...state,
              merchant: state.merchant,
              cart: newCart,
            };
          } else {
            return {
              ...state,
              merchant_email: null,
              cart: [],
            };
          }
        } else {
          const indexOfObjectToUpdate = state.cart?.findIndex(
            ({ product }) => product.id === action.payload.id
          );

          state.cart[indexOfObjectToUpdate].quantity = action.payload.quantity;

          return {
            ...state,
            cart: state.cart,
          };
        }
      case "APPLY-ORDER-COUPON":
        return {
          ...state,
          order_coupon: action.payload,
        };

      case "CLEAR":
        return {
          ...state,
          merchant: null,
          merchant_email: null,
          cart: [],
          order_coupon: 0,
        };
      case "ADD-WISHLIST":
        //? if item is in cart return state
        console.log({ payload: action.payload });
        if (
          state.wishlistCart?.some(
            (item) => item.product.id === action.payload.product.id
          )
        ) {
          return state;
        }

        var stateCopy = { ...state };

        stateCopy = {
          ...stateCopy,
          wishlistCart: [...stateCopy.wishlistCart, action.payload],
        };

        //? if there is nothing in the cart set the merchant to the merchant of the item
        if (!state.wishlistCart.length) {
          stateCopy = {
            ...stateCopy,
            wishlist_id: action.payload.wishlist_id,
          };
        }

        // fireSwalSuccess("Item added to cart");

        return stateCopy;
      case "REMOVE-WISHLIST":
        const newwishListCart = state.wishlistCart.filter(
          (item) => item.product.id !== action.payload
        );

        if (!!newwishListCart.length || newwishListCart != undefined) {
          return {
            ...state,
            wishlistCart: newwishListCart,
          };
        } else {
          return {
            ...state,
            wishlistCart: [],
          };
        }

      case "CHANGE-QUANTITY-WISHLIST":
        // if quantity is zero remove item form cart
        if (action.payload.quantity === 0) {
          // if cart is empty clear merchant
          const newCart = state.wishlistCart.filter(
            (item) => item.product.id !== action.payload.id
          );

          if (!!newCart.length) {
            return {
              ...state,
              wishlistCart: newCart,
            };
          } else {
            return {
              ...state,
              wishlistCart: [],
            };
          }
        } else {
          const indexOfObjectToUpdate = state.wishlistCart.findIndex(
            ({ product }) => product.id === action.payload.id
          );

          state.wishlistCart[indexOfObjectToUpdate].quantity =
            action.payload.quantity;

          return {
            ...state,
            wishlistCart: state.wishlistCart,
          };
        }

      case "CLEAR-WISHLIST":
        return {
          ...state,
          wishlistCart: [],
          coupon_value: 0,
          wishlist_id: action.payload.wishlist_id,
        };
      case "APPLY-WISHLIST-COUPON":
        return {
          ...state,
          coupon_value: action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const price = useMemo(() => {
    let totalPrice = state.cart?.reduce(
      (accumulator, { product, quantity }) => {
        return accumulator + parseInt(product.amount) * quantity;
      },
      0
    );
    return totalPrice - (state.order_coupon ? state.order_coupon : 0);
  }, [state]);

  const wishlistPrice = useMemo(
    () =>
      state?.wishlistCart?.reduce((accumulator, { product, quantity }) => {
        return accumulator + parseInt(product?.price) * quantity;
      }, 0),
    [state]
  );

  const platformFee = useMemo(
    () =>
      state?.wishlistCart?.reduce((accumulator, { product, quantity }) => {
        return accumulator + (parseInt(product?.price) * quantity * 1.5) / 100;
      }, 0),
    [state]
  );

  const getItemQuantity = (id) => {
    return (
      state?.wishlistCart?.find((item) => item?.product?.id === id)?.quantity ||
      0
    );
  };

  const currentState = useMemo(() => {
    const currentState = state.wishlistCart;
    return currentState;
  }, [state]);

  const couponValue = useMemo(() => {
    return state?.coupon_value;
  }, [state]);

  const orderCoupon = useMemo(() => {
    return state?.order_coupon;
  }, [state]);

  useEffect(() => {
    setToStorage("state", state);
  }, [state]);

  return (
    <CartContext.Provider
      value={{
        state,
        dispatch,
        price,
        wishlistPrice,
        getItemQuantity,
        currentState,
        platformFee,
        couponValue,
        orderCoupon,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  return useContext(CartContext);
};

export default CartProvider;
