import React from 'react'
import { Link } from 'react-router-dom'
import './premiumfooter.css'

function Index() {
  return (
    <div className='premium-footer mt-5'>
        <main className='container  p-1'>
        <div className='d-flex justify-content-between align-items-center flex-lg-row flex-column '>
       
            <ul className='nav mb-2 mb-lg-0' >
                <li className='nav-item'>
                    <Link to={'/'} className='nav-link text-white'>Home</Link>
                </li>
               
                <li className='nav-item'>
                    <Link to={'/new-dashboard/support'} className='nav-link text-white'>Support</Link>
                </li>
                <li className='nav-item'>
                    <Link to={'/new-dashboard/support'} className='nav-link text-white'>FAQs</Link>
                </li>
            </ul>
       
        <p className=''>© 2022 Pepperest. All rights reserved</p>
        </div>
        </main>

    </div>
  )
}

export default Index