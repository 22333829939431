export const ambassadorInnerRoutes = [
  {
    url: "/ambassador/dashboard",
    title: "Dashboard",
    link: "dashboard",
  },
]

export const paymentInnerRoutes = [
  {
    url: "/payments/transactions",
    title: "All Transactions",
    link: "transactions",
  },
  {
    url: "/payments/pending",
    title: "Pendings",
    link: "pending",
  },
  {
    url: "/payments/refund",
    title: "Refunds",
    link: "refund",
  },
  {
    url: "/payments/paid",
    title: "Paid",
    link: "paid",
  },
  {
    url: "/payments/release",
    title: "Completed Transactions",
    link: "release",
  },
  // {
  //   url: "/payments/stop",
  //   title: "Stop Payment",
  //   link: "stop",
  // },
  // {
  //   url: "/payments/instagram",
  //   title: "Instagram",
  // },
];

export const productInnerRoutes = [
  {
    url: "/products/single",
    title: "Products",
  },
  {
    url: "/products/instagram",
    title: "Instagram Store",
  },
];

export const ordersInnerRoutes = [
  {
    url: "/orders/dashboard",
    title: "Dashboard",
  },
  {
    url: "/orders/all",
    title: "All Orders",
  },

  {
    url: "/orders/pending",
    title: "Pending Orders",
  },
  {
    url: "/orders/paid",
    title: "Paid",
  },
  {
    url: "/orders/shipped",
    title: "Shipped",
  },
  {
    url: "/orders/delivered",
    title: "Delivered",
  },
  {
    url: "/orders/cancel",
    title: "Canceled Orders",
  },
  {
    url: "/orders/referal",
    title: "Referal Stats",
  },
];
export const invoicesInnerRoutes = [
  {
    url: "/invoices/dashboard",
    title: "Dashboard",
  },
  {
    url: "/invoices/all",
    title: "All Invoices",
  },
  {
    url: "/invoices/confirmed",
    title: "Confirmed",
  },
  {
    url: "/invoices/cancel",
    title: "Canceled",
  },
  {
    url: "/invoices/pending",
    title: "Pending",
  },
  {
    url: "/invoices/paid",
    title: "Paid",
  },
];
export const settingsInnerRoutes = [
  {
    url: "/user-account/profile",
    title: "Profile",
    exact: true,
  },
  // {
  //   url: "/user-account/notifications",
  //   title: "Notifications",
  //   exact: true,
  // },
  {
    url: "/user-account/bank-account",
    title: "Bank Account",
    exact: true,
  },
  {
    url: "/user-account/linked-media-account",
    title: "Linked Media Accounts",
    exact: true,
  },
  {
    url: "/user-account/customize-store",
    title: "Customize Store",
    exact: true,
  },
  {
    url: "/user-account/api-configurations",
    title: "API Configuration",
    exact: true,
  },
  {
    url: "/user-account/loans",
    title: "Request Loan",
    exact: true,
  },
];

export const footerLinks = {
  0: [
    // {
    //   url: "/",
    //   title: "SOLUTIONS",
    //   isLink: false,
    // },
    // {
    //   url: "/",
    //   title: "E-Invoices ",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "Social Commerce ",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "Forex Intermediation",
    //   isLink: true,
    // },
  ],
  1: [
    {
      url: "/",
      title: "COMPANY",
      isLink: false,
    },
    // {
    //   url: "/",
    //   title: "About",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "Careers",
    //   isLink: true,
    // },

    {
      url: "/terms-condition",
      title: "Terms of Service",
      isLink: true,
    },
    {
      url: "/privacy",
      title: "Privacy Policy",
      isLink: true,
    },
  ],
  2: [
    // {
    //   url: "/",
    //   title: "DOCUMENTATION",
    //   isLink: false,
    // },
    // {
    //   url: "/",
    //   title: "Support",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "Docs",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "API Reference",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "Status",
    //   isLink: true,
    // },
  ],
  3: [
    {
      url: "/",
      title: "CONNECT",
      isLink: false,
    },
    {
      url: "http://bit.ly/joinpepperestcommnity",
      title: "Join Community",
      isLink: true,
      outside: true,
    },
    {
      url: "https://prosperofpepperest.substack.com/",
      title: "Blog Post",
      isLink: true,
      outside: true,
    },
    // {
    //   url: "/",
    //   title: "Linkedin",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "Twitter",
    //   isLink: true,
    // },
    // {
    //   url: "/",
    //   title: "Facebook",
    //   isLink: true,
    // },
  ],
};
