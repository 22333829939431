import React, {useEffect, useRef, useState} from 'react'
import { KlumpCheckout } from 'klump-react'
import withDefaultLayout from 'components/layouts/withDefaultLayout';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';


const config = {
    hasAlternateHeader: false,
    hasCommonHeader: true,
    showCart: true,
    commonHeaderTitle: "Klump Secure Checkout ",
    links: [],
    page: "checkout",
    isSettings: true,
    navBarTitle: "Secure Checkout with Klump",
  };

const KlumpCheckoutComponent = () => {

  const {userInfo} = useSelector(state=>state.auth);
  const {cartOrder} = useSelector(state=>state.orders);
  const history = useHistory();

    if(cartOrder===null){
      history.push('cart');
    }
  
  
  const product = cartOrder?.cart_item?.map(item=>{
    const image_url = item.image_url;
    const item_url = item.image_url;
    const name = item.productname;
    const unit_price = parseInt(item.price);
    const quantity = item.quantity;
    return {image_url,item_url,name,unit_price,quantity}
})
product && product.push({
  name:'Pepperest Fee (1.5% of Order)',
  quantity:1,
  unit_price:parseInt(cartOrder?.pepperestfees)
})

const params = {
       amount: parseInt(cartOrder?.total+cartOrder?.shipping),
       shipping_fee: parseInt(cartOrder?.shipping),
       currency: 'NGN',
       //redirect_url: 'http://localhost:3000/confirm',
       merchant_reference: cartOrder?.orderRef,
       meta_data: {
         customer: userInfo?.name,
         email: userInfo?.email
       },
       items:product
     }
   
    const payWithKlump = () => {
        const payload = {
           publicKey:'klp_pk_664aa0d0ec634b95878d3310e8344adb0240cdebea2b4efa84ccc70d37d6b733',
           data:params,
           onSuccess,
           onError,
           onLoad,
           onOpen,
           onClose
        }
      
        // eslint-disable-next-line no-undef
          new Klump(payload)
    }

    const onSuccess = (data) => {
      const response = data.data;
     history.push(`/confirm?txref=${cartOrder?.paymentRef}&reference=${response.data.data.reference}`)
      
      return data;
    }
    const onError = (data) => {
    
    }

    const onLoad = (data) => {
    } 

    const onOpen = (data) => {
    }

    const onClose = (data) => {
  
    }

      return <>
      <div className='row justify-content-center mt-2'>
        
        <div className='col-md-6'>
          <p className='text-center'>Please click the button bellow to pay with Klump</p>
        </div>
        </div>
        <div className='row justify-content-center mt-2'>
        
        <div className='col-md-4'>

        {/* <div id="klump__checkout" ></div> */}
        <KlumpCheckout  onClick={payWithKlump} />

        </div>
        </div>
            </>
}

export default withDefaultLayout(KlumpCheckoutComponent,config)