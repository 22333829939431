import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import { SpinnerIcon } from "components/vectors";
import { currencyFormatter } from "store/utility";

function ThirdPartyOrderTransaction(props) {
  const history = useHistory();

  const { token, getThirdpartyTrxRef, thirdPartyDetails, loading } = props;

  const { buyerName, endDate, payurl, sellerName, startDate, totalCost, pepperestFees } =
    thirdPartyDetails;

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const trex = params.get("external_tranx_ref");
    getThirdpartyTrxRef(token, trex);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          minHeight: "50vh",
          width: "80vw",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SpinnerIcon />
      </div>
    );
  }
  return (
    <>
      <div className="thirdpartyLayout">
        <div className="thirdpartyLayout-card">
          <header>
            <h6 className="text--small text-font--medium text-center thirdpartyLayout-card-header">
              You Order Details
            </h6>
          </header>
          <section className="bg-black  w-100 thirdpartyLayout-card-middle">
            <ul>
              <li className="d-flex mb-1 flex justify-content-between items-center">
                <div>Buyer Name</div>
                <div>{buyerName}</div>
              </li>
              <li className="d-flex mb-1 flex justify-content-between items-center">
                <div>Seller Name</div>
                <div>{sellerName}</div>
              </li>
              <li className="d-flex mb-1 flex justify-content-between items-center">
                <div>Start Date</div>
                <div>{startDate}</div>
              </li>
              <li className="d-flex mb-1 flex justify-content-between items-center">
                <div>End Date</div>
                <div>{endDate}</div>
              </li>
              <li className="d-flex mb-1 flex justify-content-between items-center">
                <div>pepperest Fees</div>
                <div>{currencyFormatter.format(pepperestFees)}</div>
              </li>
              <li className="d-flex flex justify-content-between items-center">
                <div>Total Cost</div>
                <div>{currencyFormatter.format(totalCost)}</div>
              </li>
            </ul>
          </section>
          <footer className="thirdpartyLayout-card__base">
            <div className="thirdpartyLayout-card__base-actions">
              <button onClick={() => history.goBack()} className="button">
                Go back
              </button>
              <button
                onClick={() => {
                  window.location.assign(payurl);
                  // window.open(payurl);
                }}
                className="button"
              >
                Check Out
              </button>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    thirdPartyDetails: state.orders.thirdPartyOrder,
    loading: state.orders.loading,
    user: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getThirdpartyTrxRef: (token, externalTrxRef) =>
      dispatch(actions.getExternalTrxRef(token, externalTrxRef)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyOrderTransaction);
