import { LoadingListItem } from "components/blocks";
import CreateDiscount from "components/dashboardComponent/CreateDiscount";
import DeleteDiscount from "components/dashboardComponent/DeleteDiscount";
import ModalComponent from "components/dashboardComponent/ModalComponent";
import Pagination from "components/dashboardComponent/Pagination";
import { SearchBar } from "components/dashboardComponent/SearchBar";
import { EditIcon, TrashIcon } from "components/vectors";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadProduct } from "store/actions";
import {
  addDiscountComplete,
  getProductDiscounts,
  loadAllProducts,
} from "store/actions/products";

const Discounts = () => {
  const dispatch = useDispatch();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [createDiscount, setCreateDiscount] = useState(false);
  const {
    allproducts,
    addedDiscount,
    discounts,
    loadingDiscounts,
    deletedDiscount,
  } = useSelector((state) => state.products);
  const { userInfo, token } = useSelector((state) => state.auth);

  const fetchProductDiscounts = (page) => {
    const params = {
      page: page,
      keyword: keyword,
    };
    dispatch(getProductDiscounts(params));
  };

  useEffect(() => {
    fetchProductDiscounts(1);
  }, [keyword, addedDiscount, deletedDiscount]);

  useEffect(() => {
    const getProducts = (page) => {
      dispatch(loadAllProducts(token, userInfo));
    };
    getProducts();
  }, [addedDiscount, deletedDiscount]);

  useEffect(() => {
    if (addedDiscount) {
      setCreateDiscount(false);
      dispatch(addDiscountComplete());
    }
  }, [addedDiscount]);

  useEffect(() => {
    if (deletedDiscount) {
      setDeleteModal(false);
    }
  }, [deletedDiscount]);

  const handleEditDiscount = (discount) => {
    setSelectedDiscount(discount);
    setCreateDiscount(true);
  };
  const handleCreateDiscount = () => {
    setSelectedDiscount(null);
    setCreateDiscount(true);
  };
  const handleDeleteDiscount = (discount) => {
    setSelectedDiscount(discount);
    setDeleteModal(true);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(discounts?.data?.map((discount) => discount.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item != id));
    }
  };

  let timeout = useRef();
  const handleSearch = (event) => {
    event.persist();
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setKeyword(event.target.value);
    }, 2000);
  };

  return (
    <div className="discounts">
      <div className="title__wrapper">
        <p className="page__title"> Discounts</p>

        <button
          className="button button--purple button__content"
          onClick={handleCreateDiscount}
        >
          Create Discount
        </button>
      </div>
      <div className="search__product__action__wrapper">
        <div className="search__wrapper">
          <SearchBar placeholder="Search in discount" onChange={handleSearch} />
        </div>
        <div className="actions__wrapper">
          <div
            className={`cursor__pointer ${
              isCheckAll || isCheck.length > 1 ? "" : "disabled"
            }`}
            onClick={() => setDeleteModal(true)}
          >
            {" "}
            <TrashIcon width={16} height={17} /> Delete
          </div>
        </div>
      </div>

      {loadingDiscounts ? (
        <ul>
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
        </ul>
      ) : (
        <section className="table__wrapper">
          <table className="product__table ">
            <thead>
              <tr>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      onClick={handleSelectAll}
                      className="form_input"
                    />
                    <span></span>
                  </label>
                </th>
                <th>Discount Name</th>
                <th>Value</th>
                <th>Validity</th>
                <th width="170px">Action</th>
              </tr>
            </thead>
            <tbody>
              {discounts && discounts?.data?.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    Product discounts will appear here
                  </td>
                </tr>
              ) : (
                <>
                  {discounts?.data?.map((discount, index) => (
                    <tr key={index}>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            key={discount?.id}
                            id={discount?.id}
                            checked={isCheck.find(
                              (item) => item == discount?.id
                            )}
                            onChange={handleClick}
                            className="form_input"
                          />
                          <span></span>
                        </label>
                      </td>

                      <td>{discount?.discount_name}</td>
                      <td>{discount?.discount_value}</td>
                      <td>
                        {`${discount?.start_date} - ${discount?.end_date}`}{" "}
                      </td>
                      <td>
                        <div className="discount__action">
                          <span
                            className="edit__action"
                            onClick={() => handleEditDiscount(discount)}
                          >
                            <EditIcon color="#5D5FCC" />
                          </span>
                          <span
                            className="delete__action"
                            onClick={() => handleDeleteDiscount(discount)}
                          >
                            <TrashIcon color="#5E60CE" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={discounts?.meta?.current_page}
            totalCount={discounts?.meta?.total}
            pageSize={discounts?.meta?.per_page}
            onPageChange={(page) => fetchProductDiscounts(page)}
          />
        </section>
      )}
      <ModalComponent show={createDiscount} centered size="md">
        <CreateDiscount
          close={() => setCreateDiscount(false)}
          selectedDiscount={selectedDiscount}
        />
      </ModalComponent>

      <DeleteDiscount
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        discount={isCheckAll || isCheck.length > 1 ? isCheck : selectedDiscount}
        isMultiple={isCheckAll || isCheck.length > 1}
      />
    </div>
  );
};

export default Discounts;
