import mixPanel from "mixpanel-browser";

export const mixPanelUserProperty = (trackType, userDetails) => {
  mixPanel.track(trackType, userDetails);
};

export const mixPanelAuth = (endpoint, user) => {
  let endPointType = {
    login: "logged_in",
    minimal: "unique_signup",
    register: "account_created",
    social: "social_login",
    loginFromSocial: "google_social_login",
  };

  mixPanel.track(endPointType[endpoint] || "Authenticated", user);
};
