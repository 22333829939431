import InputField from "components/blocks/InputField";
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect } from "react";
import InputGroup from "components/blocks/InputGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  getProductCategories,
  getProductWeightUnits,
  publishSingleProduct,
  publishSingleProductComplete,
} from "store/actions/products";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "components/blocks";
import { useHistory, useLocation } from "react-router-dom";
import BreadCrumb from "components/shared/BreadCrumb";
import ProductVariant from "components/dashboardComponent/ProductVariant";
import { hasEmptyProperties, notify } from "libs/utils";
import UploadIcon from "components/vectors/UploadIcon";
import ImageUploadPreview from "components/dashboardComponent/ImageUploadPreview";
import { useCallback } from "react";
import { trackEvent } from "libs/utils/customer-io";

function AddProduct() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state.auth);
  const {
    productCategories,
    weightUnit,
    publishingProduct,
    publishProductSuccess,
  } = useSelector((state) => state.products);
  const [files, setFiles] = useState(null);
  const [imageError, setImageError] = useState(false);
  const fileTypes = ["png", "jpg", "jpeg"];
  const [showVariant, setShowVariant] = useState(false);
  const [variants, setVariants] = useState([]);
  const [productImages, setProductImages] = useState(null);

  const crumbs = [
    {
      name: "Back to products",
      path: "/dashboard/products",
    },
    {
      name: "Add product",
    },
  ];

  const handleVariant = useCallback(
    (variants) => {
      setVariants(variants);
    },
    [variants]
  );

  const storeSchema = yup.object().shape({
    productname: yup.string().required("Product name is required"),
    description: yup.string().required("Description is required"),
    price: yup
      .number()
      .typeError("Provide a valid price")
      .min(1)
      .required("price is required"),
    category_id: yup.string().required("Product Category is required"),
    weight: yup
      .number()
      .typeError("Only numbers allowed")
      .required("Weight is required"),
    discount_percent: yup.number().typeError("Only numbers allowed"),
    weight_unit: yup.string().required("Weight unit is required"),
    //productType: yup.string().required("Product type is required"),
    quantity: yup
      .number()
      .typeError("Only numbers allowed")
      .min(1)
      .required("Quantity is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(storeSchema),
    mode: "all",
  });

  const handleChange = useCallback(
    (files) => {
      if (files) {
        setFiles(files);
      } else {
        setImageError("Product image is required");
      }
    },
    [files]
  );

  const handleFileChange = useCallback(
    (files) => {
      setProductImages(files);
      if (files) {
        setImageError("");
      }
    },
    [files]
  );

  const submit = (data) => {
    if (!files) {
      notify("Product image is required", "error");
      setImageError("Product image is required");
      return;
    }

    data.product_image = productImages[0].file;
    data.currency = "NGN";
    data.deliveryperiod = "2";
    data.merchantID = userInfo.customerID;

    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    if (variants.length > 0) {
      let extraVariants = [];

      if (hasEmptyProperties(variants)) {
        notify("Variants can not be empty", "error");
        return;
      } else {
        variants.map((item) => {
          const { variant, name } = item;
          extraVariants.push({ name: name, variant: variant });
          return { name, variant };
        });

        extraVariants.forEach((item, index) => {
          formData.append(`product_variants[${index}][name]`, item.name);
          item.variant.forEach((variant, variantIndex) => {
            formData.append(
              `product_variants[${index}][variant][${variantIndex}]`,
              variant
            );
          });
        });
      }
    }
    const optionalImages = productImages?.slice(1, productImages.length);
    if (optionalImages?.length > 0) {
      optionalImages.map((item, index) => {
        formData.append(`optional_images[${index}]`, item.file);
      });
    }

    dispatch(publishSingleProduct(token, userInfo, formData));
  };

  useEffect(() => {
    dispatch(getProductWeightUnits(token, userInfo));
    dispatch(getProductCategories(token, userInfo));
  }, []);

  useEffect(() => {
    if (publishProductSuccess) {
      trackEvent("upload_product");
      dispatch(publishSingleProductComplete());
      history.push("/dashboard/products");
    }
  }, [publishProductSuccess]);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <div className="products">
      <BreadCrumb crumbs={crumbs} />
      <p className="page__title">Add Product</p>
      <form onSubmit={handleSubmit(submit)} onKeyDown={(e) => checkKeyDown(e)}>
        <div className="row row__wrapper">
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="productname"
                label="Product Name"
                name="productname"
                placeHolder="Product Name"
                register={register}
                error={errors.productname?.message}
                type="text"
              />
            </div>
          </div>
          <div className="col">
            <div className="form__wrapper">
              <InputGroup
                require={true}
                id="price"
                label="Product Price (Numbers only)"
                name="price"
                placeholder="1000"
                register={register}
                error={errors.price?.message}
                type="number"
              />
            </div>
          </div>
        </div>

        <section className="row">
          <div className="form__wrapper">
            <InputField
              require={true}
              id="description"
              label="Product Description"
              name="description"
              placeHolder="Product Description"
              register={register}
              error={errors.description?.message}
              type="textarea"
            />
          </div>
        </section>

        <div className="row">
          <div>
            <label className="form__label">
              Product Images <span className="require__field">*</span>
            </label>
          </div>
          <section className="file__upload__wrapper">
            <div
              className={`form__wrapper border__wrapper ${
                imageError ? "border-danger" : ""
              }`}
            >
              <FileUploader
                handleChange={handleChange}
                multiple={true}
                types={fileTypes}
              >
                <div className="file__uploader__wrapper ">
                  <UploadIcon />
                  <div>
                    <p className="title">
                      Drag and drop images here or{" "}
                      <span className="upload__button">
                        browse on your computer
                      </span>
                    </p>

                    <p className="image__sizing">
                      Recommended image size is 1280x780px
                    </p>
                  </div>
                </div>
              </FileUploader>
            </div>
          </section>
          <div className="error__message">{imageError && imageError}</div>
        </div>
        <ImageUploadPreview handleFileChange={handleFileChange} files={files} />

        {/* <div className="uploaded__image__preview__wrapper">
          {images.map((image, index) => (
            <div key={image.id} className="uploaded__image__preview">
              <img src={image.preview} alt="Preview" />
              <div className="image-actions">
                <button onClick={() => handleRemove(image.id)}>Remove</button>
                {index > 0 && (
                  <button onClick={() => handleSwap(index, index - 1)}>
                    Move Up
                  </button>
                )}
                {index < images.length - 1 && (
                  <button onClick={() => handleSwap(index, index + 1)}>
                    Move Down
                  </button>
                )}
              </div>
            </div>
          ))}
        </div> */}
        <section className="row row__wrapper">
          <div className="col">
            <div className="form__wrapper ">
              <InputField
                require={true}
                id="quantity"
                label="Product Quantity"
                name="quantity"
                placeHolder="0"
                register={register}
                error={errors.quantity?.message}
                type="number"
              />
            </div>
          </div>
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="category_id"
                label="Product Category"
                name="category_id"
                placeHolder="Product Category"
                register={register}
                options={productCategories?.map((item) => ({
                  label: item.category,
                  value: item.id,
                }))}
                error={errors.category_id?.message}
                type="select"
              />
            </div>
          </div>
        </section>

        <div className="row row__wrapper">
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="weight"
                label="Product Weight"
                name="weight"
                placeHolder="0"
                register={register}
                error={errors.weight?.message}
                type="number"
              />
            </div>
          </div>
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="weight_unit"
                label="Product weight Unit"
                name="weight_unit"
                placeHolder="Product Weight Unit"
                register={register}
                options={weightUnit?.map((unit) => ({
                  label: unit,
                  value: unit,
                }))}
                error={errors.weight_unit?.message}
                type="select"
              />
            </div>
          </div>
        </div>

        {/* <div className="row gap-5">
          <div className="col">
          <div className="form__wrapper">
            <InputField
              id="productType"
              label="Product Type"
              name="productType"
              placeHolder="Product Type "
              register={register}
              options={[
                { value: "Tangible", label: "Tangible" },
                // { value: "Intangible", label: "Intangible" },
              ]}
              error={errors.productType?.message}
              type="select"
            />
          </div>
          </div>
          <div className="col">
          <div className="form__wrapper">
            <InputField
              id="category_id"
              label="Product Category"
              name="category_id"
              placeHolder="Product Category"
              register={register}
              options={productCategories?.map((item) => ({
                label: item.category,
                value: item.id,
              }))}
              error={errors.category_id?.message}
              type="select"
            />
          </div>
          </div>
        </div> */}

        <div className="row">
          <section className="more__product__detail">
            <p className="product__variant">
              <span>Product Variants</span> <i>(Optional)</i>
            </p>
            <p className="detail">
              Do you need to add more details like colours, variations,size for
              your product? Click{" "}
              <span className="add__more" onClick={() => setShowVariant(true)}>
                Add More Details
              </span>{" "}
              to add product variants
            </p>
          </section>
          {showVariant ? (
            <ProductVariant handleVariant={handleVariant} />
          ) : null}
          <div className="col-md-6">
            <button
              className="button button--purple mt-4"
              disabled={publishingProduct}
              type="submit"
            >
              {publishingProduct ? <Loader /> : <>Upload product</>}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
