// MetaTag.js

import React from "react";
import { Helmet } from "react-helmet";

const MetaTag = ({ title, description, keywords, route }) => {
  const url =
    route == "index" ? "https://peppa.io" : `https://peppa.io/${route}`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:url" content={`${url}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={`${url}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <link rel="canonical" href={`${url}`}></link>
    </Helmet>
  );
};

export default MetaTag;
