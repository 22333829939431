import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Payments,
  PaymentsErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { notify, setCookie } from "libs/utils";
import axios from "axios";

export const loadPayments = (token, user, status, extraParams = {}) => {
  return (dispatch) => {
    dispatch(loadingPayments(status));
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      merchantID: user.customerID,
      status: status,
      ...extraParams,
    };
    PepperestAxios.get(Payments.TRANSACTIONS, { params, headers })
      .then((response) => {
        const payments = response.data.payments.data;
        const meta = response.data.payments.meta;
        const links = response.data.payments.links;
        dispatch(loadedPayment(payments, meta, links, status));
      })
      .catch((error) => {
        dispatch(
          failedToLoadPayment(status, PaymentsErrorMessages.getHistoryFailed)
        );
      });
  };
};

export const getPayments = (type) => {
  return (dispatch, getState) => {
    const { userInfo, token } = getState().auth;
    dispatch({ type: actionTypes.GET_PAYMENTS });
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
    };

    PepperestAxios.get(`${Payments.PAYMENTS}/${type}`, { headers })
      .then((response) => {
        const payments = response.data.payments.data;
        const meta = response.data.payments.meta;

        dispatch({ type: actionTypes.GET_PAYMENTS_SUCCESS, payments, meta });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response.data.message
          : "Couldn't connect to Server";
        dispatch({ type: actionTypes.GET_PAYMENTS_FAILED, error: message });
      });
  };
};

export const getStorePayments = (type) => {
  return (dispatch, getState) => {
    const { userInfo, token } = getState().auth;
    dispatch({ type: actionTypes.GET_STORE_PAYMENTS });
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
    };

    PepperestAxios.get(`${Payments.STORE_PAYMENTS}/${type}`, { headers })
      .then((response) => {
        const payments = response.data.orders.data;
        const meta = response.data.orders.meta;

        dispatch({
          type: actionTypes.GET_STORE_PAYMENTS_SUCCESS,
          payments,
          meta,
        });
      })
      .catch((error) => {
        const message = error.response.data
          ? error.response.data.message
          : "Couldn't connect to Server";
        dispatch({
          type: actionTypes.GET_STORE_PAYMENTS_FAILED,
          error: message,
        });
      });
  };
};

export const loadingPayments = (status) => {
  return {
    type: actionTypes.LOADING_PAYMENTS,
    status: status,
  };
};

export const loadedPayment = (payments, meta, links, status) => {
  const statusUpdate = {
    payments,
    meta,
    links,
    loading: false,
    loaded: true,
    error: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_PAYMENTS,
    status: status,
    statusUpdate: statusUpdate,
  };
};
export const failedToLoadPayment = (status, error) => {
  return {
    type: actionTypes.LOADING_PAYMENTS,
    status: status,
    error: error,
  };
};

export const getIncomingPayment = (orderRef) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_INCOMING_PAYMENT });

    const params = {
      orderRef,
    };
    PepperestAxios.get(Payments.INCOMING_PAYMENT, { params })
      .then((response) => {
        const { order, token, userInfo } = response.data;
        setCookie("vendor_token", token?.access_token);

        dispatch({
          type: actionTypes.GET_INCOMING_PAYMENT_SUCCESS,
          order,
          userInfo,
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error?.response?.data?.ResponseMessage
          : "We couldn't make this happen at the moment, Try again";
        dispatch({
          type: actionTypes.GET_INCOMING_PAYMENT_FAIL,
          error: message,
        });
      });
  };
};

export const trackIncomingPayment = (orderRef, vendorToken) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.TRACK_INCOMING_PAYMENT });
    const headers = {
      Authorization: `Bearer ${vendorToken}`,
    };
    const params = {
      orderRef,
    };
    PepperestAxios.get(Payments.TRACK_INCOMING_PAYMENT, { params, headers })
      .then((response) => {
        const { orderEvents, order } = response.data;
        dispatch({
          type: actionTypes.TRACK_INCOMING_PAYMENT_SUCCESS,
          orderEvents,
          releaseCode: order?.release_code,
          order,
        });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.TRACK_INCOMING_PAYMENT_FAIL, error });
      });
  };
};

export const declineIncomingPayment = (orderRef, vendorToken) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DECLINE_INCOMING_PAYMENT });
    const headers = {
      Authorization: `Bearer ${vendorToken}`,
    };
    const params = {
      orderRef,
    };

    PepperestAxios.get(Payments.DECLINE_PAYMENT, { params, headers })
      .then((response) => {
        const { orderEvents, order } = response.data;
        dispatch({
          type: actionTypes.DECLINE_INCOMING_PAYMENT_SUCCESS,
        });
        if (order.status == "Declined") {
          notify("Order has been declined", "success");
        }
      })
      .catch((error) => {
        console.log({ error });
        dispatch({ type: actionTypes.DECLINE_INCOMING_PAYMENT_FAIL, error });
      });
  };
};
