import React from 'react'

const StockIcon = ({width=16,height=17}) => {
  return (
    <svg width={width} height={height} 
    viewBox="0 0 16 17" fill="none" 
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2426 4.25733C12.7998 4.81448 13.2417 5.47592 13.5433 6.20387C13.8448 6.93182 14 7.71204 14 8.49997C14 9.2879 13.8448 10.0681 13.5433 10.7961C13.2417 11.524 12.7998 12.1855 12.2426 12.7426C11.6855 13.2998 11.0241 13.7417 10.2961 14.0432C9.56815 14.3448 8.78793 14.5 8 14.5C7.21207 14.5 6.43185 14.3448 5.7039 14.0432C4.97595 13.7417 4.31451 13.2998 3.75736 12.7426C2.63214 11.6174 2 10.0913 2 8.49997C2 6.90867 2.63214 5.38255 3.75736 4.25733C4.88258 3.13211 6.4087 2.49997 8 2.49997C9.5913 2.49997 11.1174 3.13211 12.2426 4.25733ZM10.7946 12.2373L4.26202 5.70532C3.5893 6.60414 3.26311 7.71533 3.34326 8.83516C3.4234 9.95499 3.90451 11.0084 4.69837 11.8023C5.49223 12.5961 6.54565 13.0772 7.66548 13.1574C8.7853 13.2375 9.89649 12.9113 10.7953 12.2386L10.7946 12.2373ZM11.3 5.19999C12.0934 5.99358 12.5743 7.04641 12.6547 8.16568C12.7352 9.28495 12.4097 10.3957 11.738 11.2946L5.20468 4.76199C6.10357 4.08993 7.21452 3.76429 8.33398 3.84473C9.45344 3.92518 10.5064 4.40632 11.3 5.19999Z" fill="#B0B0B0"/>
    </svg>
    
  )
}

export default StockIcon