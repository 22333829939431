import React from "react";

const InputField = ({
  type,
  register,
  error,
  label,
  id,
  options,
  placeHolder,
  require,
  ...inputProps
}) => (
  <div className="mb-3 d-flex flex-column ">
    {label && (
      <label htmlFor={id} className={`form__label ${error ? "error" : ""}`}>
        {label}
        {require ? <span className="require__field">*</span> : null}{" "}
      </label>
    )}
    {type == "select" ? (
      <select
        className={`form__input cursor__pointer ${error ? "error" : ""}`}
        ref={register}
        id={id}
        {...inputProps}
      >
        <option value="">--Select--</option>
        {options?.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    ) : type == "textarea" ? (
      <textarea
        rows={6}
        type={type}
        ref={register}
        id={id}
        {...inputProps}
        className={`form__textarea ${error ? "error" : ""}`}
      ></textarea>
    ) : (
      <input
        className={`form__input ${error ? "error" : ""}`}
        type={type}
        ref={register}
        placeholder={placeHolder}
        id={id}
        {...inputProps}
      />
    )}
    {error && <div className="error__message">{error}</div>}
  </div>
);

export default InputField;
