import React from "react";
import PropTypes from "prop-types";
import { getStringHash } from "libs/utils";

const SelectInputWithoutLabel = ({
  id,
  value,
  name,
  defaultValue,
  options,
  classNames,
  onChange,
  title,
  required
}) => (
  <div className={`nsForm-select ${classNames || ""}`}>
    <select
      name={name}
      id={id}
      value={value}
      required={required}
      onChange={(event) => {
        onChange(event);
      }}
    >
      {defaultValue ? <option value="">{defaultValue}</option> : null}
      {options.map((option, index) => (
        <option
          title={
            option.label.includes("Box")
              ? `Height = ${option.height}, width = ${option.width}, length = ${option.length}`
              : null
          }
          key={getStringHash(index)}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

SelectInputWithoutLabel.defaultProps = {
  classNames: "",
};

SelectInputWithoutLabel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  title: PropTypes.string,
};

export default SelectInputWithoutLabel;
