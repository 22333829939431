import React from 'react'

const LeftChevron = props => {
    return (
      <svg
        className="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="16"
        viewBox="0 0 9 16"
      >
        <path
          fill={props.color?props.color:"#DFE2E8"}
          fillRule="nonzero"
          d="M8.345 15.347a.531.531 0 0 0 0-.75l-6.583-6.59 6.583-6.603a.531.531 0 0 0 0-.749.528.528 0 0 0-.747 0L.655 7.62a.518.518 0 0 0-.155.374c0 .13.052.272.155.375l6.943 6.965c.206.22.541.22.747.013z"
        />
      </svg>
    );
}

export default LeftChevron