import { CheckedIcon } from "components/vectors";
import React from "react";

const PlanListItem = ({ title }) => {
  return (
    <div className="plan__list__item">
      <CheckedIcon color="#5D5FCC" />
      <span className="title">{title}</span>
    </div>
  );
};

export default PlanListItem;
