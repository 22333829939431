import React from 'react'

const ArrowDown = ({width=15,height=8}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 8" 
    fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.7046 1L7.71813 7L1.73066 1" stroke="#646A86" 
strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default ArrowDown