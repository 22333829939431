import React from "react";
import { Link } from "react-router-dom";
import { CartSVG, OutOfStockSVG } from "../../buyer-assets";
import { useCartContext } from "../../buyer-context/cartContext";
import styles from "./productCard.module.css";

const ProductCard = ({ product }) => {
  const { dispatch } = useCartContext();
  return (
    <div className={styles["productCard"]}>
      <Link
        to={`/product/${product?.id}`}
        className={styles["productCard-link"]}
      >
        <div
          className={styles["productCard-header"]}
          style={{
            backgroundImage: `url(${product?.image_url})`,
            backgroundPosition: " center",
          }}
        >
          {parseInt(product?.quantity) > 0 ? (
            <button
              className={styles["add-cart"]}
              onClick={() =>
                dispatch({
                  type: "ADD",
                  payload: { product, quantity: 1 },
                })
              }
            >
              Add to Cart <CartSVG className={styles["cart-icon"]} />
            </button>
          ) : (
            <button className={styles["no-stock"]}>
              Out of stock{" "}
              <OutOfStockSVG className={styles["nostock-icon"]} />
            </button>
          )}
        </div>

        <div className={styles["productCard-body"]}>
          <p className={styles["product-name"]}>{product?.productName}</p>

          {product?.discounted_price ? (
            <>
              <div className={styles["product-price-wrapper"]}>
                <p className={styles["discounted-price"]}>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: product?.currency || "NGN",
                  }).format(parseFloat(product?.amount))}
                </p>

                <p className={styles["product-price"]}>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: product?.currency || "NGN",
                  }).format(parseFloat(product?.discounted_price))}
                </p>
              </div>
              <p className={styles["discount-amount"]}>
                {`-${product?.discount_percent}%`}
              </p>
            </>
          ) : (
            <p className={styles["product-price"]}>
              {new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: product?.currency || "NGN",
              }).format(parseFloat(product?.amount))}
            </p>
          )}
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
