import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  overview: null,
  topCustomers: [],
  recentOrders: [],
  loading: true,
  error: null,
  salesReports: null,
  loadingCustomers: true,
  loadingOrders: true,
  loadingReports: true,
};

const loading = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};
const loadOverviewSuccess = (state, action) => {
  return updateObject(state, {
    overview: action.overview,
    loading: false,
    error: "",
  });
};

const loadOverviewFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const loadingTopCustomers = (state, action) => {
  return updateObject(state, {
    loadingCustomers: true,
  });
};
const loadingRecendOrders = (state, action) => {
  return updateObject(state, {
    loadingOrders: true,
  });
};
const loadingSalesReports = (state, action) => {
  return updateObject(state, {
    loadingReports: true,
  });
};

const loadTopCustomersSuccess = (state, action) => {
  return updateObject(state, {
    topCustomers: action.topCustomers,
    loadingCustomers: false,
    error: "",
  });
};

const loadOrdersSuccess = (state, action) => {
  return updateObject(state, {
    recentOrders: action.recentOrders,
    loadingOrders: false,
    error: "",
  });
};

const loadReportsSuccess = (state, action) => {
  return updateObject(state, {
    salesReports: action.salesReports,
    loadingReports: false,
    error: "",
  });
};

const loadReportsFail = (state, action) => {
  return updateObject(state, {
    loadingReports: false,
    error: action.error,
  });
};

const downloadReportSuccess = (state, action) => {
  return updateObject(state, {
    downloading: false,
    download_report_url: action.download_link,
  });
};

const downloadReportComplete = (state, action) => {
  return updateObject(state, {
    downloading: false,
    download_report_url: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_OVERVIEW:
      return loading(state, action);
    case actionTypes.GET_DASHBOARD_OVERVIEW_SUCCESS:
      return loadOverviewSuccess(state, action);
    case actionTypes.GET_DASHBOARD_OVERVIEW_FAIL:
      return loadOverviewFail(state, action);
    case actionTypes.GET_TOP_CUSTOMERS:
      return loadingTopCustomers(state, action);
    case actionTypes.GET_TOP_CUSTOMERS_SUCCESS:
      return loadTopCustomersSuccess(state, action);
    case actionTypes.GET_RECENT_ORDERS:
      return loadingRecendOrders(state, action);
    case actionTypes.GET_RECENT_ORDERS_SUCCESS:
      return loadOrdersSuccess(state, action);
    case actionTypes.GET_SALES_REPORT:
      return loadingSalesReports(state, action);
    case actionTypes.GET_SALES_REPORTS_SUCCESS:
      return loadReportsSuccess(state, action);
    case actionTypes.GET_SALES_REPORT_FAIL:
      return loadReportsFail(state, action);
    case actionTypes.DOWNLOAD_SALES_REPORT_SUCCESS:
      return downloadReportSuccess(state, action);
    case actionTypes.DOWNLOAD_SALES_REPORT_COMPLETE:
      return downloadReportComplete(state, action);

    default:
      return state;
  }
};

export default reducer;
