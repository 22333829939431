import React from "react";

const HamburgerIcon = ({ transparent = "#fff" }) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="19"
    viewBox="0 0 24 19"
  >
    <g fill="none" fillRule="evenodd" stroke={transparent ? "#fff" : "#5E60CE"} strokeWidth="2">
      <path d="M0 .5L24 .5M0 8.5L24 8.5M0 16.5L24 16.5" transform="translate(0 1)" />
    </g>
  </svg>
);

export default HamburgerIcon;
