import React from "react";

function index() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0001 28.5451H7.92007C3.29341 28.5451 1.36007 25.2384 3.60007 21.1984L7.76007 13.7051L11.6801 6.66508C14.0534 2.38508 17.9467 2.38508 20.3201 6.66508L24.2401 13.7184L28.4001 21.2117C30.6401 25.2517 28.6934 28.5584 24.0801 28.5584H16.0001V28.5451Z"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5869 26.6668L16.0002 17.8535L3.41357 26.6668"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 4V17.8533"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default index;
