import React from "react";
import PropTypes from "prop-types";
// import { NavLink as Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { mixPanelUserProperty } from "libs/mixPanel";

const NavLink = ({ url, title, classNames, exact }) => {
  if (title === "Support") {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          mixPanelUserProperty("support_clicked", {});
        }}
        href={url}
        className={classNames}
      >
        {title}
      </a>
    );
  }
  return (
    <Link to={url} className={classNames} exact={`${exact}`}>
      {title}
    </Link>
  );
};

NavLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  classNames: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
};

export default NavLink;
