import { Loader } from "components/blocks";
import { notify } from "libs/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resendVerificationEmail } from "store/actions/userAccount";

const VerifyEmail = () => {
  const { resendingEmail, userProfile } = useSelector(
    (state) => state.userAccount
  );
  const { token, userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const done = () => {
    if (!userProfile?.email_verified) {
      notify("Please verify your email to continue", "warning");
    } else {
      history.push("?tab=bank-detail");
    }
  };
  const resend = () => {
    dispatch(resendVerificationEmail(token, userInfo));
  };
  return (
    <main>
      {userProfile.email_verified ? (
        <>
          <p className="title">Email Verified</p>
          <p className="description">
            Your Email has been verified, click next to proceed to next step
          </p>
          <div className="button__wrapper justify-content-center">
            <button
              className="button button--purple button__content"
              onClick={done}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="title">Verify your email address</p>
          <p className="description">
            An email verification has been sent to your mail, kindly click the
            button to verfiy your mail
          </p>
          <div className="button__wrapper justify-content-center">
            <button
              className="button button--purple button__content"
              onClick={done}
            >
              Done
            </button>
            <button
              onClick={resend}
              className="button button--purple-outline button__content"
            >
              {resendingEmail ? <Loader /> : "Resend"}
            </button>
          </div>
        </>
      )}
    </main>
  );
};

export default VerifyEmail;
