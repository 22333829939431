import * as Yup from "yup";
import { CouponInput } from "buyer-components/CustomInput";
import { Formik } from "formik";
import React from "react";
import { applyOrderCoupon } from "buyer-api";
import { useMutation } from "@tanstack/react-query";
import styles from "./orderCoupon.module.css";
import { useCartContext } from "buyer-context/cartContext";
import {
  fireSwalError,
  fireSwalSuccess,
  getFromStorage,
} from "buyer-constants";

const OrderCoupon = ({ cart_id, disabled }) => {
  const { dispatch } = useCartContext();
  const token = getFromStorage("userToken");

  const {
    isLoading: applyingCoupon,
    mutateAsync: applyCouponFN,
    error: couponError,
  } = useMutation(applyOrderCoupon);
  const couponSchema = Yup.object().shape({
    coupon_code: Yup.string().required("Coupon code is required"),
  });

  const applyCoupon = async (values) => {
    const params = {
      ...values,
      cart_id,
    };
    try {
      const result = await applyCouponFN(params, token);
      if (result?.ResponseStatus == "Successful") {
        dispatch({
          type: "APPLY-ORDER-COUPON",
          payload: result.couponValue,
        });
        fireSwalSuccess(result?.ResponseMessage);
      }
    } catch (error) {
      fireSwalError(error?.message);
      console.log({ error });
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          coupon_code: "",
        }}
        validationSchema={couponSchema}
        onSubmit={(values, actions) => {
          applyCoupon(values);
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className={""}>
              <h4 className={styles.couponLabel}>Discount Coupon</h4>
              <p className={styles.couponDescription}>
                Have a discount coupon? Enter code below
              </p>
            </div>
            <CouponInput
              loading={applyingCoupon}
              onClick={handleSubmit}
              name="coupon_code"
              disabled={disabled}
              onChange={handleChange}
              value={values.coupon_code}
              placeholder="Enter coupon code"
              title={disabled ? "Applied" : "Apply"}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default OrderCoupon;
