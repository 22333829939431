import ReactPixel from "react-facebook-pixel";

export const FbPixelPageView = () => {
  ReactPixel.pageView(); // For tracking page view
};

export const FbPixelStandardEvent = (eventType) => {
  return ReactPixel.track("track", eventType);
};

export const FbTrackCustomEvent = (eventType, data = {}) => {
  return ReactPixel.trackCustom(eventType, data);
};
