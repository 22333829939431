import React from "react";

function TruckIcon({ size = 22, color = "#FE8F01" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
    >
      <path
        d="M24.5 5.11V19.6525C24.5 21.4375 23.0475 22.89 21.2625 22.89H5.25C4.2875 22.89 3.5 22.1025 3.5 21.14V9.9575C3.5 6.3875 6.3875 3.5 9.9575 3.5H22.8725C23.7825 3.5 24.5 4.2175 24.5 5.11Z"
        fill={color}
      />
      <path
        d="M37.625 27.125C38.115 27.125 38.5 27.51 38.5 28V29.75C38.5 32.655 36.155 35 33.25 35C33.25 32.1125 30.8875 29.75 28 29.75C25.1125 29.75 22.75 32.1125 22.75 35H19.25C19.25 32.1125 16.8875 29.75 14 29.75C11.1125 29.75 8.75 32.1125 8.75 35C5.845 35 3.5 32.655 3.5 29.75V26.25C3.5 25.2875 4.2875 24.5 5.25 24.5H21.875C24.29 24.5 26.25 22.54 26.25 20.125V10.5C26.25 9.5375 27.0375 8.75 28 8.75H29.47C30.73 8.75 31.885 9.4325 32.515 10.5175L33.635 12.4775C33.7925 12.7575 33.5825 13.125 33.25 13.125C30.835 13.125 28.875 15.085 28.875 17.5V22.75C28.875 25.165 30.835 27.125 33.25 27.125H37.625Z"
        fill={color}
      />
      <path
        d="M14 38.5C15.933 38.5 17.5 36.933 17.5 35C17.5 33.067 15.933 31.5 14 31.5C12.067 31.5 10.5 33.067 10.5 35C10.5 36.933 12.067 38.5 14 38.5Z"
        fill={color}
      />
      <path
        d="M28 38.5C29.933 38.5 31.5 36.933 31.5 35C31.5 33.067 29.933 31.5 28 31.5C26.067 31.5 24.5 33.067 24.5 35C24.5 36.933 26.067 38.5 28 38.5Z"
        fill={color}
      />
      <path
        d="M38.5 21.9275V24.5H33.25C32.2875 24.5 31.5 23.7125 31.5 22.75V17.5C31.5 16.5375 32.2875 15.75 33.25 15.75H35.5075L38.045 20.195C38.3425 20.72 38.5 21.315 38.5 21.9275Z"
        fill={color}
      />
    </svg>
  );
}

export default TruckIcon;
