import React from "react";
import PropTypes from "prop-types";

import { LeftChevron } from "components/vectors";
import { PepperestContext } from "components/helpers/constant";
import { connect } from "react-redux";

const InstagramBusinessProductsSelect = (props) => {
  return (
    <div className="instagram-page__main instagram-page__main--alt col-12">
      <div className="instagram-page__main--header instagram-page__main--header-alt">
        <div className="instagram-page__main--control">
          <LeftChevron />
          <p className="text--smallest text--gray">Back</p>
        </div>
        <p className="mx-auto">{props.page?.name}</p>
      </div>
      <ul className="instagram-page__main-list">
        {props.items.map((item) => (
          <div className={`instagram-page__main-list-item`} key={item.id}>
            <div className="input-control">
              {/* <input type="checkbox" id="product1" name="instagram" /> */}
              <PepperestContext.Consumer>
                {(context) => (
                  <div
                    role="presentation"
                    className="button button-md button--orange mt-25"
                    onClick={() => {
                      context.updateShowPublishInstagramImageModal(true, item);
                    }}
                  >
                    Add Details
                  </div>
                )}
              </PepperestContext.Consumer>
              <label htmlFor="product1" />
            </div>
            <img className="instagram-page__main-list-item__avatar" src={item.url} alt="product" />
            <p className="text--smallest text--gray">Product {item.id}</p>
          </div>
        ))}
        {/* <div className='instagram-page__main-list-item'>
          <div className='list-footer__pagination'>
            <span className='list-footer__pagination-prev list-footer-text'>Previous</span>
            <ul className='d-flex flex-row'>
              <li className='list-footer__pagination-page-number list-footer-text'>1</li>
              <li className='list-footer__pagination-page-number list-footer-text'>2</li>
              <li className='list-footer__pagination-page-number list-footer-text'>3</li>
              <li className='list-footer__pagination-page-number list-footer-text list-footer-text-alt'>
                4
              </li>
            </ul>
            <span className='list-footer__pagination-next list-footer-text list-footer-text-alt'>
              Next
            </span>
          </div>
        </div> */}
      </ul>
      <div className="instagram-page__main--footer">
        <div className="button button-md button--neutral">CANCEL</div>
        <div
          className="button button-md button--orange"
          onClick={() => {
            props.onClick(false);
          }}
        >
          FINISH
        </div>
      </div>
    </div>
  );
};

InstagramBusinessProductsSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    items: state.products.items,
    page: state.products.page,
  };
};

export default connect(mapStateToProps)(InstagramBusinessProductsSelect);
