import WelcomeHeader from "components/dashboardComponent/WelcomeHeader";
import { LoaderProcessing } from "components/shared";
import { ShareIcon } from "components/vectors";
import CopyIcon from "components/vectors/CopyIcon";
import Share from "components/vectors/Share";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPayments, getStorePayments } from "store/actions/payments";

const CompletedPayments = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("mobile");
  const { allPayments, error, loadingPayments } = useSelector(
    (state) => state.payments
  );

  useEffect(() => {
    const getCompletedPayments = () => {
      dispatch(getPayments("completed"));
    };
    const getStoreFrontPayments = () => {
      dispatch(getStorePayments("completed"));
    };
    if (activeTab == "mobile") {
      getCompletedPayments();
    } else {
      getStoreFrontPayments();
    }
  }, [activeTab]);

  return (
    <main className="payments">
      {error ? (
        <div className="empty__state">
          <p>{error}</p>
        </div>
      ) : (
        <>
          <h4 className="title__text">Completed Payments</h4>
          <div className="payment__tabs">
            <p
              className={`tab ${
                activeTab == "mobile" ? "active__payment__type" : ""
              }`}
              onClick={() => setActiveTab("mobile")}
            >
              Mobile
            </p>
            <p
              className={`tab ${
                activeTab == "storefront" ? "active__payment__type" : ""
              }`}
              onClick={() => setActiveTab("storefront")}
            >
              Storefront
            </p>
          </div>
          {loadingPayments ? (
            <div className="my-5">
              <LoaderProcessing />
            </div>
          ) : (
            <section className="table__wrapper">
              <table className="product__table">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Date</th>
                    {activeTab === "mobile" ? (
                      <th>Description</th>
                    ) : (
                      <th>Payment Ref</th>
                    )}
                    <th>Amount</th>
                    <th>Status</th>
                    {activeTab === "mobile" ? (
                      <th>Release Code</th>
                    ) : (
                      <th>Buyer Phone</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {allPayments && allPayments?.data?.length > 0 ? (
                    <>
                      {allPayments.data.map((payment, index) => (
                        <tr key={index}>
                          <td>
                            <div className="customer__info">
                              <span className="buyer__image">
                                <img src="/assets/images/new-hero-image.png" />
                              </span>
                              {activeTab === "mobile" ? (
                                <span>
                                  <p>{payment?.buyerName}</p>
                                  <p className="buyer__email">
                                    {payment?.buyerEmail}
                                  </p>
                                </span>
                              ) : (
                                <span>
                                  <p>{payment?.customerName}</p>
                                  <p className="buyer__email">
                                    {payment?.customerEmail}
                                  </p>
                                </span>
                              )}
                            </div>
                          </td>
                          <td>{payment?.date}</td>
                          <td>
                            {activeTab === "mobile" ? (
                              <span>{payment?.description}</span>
                            ) : (
                              <span>{payment?.paymentRef}</span>
                            )}
                          </td>
                          <td>{`NGN${payment?.cost}`}</td>
                          <td
                            className={`payment__status status__${(payment?.status).toLowerCase()}`}
                          >
                            {payment?.status}
                          </td>
                          <td>
                            {activeTab === "mobile" ? (
                              <div className="release__code">
                                <div className="release__code__action">
                                  <CopyIcon />
                                </div>
                                <div className="release__code__action">
                                  <Share color="#495057" />
                                </div>
                              </div>
                            ) : (
                              <span>{payment?.buyerPhone}</span>
                            )}

                            {/* {" "}
                          <span className="text__primary cursor__pointer">
                            Generate Release Code
                          </span> */}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* <Pagination currentPage={meta?.current_page} 
                totalCount={meta?.total}
                pageSize={meta?.per_page} 
                onPageChange={page => getProducts(page)}
                /> */}
            </section>
          )}
        </>
      )}
    </main>
  );
};

export default CompletedPayments;
