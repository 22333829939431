import { Loader } from "components/blocks";
import PaymentCard from "components/dashboardComponent/PaymentCard";
import PlanCard from "components/dashboardComponent/PlanCard";
import { LoaderProcessing } from "components/shared";
import { LinkIcon } from "components/vectors";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBillingHistory } from "store/actions/userAccount";

const Plan = () => {
  const { userProfile, loading } = useSelector((state) => state.userAccount);
  const { billingHistory } = useSelector((state) => state.userAccount);
  const dispatch = useDispatch();

  const getPlanHistory = () => {
    dispatch(getBillingHistory());
  };

  useEffect(() => {
    getPlanHistory();
  }, []);

  return loading ? (
    <div className="loader__container">
      <LoaderProcessing />
    </div>
  ) : (
    <main className="plan">
      <h1 className="plan__header">Plan & Billing</h1>
      <section className="plan__wrapper">
        <PlanCard plan={userProfile?.merchantSubStatus ? "Premium" : "Basic"} />
        {/* <PaymentCard /> */}
      </section>
      <div className="billing__history__wrapper">
        <p className="title">Billing History</p>

        <div className="table__wrapper">
          <table className="product__table">
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Date</th>
                <th>Amount</th>
                <th width="40px">Status</th>
              </tr>
            </thead>
            <tbody>
              {billingHistory?.data?.length > 0 ? (
                <>
                  {billingHistory?.data?.map((bill, index) => (
                    <tr key={index}>
                      <td className="invoice__number">
                        #{bill?.invoice_number} <LinkIcon />
                      </td>
                      <td>{bill?.billing_date}</td>
                      <td>{bill?.amount}</td>
                      <td>
                        <span
                          className={`payment__status status__${(bill?.status).toLowerCase()}`}
                        >
                          {bill?.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td className="text-center py-3" colSpan={4}>
                    Billing history will appear here
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default Plan;
