import React from "react";
import productImage from "../../buyer-assets/img/Product pic.png";
import styles from './cartdetailrow.module.css'

const CartDetailRow = ({item}) => {
  return (
    <div className={styles.cartItem}>
      <div className={styles.itemRow}>
        <img src={item?.product?.image_url} className={styles.productImage} />
        <div className={styles.detail}>
          <p>{item?.product?.productName}</p>
          <p>Colour:</p>
        </div>
      </div>
      <p>Quantity:{item?.quantity}</p>
    </div>
  );
};

export default CartDetailRow;
