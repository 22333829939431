import React, { Component } from "react";

function Index(WrappedComponent, data = {}) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return (
        <div className="signUp">
          <div className="overlay"></div>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
}

export default Index;
