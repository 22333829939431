import ModalComponent from "components/dashboardComponent/ModalComponent";
import WelcomeHeader from "components/dashboardComponent/WelcomeHeader";
import DashboardLayout from "components/layouts/dashboardLayout";
import {
  CloseIcon,
  FacebookIcon,
  InstagramIcon,
  CopyIconStyled,
  TwitterIcon,
} from "components/vectors";
import PlusIcon from "components/vectors/PlusIcon";
import Share from "components/vectors/Share";
import WhatsappIcon from "components/vectors/WhatsappIcon";
import {
  handleCopy,
  shareOnFacebook,
  shareOnInstagram,
  shareOnTwitter,
  shareOnWhatsApp,
} from "libs/utils";
import { trackEvent } from "libs/utils/customer-io";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CompleteSetup = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const url = `${window.location.origin}/store/${userInfo?.merchantCode}`;

  const proceedDashboard = () => {
    history.push("/dashboard/settings?tab=verify-email");
  };

  return (
    <main className="complete__setup">
      <WelcomeHeader />
      <div className="content__wrapper">
        <img src="/assets/images/success-icon.gif" className="success__icon" />
        <div className="text__wrapper">
          <p className="title">Great, you’re all set!</p>
          <p className="description">
            Copy your store link below and share on your social media pages to
            make your first sale
          </p>
        </div>
      </div>
      <div className="button__wrapper col-md-6 p-0 col-12">
        <button
          className="button button--purple-outline col-md-5 col-12"
          onClick={proceedDashboard}
        >
          Dashboard home
        </button>
        <button
          className="button button--purple col-md-5 col-12"
          onClick={() => setShow(true)}
        >
          <span>
            <Share /> Share store link
          </span>
        </button>
      </div>
      <ModalComponent show={show} centered size="md">
        <div className="modal__component">
          <div className="modal__title title__right">
            <CloseIcon onClick={() => setShow(false)} />
          </div>
          <div className="modal__content">
            <div className="share__wrapper">
              <div className="share__item">
                <div className="icon__wrapper">
                  <div className="share__icon">
                    <FacebookIcon fill="#5E60CE" />
                  </div>
                  <div className="share__text">
                    <p className="title">Share on Facebook</p>
                    <p className="description">
                      Share with friends on Facebook{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="share__button"
                  onClick={() => {
                    trackEvent("share_store_facebook");
                    shareOnFacebook(`Checkout my store on peppa \n ${url}`);
                  }}
                >
                  <Share color="#5E60CE" />
                  <p className="button__text">Share</p>
                </div>
              </div>
              <div className="share__item">
                <div className="icon__wrapper">
                  <div className="share__icon">
                    <InstagramIcon color="#5E60CE" />
                  </div>
                  <div className="share__text">
                    <p className="title">Share on Instagram</p>
                    <p className="description">
                      Share with followers on Instagram{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="share__button"
                  onClick={() =>
                    shareOnInstagram(`Checkout my store on peppa \n ${url}`)
                  }
                >
                  <Share color="#5E60CE" />
                  <p className="button__text">Share</p>
                </div>
              </div>
              <div className="share__item">
                <div className="icon__wrapper">
                  <div className="share__icon">
                    <WhatsappIcon color="#5E60CE" />
                  </div>
                  <div className="share__text">
                    <p className="title">Share on WhatsApp</p>
                    <p className="description">
                      Share with followers on Instagram{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="share__button"
                  onClick={() => {
                    shareOnWhatsApp(`Checkout my store \n ${url}`);
                    trackEvent("share_store_whatsapp");
                  }}
                >
                  <Share color="#5E60CE" />
                  <p className="button__text">Share</p>
                </div>
              </div>
              <div className="share__item">
                <div className="icon__wrapper">
                  <div className="share__icon">
                    <TwitterIcon fill="#5E60CE" />
                  </div>
                  <div className="share__text">
                    <p className="title">Share on Twitter</p>
                    <p className="description">
                      Share with followers on Twitter{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="share__button"
                  onClick={() => {
                    shareOnTwitter("Checkout my store on peppa", url);
                    trackEvent("share_store_twitter");
                  }}
                >
                  <Share color="#5E60CE" />
                  <p className="button__text">Share</p>
                </div>
              </div>
              <div className="share__item">
                <div className="icon__wrapper">
                  <div className="share__icon">
                    <CopyIconStyled color="#5E60CE" />
                  </div>
                  <div className="share__text">
                    <p className="title">Copy store link</p>
                    <p className="description">Copy link to clipboard</p>
                  </div>
                </div>
                <div
                  className="share__button"
                  onClick={() => handleCopy(url, "Store link copied")}
                >
                  <CopyIconStyled color="#5E60CE" />
                  <p className="button__text">Copy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </main>
  );
};

export default CompleteSetup;
