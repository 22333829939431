export default {
  TOP_CUSTOMERS: "/EscrowBackend/api/dashboard/topCustomers",
  SALES_REPORT: "/EscrowBackend/api/dashboard/salesReport",
  RECENT_ORDERS: "/EscrowBackend/api/dashboard/recentOrders",
  OVERVIEW: "/EscrowBackend/api/dashboard/overview",
  DOWNLOAD_SALES_REPORT: "/EscrowBackend/api/dashboard/salesReport/download",
};

export const ErrorMessages = {
  getHistoryFailed: "We are currently unable to get your dashboard data",
};
