import SearchIcon from "components/vectors/SearchIcon";
import React from "react";

const SearchBar = ({
  placeholder = "Search for products",
  onChange,
  width,
}) => {
  return (
    <div className="search_wrap search_wrap_2">
      <div className="search_box">
        <div className="btn btn_common">
          <SearchIcon height={16} width={17} color="#2C314980" />
        </div>
        <input
          type="text"
          className="input"
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
const SearchBarButtonEnd = () => {
  return (
    <div className="search_wrap search_wrap_3">
      <div className="search_box">
        <div className="search__icon__append">
          <SearchIcon color="#2C314980" height={13} width={13} />
        </div>
        <input type="text" className="input" placeholder="search..." />
        <div className="btn btn__prepend">Search</div>
      </div>
    </div>
  );
};

const SearchBox = ({
  placeholder = "Search for products",
  onChange,
  boxBorder,
}) => {
  return (
    <div className="search_wrap custom__searchBox">
      <div className={`search_box ${boxBorder ? "box__border" : ""}`}>
        <div className="btn btn_common">
          <SearchIcon height={16} width={17} color="#2C314980" />
        </div>
        <input
          type="text"
          className="input"
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export { SearchBarButtonEnd, SearchBar, SearchBox };
