/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ListItem, ListHeader, ListFooter, LoadingListItem } from "components/blocks";
import { getStringHash } from "libs/utils";

const List = ({ payments, meta, links, refreshData, loading }) => {
  const [page, setPage] = useState(null);

  const [query, setQuery] = useState("");

  const gotoPage = (page) => {
    setPage(page);
    const params = {
      page: page,
      query: query,
    };
    refreshData(params);
  };

  const search = (query) => {
    setQuery(query);
    const params = {
      page: page,
      query: query,
    };
    refreshData(params);
  };
  if (loading) {
    return (
      <div className="list-body">
        <ul>
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
        </ul>
      </div>
    );
  }

  return payments.length ? (
    <div className="list">
      <ListHeader search={search} />
      <div
        className="list-item d-none d-md-flex row mx-0"
        // onClick={() => {
        //   handleListClick();
        // }}
      >
        <div className={`list-item__indicator `} />
        <div className="col-2 col-md-4 px-0">
          <div className="d-flex flex-row">
            <div className="list-item__date">
              <p
                className="list-item__date-text"
                style={{ color: "#10182a", opacity: "1", fontWeight: "600" }}
              >
                Date
              </p>
            </div>
            <div className="list-item__status">
              <div className="list-item__status-container">
                <div
                  className={`list-item__status-tag 
                  `}
                >
                  {/* {getStatusText(status)} */}
                </div>
                {/* {status.toLowerCase() === "pending" ? (
                  <p className={`list-item__status-text ${getStatusTextClass(status)}`}>
                    {statusText.substring(statusText.indexOf(" ") + 1)}
                  </p>
                ) : null} */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 list-item__details col-md-4 px-0">
          <div className="list-item__details-container">
            <p className="list-item__details-product">Product Name</p>
            <p
              className="list-item__details-email"
              style={{ color: "#10182a", opacity: "1", fontWeight: "600" }}
            >
              Customer Name
            </p>
          </div>
        </div>
        <div className="col-4 col-md-4 px-0">
          <div className="d-flex flex-row justify-content-end">
            <div className="list-item__payment">
              <div className="list-item__payment-container">
                {/* {!isMerchant && (
                  <>
                    {status.toLowerCase() === "pending" ? (
                      <div className='button button-md button--orange'>Make Payment</div>
                    ) : null}
                  </>
                )} */}
                <p className="list-item__payment-price">Amount</p>
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              className="list-item__more"
              // onClick={() => {
              //   setListDetailsOpen(true);
              // }}
            >
              {/* <div className='dot' />
              <div className='dot' />
              <div className='dot' /> */}
              More
            </div>
          </div>
        </div>
      </div>
      <div className="list-body">
        <ul>
          {payments.reverse().map((payment, index) => (
            <ListItem
              key={getStringHash(index)}
              status={payment.status}
              statusText={payment.statusText}
              transactionId={payment.transactionId}
              transactionDatetime={payment.transactionDatetime}
              amount={payment.amount}
              customerEmail={payment.customerEmail}
              customerName={payment.customerName}
              paymentDescription={payment.paymentDescription}
              paymentName={payment.paymentName}
              deliveryDatetime={payment.deliveryDatetime}
              date={payment.date}
            />
          ))}
        </ul>
      </div>
      <ListFooter meta={meta} links={links} gotoPage={(page) => gotoPage(page)} />
    </div>
  ) : (
    <p className="mx-auto text-center" style={{ width: "80%", height: "auto" }}>
      You dont have any processed transactions yet
    </p>
  );
};

List.defaultProps = {
  payments: [],
};

List.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      statusText: PropTypes.string.isRequired,
      transactionId: PropTypes.string.isRequired,
      customerName: PropTypes.string.isRequired,
      transactionDate: PropTypes.string.isRequired,
      transactionDatetime: PropTypes.string.isRequired,
      paymentName: PropTypes.string.isRequired,
      deliveryDatetime: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      customerEmail: PropTypes.string.isRequired,
      paymentDescription: PropTypes.string.isRequired,
    })
  ),
};

export default List;
