import React from "react";
import { FooterList } from "components/blocks";
import { withWelcomeLayout } from "components/layouts";
import { getStringHash } from "libs/utils";
import { footerLinks } from "config/inner-routes";
import { useHistory } from "react-router-dom";
const Footer = () => {
  const history = useHistory();
  return (
    <footer className="max-width homepage-footer">
      <div className="max-container">
        <div className="container">
          <div className="homepage-footer-container">
            <div className="homepage-footer__header">
              <div className="row">
                <div className="col-md-5 mr-auto">
                  <h3 className="title">Ready to get started?</h3>
                  <p className="info">Get in touch or create an account.</p>
                </div>
                <div className="col-md-4">
                  <div className="homepage-footer__header-actions">
                    {/* <div className="button button-md button-lg button--orange" style={{ width: '260px', fontSize: '18px' }} onClick={() => { history.push('/welcome') }}>
                                            Make your first sale
                      </div> */}
                    {/* <div className="button button-md button-auto button-lg button--white text--gray">
                                                View Fair Pricing
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="homepage-footer-content">
              <div className="row">
                <div className="col-md-3">
                  <ul className="footer-list">
                    <li className="footer-list-item">ADDRESS</li>
                    {/* <li className="footer-list-item">
                      3 Samuel Onafuwa Close Rivervalley Estate Ojodu Lagos
                    </li> */}
                    <li className="footer-list-item">160 City Road London EC1V 2NX</li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <ul className="footer-list">
                    <FooterList index={getStringHash()} links={footerLinks[0]} />
                  </ul>
                </div>
                <div className="col-md-2">
                  <ul className="footer-list">
                    <FooterList index={getStringHash()} links={footerLinks[1]} />
                  </ul>
                </div>

                <div className="col-md-2">
                  <ul className="footer-list">
                    <FooterList index={getStringHash()} links={footerLinks[2]} />
                  </ul>
                </div>

                <div className="col-md-2">
                  <ul className="footer-list">
                    {/* <FooterList links={footerLinks[3]} /> */}
                    <li className="footer-list-item">CONNECT</li>
                    <a
                      href="http://bit.ly/joinpepperestcomm"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-list-item"
                      style={{ textDecoration: "none" }}
                    >
                      Join Community
                    </a>
                    <a
                      href="https://prosperofpepperest.substack.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-list-item"
                      style={{ textDecoration: "none" }}
                    >
                      Our Blog
                    </a>
                  </ul>
                </div>
              </div>
            </div>
            <div className="homepage-footer-base" style={{ marginTop: "20px" }}>
              <p className="homepage-footer-base-text">© {new Date().getFullYear()} Pepperest</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
