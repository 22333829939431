import { Loader } from "components/blocks";
import InputField from "components/blocks/InputField";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBanks } from "store/actions";
import {
  getAccountName,
  getAccountNameComplete,
  updateBankAccount,
} from "store/actions/userAccount";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { notify } from "libs/utils";

const AddBankDetail = ({ title = "Add Bank Account", update = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [bankCode, setBankCode] = useState("");
  const {
    nameEnquiry,
    banks,
    updatingBankAccount,
    userProfile,
    updatingBankAccountSuccess,
  } = useSelector((state) => state.userAccount);
  const { userInfo, token } = useSelector((state) => state.auth);

  const bankDetailSchema = yup.object().shape({
    bankCode: yup.string().required("Bank name is required"),
    accountNo: yup.string().required("Account number is required"),
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(bankDetailSchema),
  });

  useEffect(() => {
    if (!banks) {
      dispatch(getBanks());
    }
  }, []);

  useEffect(() => {
    if (userProfile?.bankAccountNo) {
      const userBank = banks?.find(
        (bank) => bank?.bank == userProfile.bankName
      );
      console.log({ userBank });
      reset({
        accountNo: userProfile?.bankAccountNo,
        bankCode: userBank?.bankcode,
      });
    }
  }, [banks]);

  const verifyAccountName = (data) => {
    setBankCode(data.bankCode);
    dispatch(getAccountName(data.bankCode, data.accountNo));
  };
  const addBankAccount = () => {
    const params = {
      accountName: nameEnquiry?.details?.name,
      accountNo: nameEnquiry?.details?.account?.number,
      bankCode,
    };
    dispatch(updateBankAccount(params, token, userInfo));
  };

  useEffect(() => {
    if (updatingBankAccountSuccess && !update) {
      history.push("/dashboard/settings?tab=premium", { replace: true });
      dispatch(getAccountNameComplete());
    }
    return () => {
      dispatch(getAccountNameComplete());
    };
  }, [updatingBankAccountSuccess]);

  return (
    <div className="bank__account__form w__full my-5">
      <form onSubmit={handleSubmit(verifyAccountName)}>
        <div className="bank__header">
          <p className="title">{title}</p>
        </div>
        <div className="form__wrapper">
          <InputField
            id={"accountNo"}
            name="accountNo"
            type="number"
            label="Account Number"
            register={register}
            error={errors.accountNo?.message}
          />
        </div>

        <div className="form__wrapper">
          <InputField
            id="bankCode"
            name="bankCode"
            type="select"
            label="Bank"
            register={register}
            error={errors.bankCode?.message}
            options={banks?.map((bank) => ({
              label: bank.bank,
              value: bank?.bankcode,
            }))}
          />
        </div>
        <div className="bank__detail">
          {nameEnquiry.details ? (
            <p className="py-3">{nameEnquiry?.details?.name}</p>
          ) : null}
        </div>
        <div className="error__message text-center ">
          {nameEnquiry?.error ? (
            <p className="py-2">{nameEnquiry.error}</p>
          ) : null}
        </div>
        {nameEnquiry.details ? (
          <button
            className="button button--purple button__content"
            type="button"
            onClick={addBankAccount}
            disabled={updatingBankAccount}
          >
            {update ? "Save Changes" : "Add bank account"}
          </button>
        ) : (
          <>
            {update ? (
              <div className="button__wrapper justify-content-end">
                <button
                  className="button button--purple button__content"
                  type="submit"
                  disabled={nameEnquiry.loading}
                >
                  Update changes
                </button>
              </div>
            ) : (
              <button
                className="button button--purple"
                type="submit"
                disabled={nameEnquiry.loading}
              >
                Verify bank account
              </button>
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default AddBankDetail;
