import { getCookie, setCookie } from 'libs/utils';
import React, { useEffect, useState } from 'react';


const CookieConsent = () => {
    const [show,setShow] = useState(false);
    const cookies = getCookie("cookieConsent");
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
    setShow(false)
  };

  useEffect(() => {
    if(cookies!=undefined){
        setShow(false);
    }else{
      setShow(true);
    }
  }, [show,cookies]);


  return (
    show?(
        <div className="cookie__consent">
      <p>
        We use cookies to enhance your user experience. By using our website,
        you agree to our use of cookies.{" "}
        <a href="/privacy" className='policy__link'>Learn more.</a>
      </p>
      <button onClick={giveCookieConsent}>
        Accept
      </button>
    </div>
    ):(null)
  );
};

export default CookieConsent;
