import React from "react";
import ModalComponent from "../ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderStatus } from "store/actions";
import { useEffect } from "react";

const ConfirmOrder = ({ order, setConfirmModal, confirmModal }) => {
  const { updatingOrderStatus, success } = useSelector((state) => state.orders);
  const { token, userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const confirmOrder = () => {
    dispatch(changeOrderStatus(token, userInfo, order?.orderId, "Confirmed"));
  };

  useEffect(() => {
    if (success) {
      setConfirmModal(false);
    }
  }, [success]);
  return (
    <ModalComponent show={confirmModal} centered size="md">
      <div className="modal__component">
        <div className="modal__title">Confirm order?</div>
        <div className="modal__content">
          <p className="text-center">
            Are you sure you want to confirm these Order:{" "}
            <span className="fw-bold">{order?.orderRef}</span>?{" "}
          </p>
          <div className="modal__control">
            <button
              className="button button--purple-outline px-4"
              onClick={() => setConfirmModal(false)}
            >
              Cancel
            </button>
            <button
              className="button button--purple px-4"
              onClick={confirmOrder}
              disabled={updatingOrderStatus}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ConfirmOrder;
